package bootstrap

import org.w3c.dom.events.Event
import react.*

@JsModule("react-bootstrap/Modal")
private external val modalModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bModal: ComponentType<ModalProps> = modalModule.default

external interface ModalProps : PropsWithChildren {
    var animation: Boolean
    var autoFocus: Boolean
    var backdrop: dynamic /* 'static' | true | false */
    var backdropClassName: String
    var centered: Boolean
    var container: Any
    var contentClassName: String
    var dialogClassName: String
    var enforceFocus: Boolean
    var keyboard: Boolean
    var manager: dynamic
    var onEnter: (Event) -> Unit
    var onEntered: (Event) -> Unit
    var onEntering: (Event) -> Unit
    var onEscapeKeyDown: (Event) -> Unit
    var onExit: (Event) -> Unit
    var onExited: (Event) -> Unit
    var onExiting: (Event) -> Unit
    var onHide: (Event) -> Unit
    var onShow: (Event) -> Unit
    var restoreFocus: Boolean
    var scrollable: Boolean
    var show: Boolean
    var size: String /* 'sm' | 'lg','xl' */
    var bsPrefix: String
}
