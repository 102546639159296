import components.CheckoutComponent
import react.*
import react.router.Route
import react.router.Routes
import react.router.dom.*
import screens.*
import support.Store
import views.*
import kotlin.time.ExperimentalTime

val storeReducer: Reducer<Store, List<Store.Action>> = { store, actions ->
    store.reduce(actions)
}

var StoreContext: Context<Pair<Store, Dispatch<List<Store.Action>>>> = createContext()

@ExperimentalTime
val frontReducer: Reducer<FrontScreen.ViewModel, FrontScreen.ViewModel> = { _, viewModel ->
    viewModel
}

@ExperimentalTime
var FrontContext: Context<Pair<FrontScreen.ViewModel, Dispatch<FrontScreen.ViewModel>>> = createContext()

@ExperimentalTime
val orderReducer: Reducer<OrderScreen.ViewModel, OrderScreen.ViewModel> = { _, viewModel ->
    viewModel
}

@ExperimentalTime
var OrderContext: Context<Pair<OrderScreen.ViewModel, Dispatch<OrderScreen.ViewModel>>> = createContext()


@ExperimentalTime
val designReducer: Reducer<DesignScreen.ViewModel, DesignScreen.ViewModel> = { _, viewModel ->
    viewModel
}

@ExperimentalTime
var DesignContext: Context<Pair<DesignScreen.ViewModel, Dispatch<DesignScreen.ViewModel>>> = createContext()


@ExperimentalTime
val inspirationReducer: Reducer<InspirationScreen.ViewModel, InspirationScreen.ViewModel> = { _, viewModel ->
    viewModel
}

@ExperimentalTime
var InspirationContext: Context<Pair<InspirationScreen.ViewModel, Dispatch<InspirationScreen.ViewModel>>> =
    createContext()

@ExperimentalTime
val faqReducer: Reducer<FaqScreen.ViewModel, FaqScreen.ViewModel> = { _, viewModel ->
    viewModel
}

@ExperimentalTime
var FaqContext: Context<Pair<FaqScreen.ViewModel, Dispatch<FaqScreen.ViewModel>>> = createContext()

@ExperimentalTime
val ownDesignReducer: Reducer<OwnDesignScreen.ViewModel, OwnDesignScreen.ViewModel> = { _, viewModel ->
    viewModel
}

@ExperimentalTime
var OwnDesignContext: Context<Pair<OwnDesignScreen.ViewModel, Dispatch<OwnDesignScreen.ViewModel>>> = createContext()

@ExperimentalTime
val paymentReducer: Reducer<PaymentScreen.ViewModel, PaymentScreen.ViewModel> = { _, viewModel ->
    viewModel
}

@ExperimentalTime
var PaymentContext: Context<Pair<PaymentScreen.ViewModel, Dispatch<PaymentScreen.ViewModel>>> = createContext()


@ExperimentalTime
val emailReducer: Reducer<EmailScreen.ViewModel, EmailScreen.ViewModel> = { _, viewModel ->
    viewModel
}

@ExperimentalTime
var EmailContext: Context<Pair<EmailScreen.ViewModel, Dispatch<EmailScreen.ViewModel>>> = createContext()

@ExperimentalTime
val helperReducer: Reducer<HelperScreen.ViewModel, HelperScreen.ViewModel> = { _, viewModel ->
    viewModel
}

@ExperimentalTime
var HelperContext: Context<Pair<HelperScreen.ViewModel, Dispatch<HelperScreen.ViewModel>>> = createContext()


@ExperimentalTime
val App = FC<PropsWithChildren>("APP") {
    val (store, storeDispatch) = useReducer(storeReducer, Store())
    val (front, frontDispatch) = useReducer(frontReducer, FrontScreen.ViewModel.None)
    val (order, orderDispatch) = useReducer(orderReducer, OrderScreen.ViewModel.None)
    val (design, designDispatch) = useReducer(designReducer, DesignScreen.ViewModel.None)
    val (inspiration, inspirationDispatch) = useReducer(inspirationReducer, InspirationScreen.ViewModel.None)
    val (faq, faqDispatch) = useReducer(faqReducer, FaqScreen.ViewModel.None)
    val (ownDesign, ownDesignDispatch) = useReducer(ownDesignReducer, OwnDesignScreen.ViewModel.None)
    val (payment, paymentDispatch) = useReducer(paymentReducer, PaymentScreen.ViewModel.None)
    val (email, emailDispatch) = useReducer(emailReducer, EmailScreen.ViewModel.None)
    val (helper, helperDispatch) = useReducer(helperReducer, HelperScreen.ViewModel.None)

    StoreContext.Provider(store to storeDispatch) {
        FrontContext.Provider(front to frontDispatch) {
            OrderContext.Provider(order to orderDispatch) {
                DesignContext.Provider(design to designDispatch) {
                    InspirationContext.Provider(inspiration to inspirationDispatch) {
                        FaqContext.Provider(faq to faqDispatch) {
                            OwnDesignContext.Provider(ownDesign to ownDesignDispatch) {
                                PaymentContext.Provider(payment to paymentDispatch) {
                                    EmailContext.Provider(email to emailDispatch) {
                                        HelperContext.Provider(helper to helperDispatch) {
                                            BrowserRouter {
                                                //   attrs.basename = Deployment.basename()
                                                //  attrs.forceRefresh = false
                                                Routes {
                                                    Route { path = "/"; element = DesignView.create() }
                                                    Route { path = "/inspiration"; element = InspirationView.create() }
                                                    Route { path = "/faq";element = FaqView.create() }
                                                    Route { path = "/ownDesign"; element = OwnDesignView.create() }
                                                    Route { path = "/checkout"; element = OrderView.create() }
                                                    Route { path = "/payment"; element = PaymentView.create() }
                                                    Route { path = "/helper"; element = HelperView.create() }
                                                    Route { path = "/terms"; element = TermsView.create() }
                                                    Route { path = "*"; element = NotFoundView.create() }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

