package views

import EmailContext
import OrderContext
import PaymentContext
import StoreContext
import bCol
import bootstrap.*
import components.*
import kotlinx.js.jso
import react.*
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.Window
import org.w3c.dom.events.Event
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.main
import react.dom.html.ReactHTML.p
import react.dom.server.rawRenderToStaticMarkup
import react.router.dom.Link
import react.router.useNavigate
import screens.EmailScreen

import screens.OrderScreen
import screens.PaymentScreen
import screens.PaymentScreen.failed
import support.className
import support.sceneOf
import support.useAsyncEffect
import techla.base.techla_log
import kotlin.time.ExperimentalTime

var Window.klarnaAsyncCallback: () -> Unit
    get() = asDynamic().klarnaAsyncCallback
    set(value) {
        asDynamic().klarnaAsyncCallback = value
    }

external class Klarna {
    class Payments {
        companion object {
            fun init(params: dynamic)
            fun load(params: dynamic, block: (dynamic) -> Unit)
            fun authorize(params: dynamic, block: (dynamic) -> Unit)
        }
    }
}

@ExperimentalTime
val PaymentView = FC<PropsWithChildren>("PAYMENT_VIEW") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useContext(PaymentContext)
    val (orderViewModel, setOrderViewModel) = useContext(OrderContext)
    val (paymentMethod, setPaymentMethod) = useState("")
    val (calPayment, setCalPayment) = useState(0)
    val (showForm, setShowForm) = useState(false)
    val (emailViewModel, setEmailViewModel) = useContext(EmailContext)
    val (send, setSend) = useState(false)
    val navigate = useNavigate()

    fun restart() {
        techla_log("Restart")
        MainScope().launch {
            val (new, actions) = OrderScreen.load(sceneOf(store, orderViewModel))
            dispatch(actions)
            setOrderViewModel(new)
            navigate("/")
        }

    }

    useEffect(emailViewModel) {
        when (emailViewModel) {
            is EmailScreen.ViewModel.None -> {
                techla_log("EMAIL -> NONE")
                MainScope().launch {
                    val (new, actions) = EmailScreen.load(sceneOf(store, emailViewModel))
                    dispatch(actions)
                    setEmailViewModel(new)
                }
            }
            is EmailScreen.ViewModel.Ready -> {
                techla_log("EMAIL -> READY")
            }

            is EmailScreen.ViewModel.Sent -> {
                techla_log("EMAIL -> SENT")
                MainScope().launch {
                    val (new, actions) = EmailScreen.load(sceneOf(store, emailViewModel))
                    dispatch(actions)
                    setEmailViewModel(new)
                }
            }
            is EmailScreen.ViewModel.Failed -> {
                techla_log("EMAIL -> FAILED")
                techla_log(emailViewModel.message)
            }
        }
    }

    useAsyncEffect(send) { coroutineScope ->
        if (send && emailViewModel is EmailScreen.ViewModel.Ready && viewModel is PaymentScreen.ViewModel.PaymentSuccessful) {
            val element = createElement(EMAIL, jso<EmailProps>().apply {
                this.viewModel = viewModel
                this.orderItemPrice = orderItemPrice
            })

            val body = rawRenderToStaticMarkup(element)
            MainScope().launch {
                val (new, actions) = EmailScreen.send(
                    sceneOf(store, emailViewModel),
                    body,
                    viewModel.emailInformation.email
                )
                dispatch(actions)
                setEmailViewModel(new)
            }

        }
        setSend(false)
    }

    fun startAuth() {
        Klarna.Payments.authorize(
            jso() {
                container = "#klarna-payments-container-${paymentMethod}"
                payment_method_category = paymentMethod
            },
        ) { res ->
            if (res.show_form == true) {
                if (res.authorization_token != "" && res.approved == true) {
                    MainScope().launch {
                        val (new, actions) = PaymentScreen.authorizeKlarna(
                            sceneOf(store, viewModel),
                            paymentKey = paymentMethod,
                            authorization = res.authorization_token
                        )
                        dispatch(actions)
                        setViewModel(new)
                    }

                } else if (res.approved == false && res.show_form == true) {
                    MainScope().launch {
                        val (new, actions) = PaymentScreen.load(sceneOf(store, viewModel))
                        dispatch(actions)
                        setViewModel(new)
                    }
                }
            } else {
                MainScope().launch {
                    val (new, actions) = PaymentScreen.declined(
                        sceneOf(store, viewModel),
                    )
                    dispatch(actions)
                    setViewModel(new)

                }
            }

        }
    }


    useEffect(paymentMethod) {
        if (calPayment > 0) {
            Klarna.Payments.load(
                jso() {
                    container = "#klarna-payments-container-${paymentMethod}"
                    payment_method_category = paymentMethod
                }
            ) { res ->
                setCalPayment(0)
                if (res.show_form == true && res.error.invalid_fields.length >= 1) {
                    setShowForm(false)
                    techla_log(
                        "This can never happen due to validation before entering Payment page"
                    )
                    techla_log(res)
                    failed(sceneOf(store, viewModel), res)
                } else if (
                    res.show_form == true) {
                    setShowForm(true)
                    techla_log(res)
                    techla_log("payment 1")
                } else {
                    techla_log("Customer can't pay with this method")
                    techla_log(res)
                }
            }
            setCalPayment(0)
        }

    }

    fun selectPayment(event: Event) {
        val target = event.target as HTMLInputElement
        val value = target.value
        setPaymentMethod(value)
        setCalPayment(1)
    }

    useEffect(viewModel) {
        when (viewModel) {
            is PaymentScreen.ViewModel.None -> {
                techla_log("PAYMENT -> NONE")
                MainScope().launch {
                    val (new, actions) = PaymentScreen.start(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)
                }
            }
            is PaymentScreen.ViewModel.Started -> {
                techla_log("PAYMENT -> STARTED")
                MainScope().launch {
                    val (new, actions) = PaymentScreen.load(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)
                }
            }

            is PaymentScreen.ViewModel.Payment -> {
                techla_log("PAYMENT -> READY")
                if (paymentMethod == "") {
                    setPaymentMethod(
                        viewModel.paymentMethod.minByOrNull { it.key.length }?.key ?: ""
                    )
                    setCalPayment(1)
                }
            }
            is PaymentScreen.ViewModel.PaymentSuccessful -> {
                // TODO - ADD dataLayer
                techla_log("PAYMENT -> PaymentSuccessful")
                setSend(true)

            }
            is PaymentScreen.ViewModel.Pending -> {
                // TODO - ADD dataLayer
                techla_log("PAYMENT -> Pending")
            }

            is PaymentScreen.ViewModel.PaymentFailed -> {
                techla_log("PAYMENT -> PaymentFailed")
                techla_log(viewModel.message)
            }
            is PaymentScreen.ViewModel.Declined -> {
                techla_log("PAYMENT -> DECLINED")
            }
            is PaymentScreen.ViewModel.Failed -> {
                techla_log("PAYMENT -> FAILED")
                techla_log(viewModel.message)
            }
        }
    }




    main {
        if (viewModel is PaymentScreen.ViewModel.Payment) {
            bContainer {
                fluid = true
                className = className("n-header py-3 px-0 d-flex align-items-center")
                bContainer {
                    className = className("px-3 px-md-0 p-lg-0")
                    a {
                        onClick = { restart() }
                        img {
                            className = className("n-logo-spegla")
                            alt = "Speglar.nu"
                            src = "svg/logo_speglar.svg"

                        }
                    }

                }
            }
            bContainer {
                className = className("px-3 px-md-0 p-lg-0")
                Link {
                    to = "/checkout"
                    className = className("d-block greenlink pt-4")

                    img {
                        className = className("pr-2")
                        alt = "Go back"
                        src = "svg/ico_back.svg"
                    }
                    +viewModel.texts.buttonGoBack
                }
            }

            bContainer {
                className = className("py-5 px-0 n-bottom-space")
                bRow {
                    className = className("pb-4")
                    bCol() {
                        xs = 12; md = 8
                        bAccordion {
                            activeKey = paymentMethod
                            viewModel.paymentMethod.sortedBy { it.key.length }
                                .map { payment ->
                                    div {
                                        className = className("mb-2 mt-2")
                                        bAccordionItem {
                                            eventKey = payment.key
                                            bCardHeader {
                                                div {
                                                    onClick = { setPaymentMethod(payment.key); setCalPayment(1) }
                                                    bFormControl {
                                                        className = className("d-inline n-checkbox")
                                                        type = "radio"
                                                        value = payment.key
                                                        checked = paymentMethod == payment.key
                                                        onChange = ::selectPayment
                                                    }
                                                    h3 {
                                                        className = className("d-inline ml-3")
                                                        +payment.name
                                                    }
                                                    img {
                                                        className = className("float-right")
                                                        alt = "Klarna"
                                                        src = payment.imgAsset.toString()
                                                    }
                                                }
                                            }
                                            if (paymentMethod == payment.key) {
                                                div {
                                                    id = "klarna-payments-container-${payment.key}"
                                                }
                                            }
                                        }
                                    }
                                }
                        }



                        if (!showForm) {
                            bButton {
                                className = className("btn-lg btn-block mt-4 mb-5")
                                onClick = { startAuth() }
                                disabled = paymentMethod == ""
                                +viewModel.texts.pay
                            }
                        }

                    }
                    bCol {
                        className = className("p-4")
                        xs = 12; md = 4
                        orderComponent(show = false, title = viewModel.texts.overview, className = "n-cart-checkout p-4", isEditable = false)
                    }
                }
            }
        }

        if (viewModel is PaymentScreen.ViewModel.PaymentSuccessful) {
            div {

                bContainer {
                    className = className("px-3 px-md-0 p-lg-0")
                    a {
                        className = className("d-block greenlink pt-4")
                        onClick = { restart() }
                        img {
                            className = className("pr-2")
                            alt = "Go back"
                            src = "svg/ico_back.svg"
                        }
                        +viewModel.texts.buttonGoBack
                    }
                }


                bContainer {
                    className = className("py-5")
                    bRow {
                        className = className("pb-4 justify-content-center")
                        bCol {
                            className = className("p-4 text-center")
                            xs = 12; md = 8
                            img {
                                src = "svg/ico_thumbs_up.svg"
                                className = className("py-4")
                            }
                            h1 {
                                +viewModel.texts.PaymentSuccessfulTitle
                            }
                            p {
                                +viewModel.texts.PaymentSuccessfulText
                            }
                        }
                    }
                }


            }
        }

        if (viewModel is PaymentScreen.ViewModel.Pending) {
            div {

                bContainer {
                    className = className("px-3 px-md-0 p-lg-0")
                    a {
                        className = className("d-block greenlink pt-4")
                        onClick = { restart() }
                        img {
                            className = className("pr-2")
                            alt = "Go back"
                            src = "svg/ico_back.svg"
                        }
                        +viewModel.texts.buttonGoBack
                    }
                }

                bContainer {
                    className = className("py-5")
                    bRow {
                        className = className("pb-4 justify-content-center")
                        bCol {
                            className = className("p-4 text-center")
                            xs = 12; md = 8
                            img {
                                src = "svg/ico_error.svg"
                                className = className("py-4")
                            }
                            h1 {
                                +viewModel.texts.pendingTitle
                            }
                            p {
                                +viewModel.texts.pendingText
                            }
                        }
                    }
                }
            }
        }

        if (viewModel is PaymentScreen.ViewModel.PaymentFailed) {
            div {

                bContainer {
                    className = className("px-3 px-md-0 p-lg-0")
                    a {
                        className = className("d-block greenlink pt-4")
                        onClick = { restart() }

                        img {
                            className = className("pr-2")
                            alt = "Go back"
                            src = "svg/ico_back.svg"

                        }
                        +viewModel.buttonGoBack
                    }
                }

                bContainer {
                    className = className("py-5")
                    bRow {
                        className = className("pb-4 justify-content-center")
                        bCol {
                            className = className("p-4 text-center")
                            xs = 12; md = 8
                            img {
                                src = "svg/ico_error.svg"
                                className = className("py-4")
                            }
                            h1 {
                                +viewModel.title
                            }
                        }
                    }
                }
            }
        }

        if (viewModel is PaymentScreen.ViewModel.Declined) {
            div {
                bContainer {
                    className = className("px-3 px-md-0 p-lg-0")
                    a {
                        className = className("d-block greenlink pt-4")
                        onClick = { restart() }
                        img {
                            className = className("pr-2")
                            alt = "Go back"
                            src = "svg/ico_back.svg"
                        }
                        +viewModel.texts.buttonGoBack
                    }
                }

                bContainer {
                    className = className("py-5")
                    bRow {
                        className = className("pb-4 justify-content-center")
                        bCol {
                            className = className("p-4 text-center")
                            xs = 12; md = 8
                            img {
                                src = "svg/ico_error.svg"
                                className = className("py-4")
                            }
                            h1 {
                                +viewModel.texts.declinedTitle
                            }
                            p {
                                +"${viewModel.texts.declinedText1} "
                                a {
                                    className = className("d-inline")
                                    href = "tel:${viewModel.texts.phoneNumber}"
                                    +viewModel.texts.phoneNumber
                                }

                                +" ${viewModel.texts.emailText} "

                                a {
                                    className = className("d-inline")
                                    href = "mailto:${viewModel.texts.email}"
                                    target = AnchorTarget._blank
                                    +viewModel.texts.email
                                }
                            }
                            p {
                                +"${viewModel.texts.declinedText2} "
                            }

                        }
                        footerComponent()
                    }
                }
            }
        }

        if (viewModel is PaymentScreen.ViewModel.Failed) {
            div {

                bContainer {
                    className = className("px-3 px-md-0 p-lg-0")

                    a {
                        className = className("d-block greenlink pt-4")
                        onClick = { restart() }
                        img {
                            className = className("pr-2")
                            alt = "Go back"
                            src = "svg/ico_back.svg"
                        }
                        +viewModel.buttonGoBack
                    }
                }

                bContainer {
                    className = className("py-5")
                    bRow {
                        className = className("pb-4 justify-content-center")
                        bCol {
                            className = className("p-4 text-center")
                            xs = 12; md = 8
                            img {
                                src = "svg/ico_error.svg"
                                className = className("py-4")
                            }
                            h1 {
                                +viewModel.title
                            }
                            p {
                                +viewModel.message
                            }
                        }
                    }
                }
            }
        }
    }
}