package bootstrap

import csstype.ClassName
import react.ComponentType
import react.PropsWithChildren

external interface ContainerProps : PropsWithChildren {
    operator fun set(s: String, value: String)

    var className: ClassName
    var fluid: dynamic /* true | "sm" | "md" | "lg" | "xl" */
    var bsPrefix: String
}

@JsModule("react-bootstrap/Container")
private external val containerModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bContainer: ComponentType<ContainerProps> = containerModule.default