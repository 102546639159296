package bootstrap

import react.*

@JsModule("react-bootstrap/ModalBody")
private external val modalBodyModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bModalBody: ComponentType<ModalBodyProps> = modalBodyModule.default

external interface ModalBodyProps : PropsWithChildren {
    var bsPrefix: String
}