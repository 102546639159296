package screens


import io.ktor.http.*
import kotlinx.js.jso
import support.*
import techla.base.*
import techla.order.*
import techla.payment.Payment
import techla.payment.Provider
import views.Klarna
import kotlin.math.roundToInt


import kotlin.time.ExperimentalTime


val Order.contact
    get() =
        contact as? Order.Contact.Full

val Order.delivery
    get() =
        (delivery as? Order.Delivery.Home)?.address

val Order.Delivery.Home.address
    get() =
        when (this) {
            is Order.Delivery.Home -> address
            else -> null
        }

val Order.totalAmount
    get() =
        (total as? Order.Total.Price)?.amount ?: Amount.zero("SEK")

val Order.taxAmount
    get() =
        (total as? Order.Total.Price)?.taxAmount ?: Amount.zero("SEK")

val OrderItem.taxRate
    get() =
        (cost as? Article.Cost.Price)?.taxRate ?: 0.0


fun replaceChar(sent: String): String {
    return sent.lowercase().replace("å", "a").replace("ä", "a").replace("ö", "o")
}

@ExperimentalTime
object PaymentScreen {

    data class Texts(
        val PaymentSuccessfulTitle: String,
        val PaymentSuccessfulText: String,
        val PaymentFailedTitle: String,
        val PaymentFailedText: String,
        val declinedTitle: String,
        val declinedText1: String,
        val declinedText2: String,
        val pay: String,
        val pendingTitle: String,
        val pendingText: String,
        val buttonGoBack: String,
        val errorMessage: String,
        val phoneNumber: String,
        val emailText: String,
        val email: String,
        val overview: String,
        val height: String,
        val width: String,
        val errorMessageOrderError: String,
    )

    data class EmailText(
        val urlSpeglar: String,
        val thankYouMessage: String,
        val openingHours: String,
        val textRydsGlasSe: String,
        val instagramText: String,
        val urlInstagram: String,
        val contact: String,
        val RydsPhone: String,
        val RydsEmail: String,
        val titleOverview: String,
        val linkRydsGlas: String,
        val instagramName: String,
        val emailsubject: String,
        val cost: String,
        val vat: String,
        val discountCost: String,
        val originalCost: String,
        val titleDiscount: String,
    )

    data class EmailInformation(
        val firstName: String,
        val lastName: String,
        val email: String,
        val phoneNumber: String,
        val street: String?,
        val postalCode: Int?,
        val city: String?,
        val selectedCity: String,
        val totalCost: String,
        val orderItems: List<OrderItem>?,
        val discountName: String?,
        val discountCost: Int?, val originalCost: Int?

    )

    data class State(
        val order: Order?,
        val orderItems: List<OrderItem>?,
        val totalCost: Order.Total.Price?,
        val clientToken: String,
        val klarnaURL: String,
        val authToken: String,
        val paymentId: Identifier<Payment>
    )

    data class PaymentMethod(
        val name: String,
        val key: String,
        val imgAsset: Url?,
    )


    sealed class ViewModel(
        val texts: Texts,
        val state: State,
        val paymentMethod: List<PaymentMethod>,
        val emailText: EmailText,
        val emailInformation: EmailInformation
    ) {
        object None : ViewModel(
            Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            State(null, null, null, "", "https://x.klarnacdn.net/kp/lib/v1/api.js", "", Identifier("")),
            emptyList(),
            EmailText("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            EmailInformation("", "", "", "", "", 0, "", "", "", emptyList(), "", 0, 0)
        )

        class Started(
            texts: Texts,
            state: State,
            paymentMethod: List<PaymentMethod>,
            emailText: EmailText,
            emailInformation: EmailInformation
        ) : ViewModel(texts, state, paymentMethod, emailText, emailInformation)

        class Payment(
            texts: Texts,
            state: State,
            paymentMethod: List<PaymentMethod>,
            emailText: EmailText,
            emailInformation: EmailInformation
        ) : ViewModel(texts, state, paymentMethod, emailText, emailInformation)

        class PaymentSuccessful(
            texts: Texts,
            state: State,
            paymentMethod: List<PaymentMethod>,
            emailText: EmailText,
            emailInformation: EmailInformation
        ) : ViewModel(texts, state, paymentMethod, emailText, emailInformation)

        class Pending(
            texts: Texts,
            state: State,
            paymentMethod: List<PaymentMethod>,
            emailText: EmailText,
            emailInformation: EmailInformation
        ) : ViewModel(texts, state, paymentMethod, emailText, emailInformation)


        class PaymentFailed(
            texts: Texts,
            state: State,
            paymentMethod: List<PaymentMethod>,
            emailText: EmailText,
            emailInformation: EmailInformation,
            val message: String,
            val title: String,
            val buttonGoBack: String,

            ) : ViewModel(texts, state, paymentMethod, emailText, emailInformation)

        class Declined(
            texts: Texts,
            state: State,
            paymentMethod: List<PaymentMethod>,
            emailInformation: EmailInformation,
            emailText: EmailText,

            ) : ViewModel(texts, state, paymentMethod, emailText, emailInformation)

        class Failed(
            texts: Texts,
            state: State,
            paymentMethod: List<PaymentMethod>,
            emailText: EmailText,
            emailInformation: EmailInformation,
            val message: String,
            val title: String,
            val buttonGoBack: String,
        ) : ViewModel(texts, state, paymentMethod, emailText, emailInformation)

        fun started() =
            Started(
                texts = texts,
                state = state,
                paymentMethod = paymentMethod,
                emailText = emailText,
                emailInformation = emailInformation
            )

        fun payment(
            texts: Texts,
            state: State,
            paymentMethod: List<PaymentMethod>,
            emailText: EmailText,
            emailInformation: EmailInformation

        ) =
            Payment(
                texts = texts,
                state = state,
                paymentMethod = paymentMethod,
                emailText = emailText,
                emailInformation = emailInformation
            )

        fun pending(
            texts: Texts,
            state: State,
            paymentMethod: List<PaymentMethod>,
            emailText: EmailText,
        ) =
            Pending(
                texts = texts,
                state = state,
                paymentMethod = paymentMethod,
                emailText = emailText,
                emailInformation = emailInformation
            )

        fun paymentSuccessful(
            texts: Texts,
            state: State,
            paymentMethod: List<PaymentMethod>,
            emailText: EmailText,

            ) =
            PaymentSuccessful(
                texts = texts,
                state = state,
                paymentMethod = paymentMethod,
                emailText = emailText,
                emailInformation = emailInformation
            )

        private fun paymentFailed(message: String, title: String, buttonGoBack: String) =
            PaymentFailed(
                texts = texts,
                state = state,
                message = message,
                paymentMethod = paymentMethod,
                emailText = emailText,
                emailInformation = emailInformation,
                title = title,
                buttonGoBack = buttonGoBack,
            )

        fun paymentFailed(result: Either<List<Warning>, Throwable>, title: String, buttonGoBack: String) =
            paymentFailed(
                message = when (result) {
                    is Either.Left -> "(${result.value.joinToString(", ") { it.message }})"
                    is Either.Right -> "(${result.value.message})"
                },
                title = texts.declinedTitle,
                buttonGoBack = texts.buttonGoBack,
            )


        fun declined() =
            Declined(
                texts = texts,
                state = state,
                paymentMethod = paymentMethod,
                emailText = emailText,
                emailInformation = emailInformation,
            )


        fun failed(message: String, title: String, buttonGoBack: String) =
            Failed(
                texts = texts,
                state = state,
                message = message,
                title = title,
                paymentMethod = paymentMethod,
                emailText = emailText,
                emailInformation = emailInformation,
                buttonGoBack = buttonGoBack,
            )

        fun failed(result: Either<List<Warning>, Throwable>, title: String, buttonGoBack: String) =
            failed(
                message = when (result) {
                    is Either.Left -> "(${result.value.joinToString(", ") { it.message }})"
                    is Either.Right -> "(${result.value.message})"
                },
                title = title,
                buttonGoBack = buttonGoBack,
            )
    }

    private fun Scene.Input<ViewModel>.paymentFailed(
        result: Either<List<Warning>, Throwable>,
        title: String,
        buttonGoBack: String
    ) =
        sceneOf<ViewModel>(viewModel.paymentFailed(result, title, buttonGoBack))


    fun Scene.Input<ViewModel>.failed(
        result: Either<List<Warning>, Throwable>,
        title: String,
        buttonGoBack: String
    ) =
        sceneOf<ViewModel>(viewModel.failed(result, title, buttonGoBack))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return sceneOf(viewModel.started())
    }

    fun declined(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return sceneOf(
            viewModel.declined(
            )
        )
    }


    private suspend fun editPayment(
        store: Store,
        id: Identifier<Payment>,
        paymentMethod: PaymentMethod,
        authorization: String
    ) =
        store.paymentAPI.editPayment(
            id,
            Payment.Edit(
                status = modifiedOf(Payment.Status.Authenticating),
                remittance = modifiedOf(Payment.Remittance.Authorization(authorization)),
                method = modifiedOf(
                    Payment.Method(
                        Key(paymentMethod.key),
                        paymentMethod.name,
                        paymentMethod.imgAsset
                    )
                )
            )
        )


    suspend fun authorizeKlarna(
        scene: Scene.Input<ViewModel>,
        paymentKey: String,
        authorization: String
    ): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val action = listOf(
            Store.Action.Reset,
        )

        val paymentMethod = viewModel.paymentMethod.first { it.key == paymentKey }
        return successfulOf(Unit)
            .flatMap {
                editPayment(
                    store,
                    viewModel.state.paymentId,
                    paymentMethod,
                    authorization
                )
            }
            .map { payment ->
                when ((payment.provider.status as? Provider.Status.KlarnaExecuted)?.fraudStatus) {
                    "ACCEPTED" -> {
                        dataLayerPurchase(viewModel.state.orderItems, viewModel.state.order)
                        store.orderAPI.editOrder(
                            id = store.orderId!!, Order.Edit(
                                name = modifiedOf((payment.provider.status as? Provider.Status.KlarnaExecuted)?.orderId!!),
                            )
                        ).map {
                            sceneOf<ViewModel>(
                                viewModel.paymentSuccessful(
                                    texts = viewModel.texts,
                                    state = viewModel.state,
                                    paymentMethod = emptyList(),
                                    emailText = viewModel.emailText
                                ), action
                            )
                        }
                            .fold(
                                onSuccess = { it },
                                onNotSuccess = {
                                    techla_log("Order ACCEPTED. But could not save Klarna id in Order -> $it"); scene.paymentFailed(
                                    result = it,
                                    title = listOf(viewModel.texts.PaymentSuccessfulTitle, viewModel.texts.errorMessageOrderError).joinToString { " " },
                                    buttonGoBack = viewModel.texts.buttonGoBack,
                                )
                                }
                            )

                    }

                    "PENDING" -> {
                        dataLayerPurchase(viewModel.state.orderItems, viewModel.state.order)
                        store.orderAPI.editOrder(
                            id = store.orderId!!, Order.Edit(
                                name = modifiedOf((payment.provider.status as? Provider.Status.KlarnaExecuted)?.orderId!!),
                            )
                        ).map {
                            sceneOf<ViewModel>(
                                viewModel.pending(
                                    texts = viewModel.texts,
                                    state = viewModel.state,
                                    paymentMethod = emptyList(),
                                    emailText = viewModel.emailText
                                ), action
                            )
                        }
                            .fold(
                                onSuccess = { it },
                                onNotSuccess = {
                                    techla_log("Order PENDING. But could not save Klarna id in Order -> $it"); scene.paymentFailed(
                                    result = it,
                                    title = listOf(viewModel.texts.pendingTitle, viewModel.texts.errorMessageOrderError).joinToString { " " },
                                    buttonGoBack = viewModel.texts.buttonGoBack,
                                )
                                }
                            )
                    }

                    "REJECTED" -> {
                        cancelOrder(store)
                        sceneOf<ViewModel>(
                            viewModel.declined(
                            ), action
                        )
                    }

                    else -> {
                        cancelOrder(store)
                        sceneOf<ViewModel>(
                            viewModel.declined(

                            ), action
                        )
                    }
                }

            }

            .fold(
                onSuccess = { it },
                onNotSuccess = {
                    techla_log("5 Declined -> $it"); scene.paymentFailed(
                    result = it,
                    title = viewModel.texts.errorMessage,
                    buttonGoBack = viewModel.texts.buttonGoBack,
                );
                } //TODO - error_code: "NOT_FOUND" -> 60min har gått ladda om sidan bara så att det kommer ett nytt token
            )
    }

    private suspend fun cancelOrder(store: Store) = store.orderAPI.deleteOrder(
        id = store.orderId!!
    )


    private suspend fun getOrder(store: Store, viewModel: ViewModel) =
        store.orderAPI.getOrder(store.orderId!!)
            .flatMap { refresh(store, it.id) }
            .map { (order, orderItems) ->
                (order.total as? Order.Total.Price)?.let { viewModel.state.copy(totalCost = it) }
                Pair(order, orderItems)
            }

    private fun createPaymentMethod(availableMethods: List<Payment.Method>) =
        availableMethods.map {
            PaymentMethod(
                name = it.name,
                key = it.key.rawValue,
                imgAsset = it.imageAsset
            )
        }


    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene


        val texts = Texts(
            PaymentSuccessfulTitle = store.get(media = Key("screen:payment"), content = Key("PaymentSuccessfulTitle")),
            PaymentSuccessfulText = store.get(media = Key("screen:payment"), content = Key("PaymentSuccessfulText")),
            PaymentFailedTitle = store.get(media = Key("screen:payment"), content = Key("PaymentFailedTitle")),
            PaymentFailedText = store.get(media = Key("screen:payment"), content = Key("PaymentFailedText")),
            declinedTitle = store.get(media = Key("screen:payment"), content = Key("declinedTitle")),
            declinedText1 = store.get(media = Key("screen:payment"), content = Key("declinedText.1")),
            declinedText2 = store.get(media = Key("screen:payment"), content = Key("declinedText.2")),
            pay = store.get(media = Key("screen:payment"), content = Key("pay")),
            pendingTitle = store.get(media = Key("screen:payment"), content = Key("pendingTitle")),
            pendingText = store.get(media = Key("screen:payment"), content = Key("pendingText")),
            buttonGoBack = store.get(media = Key("screen:payment"), content = Key("buttonGoBack")),
            errorMessage = store.get(media = Key("screen:payment"), content = Key("errorMessage")),
            phoneNumber = store.get(media = Key("screen:payment"), content = Key("phoneNumber")),
            emailText = store.get(media = Key("screen:payment"), content = Key("emailText")),
            email = store.get(media = Key("screen:payment"), content = Key("email")),
            overview = store.get(media = Key("screen:payment"), content = Key("overview")),
            height = store.get(media = Key("screen:payment"), content = Key("height")),
            width = store.get(media = Key("screen:payment"), content = Key("width")),
            errorMessageOrderError = store.get(media = Key("screen:payment"), content = Key("errorMessageOrderError")),
        )


        val emailText = EmailText(
            urlSpeglar = store.get(media = Key("screen:order"), content = Key("urlSpeglar")),
            thankYouMessage = store.get(media = Key("screen:order"), content = Key("thankYouMessage")),
            openingHours = store.get(media = Key("screen:order"), content = Key("openingHours")),
            textRydsGlasSe = store.get(media = Key("screen:order"), content = Key("textRydsGlasSe")),
            instagramText = store.get(media = Key("screen:order"), content = Key("instagramText")),
            urlInstagram = store.get(media = Key("screen:order"), content = Key("urlInstagram")),
            contact = store.get(media = Key("screen:order"), content = Key("contact")),
            RydsPhone = store.get(media = Key("screen:order"), content = Key("RydsPhone")),
            RydsEmail = store.get(media = Key("screen:order"), content = Key("RydsEmail")),
            titleOverview = store.get(media = Key("screen:order"), content = Key("titleOverview")),
            linkRydsGlas = store.get(media = Key("screen:order"), content = Key("linkRydsGlas")),
            instagramName = store.get(media = Key("screen:order"), content = Key("instagramName")),
            emailsubject = store.get(media = Key("screen:order"), content = Key("emailsubject")),
            cost = store.get(media = Key("screen:order"), content = Key("cost")),
            vat = store.get(media = Key("screen:order"), content = Key("vat")),
            originalCost = store.get(media = Key("screen:order"), content = Key("originalCost")),
            discountCost = store.get(media = Key("screen:order"), content = Key("discountCost")),
            titleDiscount = store.get(media = Key("screen:order"), content = Key("titleDiscount")),


            )

        if (store.orderId?.rawValue == "") {
            return scene.failed(
                result = Either.Right(TechlaError.BadRequest("orderId token missing")),
                title = texts.errorMessage,
                buttonGoBack = texts.buttonGoBack,
            )
        }

        return successfulOf(Unit)
            .flatMap { getOrder(store, viewModel) }
            .flatMap { (order, orderItems) ->
                techla_log("THIS IS THE ORDER: ${order.status.rawValue}, ${order.id.rawValue} ${order.name}")
                techla_log("THIS IS THE ORDER FROM STORE: ${store.orderId?.rawValue}")
                val price = (order.total as? Order.Total.Price)?.amount?.amount ?: 0.0
                val amount = Amount(amount = price, currency = "SEK")

                val specifications = orderItems.map { orderItem ->
                    val quantity = (orderItem.unit as? Article.Unit.Pieces)?.quantity ?: 1
                    var name = orderItem.article?.specification ?: ""
                    val minCost = (orderItem.minimumCost as? Article.Cost.Price)?.amount?.amount ?: 100.0
                    val pay = (orderItem.total as? Order.Total.Price)?.amount?.amount ?: minCost
                    val tax = (orderItem.total as? Order.Total.Price)?.taxAmount?.amount!!
                    val dimensions = orderItem.dimensions
                    if (dimensions != null) {
                        val height = ((dimensions.height.times(1000))).roundToInt()
                        val width = ((dimensions.width.times(1000))).roundToInt()
                        name = "${orderItem.article!!.specification} - (${texts.width}: $width mm, ${texts.height}: $height mm)"
                    }

                    // TODO: This needs to be changed. There should be a better fit between Order and Payment.
                    Payment.Specification.Item(
                        name = name,
                        quantity = quantity,
                        itemAmount = Amount(amount = pay.div(quantity), currency = "SEK"),
                        totalAmount = Amount(amount = pay, currency = "SEK"),
                        taxRate = orderItem.taxRate,
                        totalTaxAmount = Amount(amount = tax, currency = "SEK"),
                        imageUrl = orderItem.article?.tags?.firstNotNullOfOrNull { (it as? Article.Tag.PublicImage)?.url }

                    )
                } + Payment.Specification.Order(
                    id = order.id,
                    taxAmount = order.taxAmount
                ) + listOfNotNull(
                    if (order.discount is Order.Discount.Percentage) {
                        val discount = order.discount as Order.Discount.Percentage
                        val discounts = order.discounts as Order.Total.Price
                        Payment.Specification.Discount(
                            name = discount.name,
                            quantity = 1,
                            itemAmount = discounts.amount,
                            totalAmount = discounts.amount,
                            taxRate = discount.taxRate,
                            totalTaxAmount = discounts.taxAmount,
                            imageUrl = null
                        )
                    } else null
                )

                val workShopId = (order.tags.firstOrNull() as? Order.Tag.Manufacturer)?.name
                val workShopCity =
                    RydsCities.cityList.firstOrNull { it.id.toString() == workShopId }?.city?.trim()
                val workShopNumber =
                    RydsCities.cityList.firstOrNull { it.id.toString() == workShopId }?.workshopNumber?.trim()
                store.paymentAPI.createPayment(
                    Payment.Create(
                        provider = Provider.KLARNA,
                        amount = amount,
                        creditor = Payment.Creditor.Merchant,
                        reference = "$workShopNumber - $workShopCity",
                        debtor = Payment.Debtor.Profile(
                            firstName = (order.contact as? Order.Contact.Full)?.firstName ?: "",
                            lastName = (order.contact as? Order.Contact.Full)?.lastName ?: "",
                            billingAddress = (order.delivery as? Order.Delivery.Home)?.address,
                            email = (order.contact as? Order.Contact.Full)?.email ?: "",
                            phoneNumber = (order.contact as? Order.Contact.Full)?.phoneNumber ?: ""
                        ),
                        remittance = Payment.Remittance.None,
                        specifications = specifications

                    )
                )
                    .map { payment ->
                        val newState =
                            (payment.provider.status as? Provider.Status.KlarnaCreated)?.clientToken?.let { it1 ->
                                viewModel.state.copy(
                                    clientToken = it1,
                                    paymentId = payment.id,
                                    order = order,
                                    orderItems = orderItems
                                )
                            }

                        Klarna.Payments.init(jso {
                            client_token = newState?.clientToken
                        })

                        val paymentMethod = createPaymentMethod(payment.availableMethods)


                        val cost = (order.total as? Order.Total.Price)?.amount?.amount


                        val emailInformation = EmailInformation(
                            firstName = (order.contact as? Order.Contact.Full)?.firstName!!,
                            lastName = (order.contact as? Order.Contact.Full)?.lastName!!,
                            email = (order.contact as? Order.Contact.Full)?.email!!,
                            phoneNumber = (order.contact as? Order.Contact.Full)?.phoneNumber!!,
                            street = ((order.delivery as? Order.Delivery.Home)?.address as? Address.Swedish)?.street,
                            postalCode =
                            ((order.delivery as? Order.Delivery.Home)?.address as? Address.Swedish)?.postalCode,
                            city = ((order.delivery as? Order.Delivery.Home)?.address as? Address.Swedish)?.city,
                            selectedCity = RydsCities.cityList.firstOrNull { it.id.toString() == workShopId }?.city?.trim()
                                ?: "",
                            totalCost = cost.toString(),
                            //totatlCost = formatTwoDigits(floorTheCost),
                            orderItems = orderItems,
                            discountName = (order.discount as? Order.Discount.Percentage)?.name,
                            discountCost = order.discountCost,
                            originalCost = order.originalCost,
                        )
                        techla_log("IN LOAD -> Accepted -> ${viewModel.state.orderItems} ***** ${viewModel.state.order}")

                        return sceneOf<ViewModel>(
                            viewModel.payment(
                                texts = texts,
                                state = newState!!,
                                paymentMethod = paymentMethod,
                                emailText = emailText,
                                emailInformation = emailInformation
                            ),
                        )
                    }
            }

            .fold(
                onSuccess = { it },
                onNotSuccess = {
                    techla_log("6 PAYMENT FAILED"); scene.failed(
                    result = it,
                    title = texts.errorMessage,
                    buttonGoBack = texts.buttonGoBack,
                )
                }
            )


    }


    private suspend fun refresh(store: Store, id: Identifier<Order>) =
        store.orderAPI.run {
            getOrder(id).zip(listOrderItems(id))
        }

    fun failed(scene: Scene.Input<ViewModel>, result: Either<List<Warning>, Throwable>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return scene.failed(
            result = result,
            title = viewModel.texts.errorMessage,
            buttonGoBack = viewModel.texts.buttonGoBack,
        )

    }


    fun dataLayerPurchase(orderItems: List<OrderItem>?, order: Order?) {
        Ecommerce.ga3Purchase(orderItems, order)
        Ecommerce.ga4Purchase(orderItems, order)
    }

}




