package screens

import support.RydsCities
import kotlinx.js.jso
import support.*
import techla.base.*
import techla.order.*
import kotlin.math.absoluteValue
import kotlin.math.roundToInt
import kotlin.time.ExperimentalTime

val Article.category
    get() = tags.filterIsInstance<Article.Tag.Category>().firstOrNull()


val Article.isInstallation
    get() = key.rawValue == "INSTALLATION"

val Article.isAdditionalInstallation
    get() = key.rawValue == "ADDITIONALINSTALLATION"

val Article.isStartup
    get() = key.rawValue == "STARTUPCOST"

val Article.isCategoryMontage
    get() = category?.key == Key<Article>(
        "MONTAGE"
    )


val Article.isHiddenSuspension
    get() = key.rawValue == "HIDDEN_SUSPENSION"

val Article.isSafetyFilm
    get() = key.rawValue == "SAFETYFILM"

val Article.isGlue
    get() = key.rawValue == "GLUE"

val Article.publicImage
    get() = tags.firstNotNullOfOrNull { (it as? Article.Tag.PublicImage)?.url?.toString() } ?: ""

val Article.price
    get() = ((cost as? Article.Cost.Price)?.amount?.amount ?: 0.0)

val OrderItem.quantity
    get() = ((unit as? Article.Unit.Pieces)?.quantity ?: 1)


val OrderItem.dimensions
    get() = tags.filterIsInstance<OrderItem.Tag.Dimensions>().firstOrNull()

val OrderItem.uncutSquareMeters
    get() = dimensions?.let { it.height * it.width } ?: 0.0

val OrderItem.cutSquareMeters
    get() = dimensions?.let {
        val r = (it.width / 2.0)
        when {
            article?.isRectangle == true -> it.height * it.width
            article?.isSquare == true -> it.height * it.width
            article?.isCircle == true -> r * 3.14 * 2.0
            // half circle + remaining rectangle
            article?.isArch == true -> (r * 3.14 * 2.0) / 2.0 + (it.height - r) * it.width
            else -> null
        }
    } ?: 0.0

val OrderItem.weight
    get() = cutSquareMeters * 2.5 * 4


val Order?.totalCost
    get() = (this?.total as? Order.Total.Price)?.amount?.amount?.toInt() ?: 0

val Order?.discountCost
    get() = (this?.discounts as? Order.Total.Price)?.amount?.amount?.toInt()?.absoluteValue ?: 0

val Order?.originalCost
    get() = totalCost + discountCost


fun format(value: Double): String = value.asDynamic().toLocaleString("sv-SE", jso {
    minimumFractionDigits = 2
    maximumFractionDigits = 4
}) as String


fun replaceAndFormat(value: String): Double = value.replaceFirst(",", ".").toDouble()


fun removeWhiteSpaces(value: String): Int = value.replace(" ", "").toInt()

@ExperimentalTime
object OrderScreen {

    data class Texts(
        val title: String,
        val titleMontage: String,
        val titleCustomer: String,
        val titlePayment: String,
        val overview: String,
        val subtitleMontage1: String,
        val subtitleMontage2: String,
        val subtitleMontage3: String,
        val subtitleMontage4: String,
        val toSeeAddress: String,
        val glue: String,
        val screw: String,
        val buttonTitle: String,
        val buttonBack: String,
        val buttonClickHere: String,
        val buttonDuplicate: String,
        val vat: String,
        val unit: String,
        val cost: String,
        val total: String,
        val amount: String,
        val selectCity: String,
        val questionMark1: String,
        val questionMark2: String,
        val questionMark3: String,
        val questionMark4: String,
        val orderEmail: String,
        val linkRydsGlas: String,
        val firstName: String,
        val lastName: String,
        val email: String,
        val phoneNumber: String,
        val street: String,
        val postalCode: String,
        val city: String,
        val buttonGoToPayment: String,
        val buttonGoBack: String,
        val errorMessage: String,
        val validationSelectCity: String,
        val validationContactInformation: String,
        val validationCase1: String,
        val validationCase2: String,
        val validationCase3: String,
        val linkText: String,
        val instructions: String,
        val titleCoupon: String,
        val couponPercentage1: String,
        val couponPercentage2: String,
        val buttonRedeem: String,
        val couponNoMatch: String,
        val originalCost: String,
        val discountCost: String,
    )

    data class State(
        val cartOpen: Boolean,
        val order: Order?,
        val orderItem: List<OrderItem>?,
        val totalCost: String,
        val activeAttachment: OptionAttachment,
        val selectedCity: String,
        val montageInOrder: Boolean,
        val couponFailed: Boolean,
    )

    data class Item(
        val title: String,
        val article: Article,
        val id: Identifier<OrderItem>,
        val height: Int,
        val width: Int,
        val cost: String,
        val unit: Int,
        val imgLink: String?,
    )

    data class Attachment(
        val name: String,
        val imgLink: String?,
        val article: Article,
        val selected: Boolean?,
        val cost: Int,
    )

    data class Montage(
        val name: String,
        val imgLink: String?,
        val article: Article,
        val selected: Boolean?,
        val cost: Int,
    )

    enum class OptionAttachment {
        Screw, Glue,
    }

    data class PersonalData(
        val firstName: String,
        val lastName: String,
        val email: String,
        val phoneNumber: String,
        val address: Address?,
    )


    sealed class ViewModel(
        val texts: Texts,
        val state: State,
        val cities: List<RydsCities.City>,
        val personalData: PersonalData,
    ) {
        object None : ViewModel(
            Texts(
                "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",
            ), State(false, null, null, "0,0", OptionAttachment.Glue, "", false, false), listOf(), PersonalData("", "", "", "", Address.Swedish("", 0, ""))
        )

        class Started(
            texts: Texts,
            state: State,
            cities: List<RydsCities.City>,
            personalData: PersonalData,

            ) : ViewModel(texts, state, cities, personalData)

        class Cart(
            texts: Texts,
            state: State,
            cities: List<RydsCities.City>,
            val items: List<Item>,
            val attachments: List<Attachment>,
            val montage: List<Montage>,
            personalData: PersonalData,
            val mirrorsInOrder: Int,


            ) : ViewModel(texts, state, cities, personalData)

        class Failed(
            texts: Texts, state: State, cities: List<RydsCities.City>, personalData: PersonalData, val message: String
        ) : ViewModel(texts, state, cities, personalData)

        fun started() = Started(texts = texts, state = state, cities = cities, personalData = personalData)

        fun cart(
            texts: Texts,
            state: State,
            items: List<Item>,
            attachments: List<Attachment>,
            montage: List<Montage>,
            cities: List<RydsCities.City>,
            personalData: PersonalData,
            mirrorsInOrder: Int,

            ) = Cart(
            texts = texts,
            state = state,
            cities = cities,
            items = items,
            attachments = attachments,
            montage = montage,
            personalData = personalData,
            mirrorsInOrder = mirrorsInOrder,


            )

        fun failed(message: String) = Failed(
            texts = texts, state = state, cities = cities, message = message, personalData = personalData
        )

        fun failed(result: Either<List<Warning>, Throwable>) = failed(
            message = when (result) {
                is Either.Left -> "(${result.value.joinToString(", ") { it.message }})"
                is Either.Right -> "(${result.value.message})"
            }
        )
    }


    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) = sceneOf<ViewModel>(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return sceneOf(viewModel.started())
    }


    fun openCart(scene: Scene.Input<ViewModel>, isOpen: Boolean): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val changeCart = viewModel.state.copy(cartOpen = isOpen)

        return createItems(
            store.articles, viewModel.state.orderItem!!, texts = viewModel.texts, state = changeCart, viewModel = viewModel, personalData = viewModel.personalData
        )
    }

    private suspend fun createOrderItem(store: Store, create: OrderItem.Create, viewModel: ViewModel, montageInOrder: Boolean) = store.orderAPI.createOrderItem(create).flatMap { item ->
        refresh(store, store.orderId!!).map { tupleOf(it.first, it.second, item) }
    }.map { (order, orderItems, orderItem) ->
        val cost = (order.total as? Order.Total.Price)?.amount?.amount
        dataLayerAddToCart(orderItem)
        val newState = viewModel.state.copy(
            order = order, orderItem = orderItems, cartOpen = true, totalCost = cost?.toString() ?: "0", montageInOrder = montageInOrder
        )
        Pair(orderItems, newState)
    }


    private suspend fun editOrderItem(store: Store, id: Identifier<OrderItem>, edit: OrderItem.Edit, viewModel: ViewModel) = store.orderAPI.editOrderItem(id, edit).flatMap { refresh(store, store.orderId!!) }.map { (order, orderItems) ->
        val cost = (order.total as? Order.Total.Price)?.amount?.amount
        val newState = viewModel.state.copy(
            order = order, orderItem = orderItems, cartOpen = true, totalCost = cost.toString()
        )
        Pair(orderItems, newState)
    }


    private suspend fun createExtraOrderItem(store: Store, viewModel: ViewModel, quantity: Int, rawKey: String) {
        val articleMontage = store.articles.firstOrNull {
            it.key == Key<Article>(
                rawKey
            )
        }

        val create = OrderItem.Create(
            orderId = viewModel.state.order!!.id,
            article = articleMontage?.key!!,
            unit = Article.Unit.Pieces(quantity),
        )

        createOrderItem(store, create, viewModel, montageInOrder = true).map { (orderItems, newState) ->
            createItems(
                store.articles, orderItems, texts = viewModel.texts, state = newState, viewModel = viewModel, personalData = viewModel.personalData
            )

        }
    }

    private suspend fun updateOrderItem(store: Store, viewModel: ViewModel, orderItem: OrderItem, quantity: Int) {

        val edit = OrderItem.Edit(unit = modifiedOf(Article.Unit.Pieces(quantity)))

        editOrderItem(store, id = orderItem.id, edit, viewModel).map { (orderItems, newState) ->
            createItems(
                store.articles, orderItems, texts = viewModel.texts, state = newState, viewModel = viewModel, personalData = viewModel.personalData
            )
        }
    }

    suspend fun addOrRemoveItem(scene: Scene.Input<ViewModel>, article: Article): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val mirrorsInOrder = viewModel.state.orderItem?.filter { it.article?.kind == Article.Kind.None }
        val containsArticle = viewModel.state.orderItem?.filter { it.article!!.id == article.id }
        val containsInstallation = viewModel.state.orderItem?.firstOrNull { (it.article?.isInstallation == true) && it.article?.category?.name == "Basic" }

        val additionalArticles = viewModel.state.orderItem?.filter { it.article?.isCategoryMontage == true || it.article?.category?.name == "SafetyFilm" }
        val additionalMontage = viewModel.state.orderItem?.firstOrNull { it.article?.isAdditionalInstallation == true }
        var montageInOrder = viewModel.state.montageInOrder


        if (mirrorsInOrder?.size == 0 && (article.category?.name == "Basic" || article.isCategoryMontage)) {
            return createItems(
                store.articles, viewModel.state.orderItem, texts = viewModel.texts, state = viewModel.state, viewModel = viewModel, personalData = viewModel.personalData
            )
        }


        // Removes Montage articles if Installation is added
        if (additionalArticles != null && additionalArticles.isNotEmpty() && article.category?.name == "Basic") {
            additionalArticles.map {
                removeItem(scene, it.id)
            }
        }



        if (article.category?.name == "Basic" && containsInstallation == null) {
            montageInOrder = true
        }


        //Rewmoves additional installation
        if (article.category?.name == "Basic" && containsInstallation != null) {
            removeItem(scene, containsInstallation.id)
            montageInOrder = false

        }

        // Create additional installation
        if (article.category?.name == "Basic" && containsInstallation == null && additionalMontage == null && (mirrorsInOrder?.size ?: 0) > 1) {
            val quantity = mirrorsInOrder?.size?.minus(1)

            createExtraOrderItem(store, viewModel, quantity!!, "ADDITIONALINSTALLATION")


        }

// Create a orderItem from checkOutPage
        if (containsArticle.isNullOrEmpty()) {
            val create = OrderItem.Create(
                orderId = viewModel.state.order!!.id,
                article = article.key,
            )

            return createOrderItem(store, create, viewModel, montageInOrder = montageInOrder).map { (orderItems, newState) ->
                createItems(
                    store.articles, orderItems, texts = viewModel.texts, state = newState, viewModel = viewModel, personalData = viewModel.personalData
                )

            }.fold(onSuccess = { it }, onNotSuccess = { scene.failed(result = it) })

        } else {
            return successfulOf(Unit).map {
                removeItem(scene, containsArticle.first().id)
            }.fold(onSuccess = { it }, onNotSuccess = { scene.failed(result = it) })
        }
    }


    suspend fun saveToCart(scene: Scene.Input<ViewModel>, orderItem: OrderItem): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val mirrorsInOrder = viewModel.state.orderItem?.filter { it.article?.kind == Article.Kind.None }
        val containsInstallation = viewModel.state.orderItem?.firstOrNull { it.article?.isInstallation == true && it.article?.category?.name == "Basic" }
        val startUpCost = viewModel.state.orderItem?.firstOrNull { it.article?.isStartup == true }
        val additionalMontage = viewModel.state.orderItem?.firstOrNull { it.article?.isAdditionalInstallation == true }
        val montageInOrder = viewModel.state.montageInOrder

        // CREATE STARTUPCOST ON MIRRORS
        if (orderItem.article?.kind == Article.Kind.None && startUpCost == null) {
            createExtraOrderItem(store, viewModel, 1, "STARTUPCOST")
        }

        // UPPDATE STARTUPCOST ON MIRRORS
        if (orderItem.article?.kind == Article.Kind.None && startUpCost != null) {
            val quantity = mirrorsInOrder?.size?.plus(1)

            updateOrderItem(store, viewModel, startUpCost, quantity!!)
        }


        // Create Additional Installation - from duplicate
        if (orderItem.article?.kind == Article.Kind.None && containsInstallation != null && additionalMontage == null && (mirrorsInOrder?.size ?: 0) >= 1) {
            val quantity = mirrorsInOrder?.size
            createExtraOrderItem(store, viewModel, quantity!!, "ADDITIONALINSTALLATION")
        }

        // Update Additional Installation - from duplicate
        if (orderItem.article?.kind == Article.Kind.None && containsInstallation != null && additionalMontage != null && (mirrorsInOrder?.size ?: 0) > 1) {
            val quantity = mirrorsInOrder?.size
            updateOrderItem(store, viewModel, additionalMontage, quantity!!)

        }

        //Remove ADDITIONALINSTALLATION
        if (orderItem.article == containsInstallation?.article && containsInstallation != null && additionalMontage != null) {
            removeItem(scene, additionalMontage.id)
        }

        val orderItemArea = orderItem.unit.squareMeters?.let { format(it) }

        val isNotAMirror = orderItem.article?.isCategoryMontage == true

        val create = if (isNotAMirror) {
            OrderItem.Create(
                orderId = store.orderId!!, article = orderItem.article!!.key, tags = orderItem.tags
            )
        } else OrderItem.Create(
            orderId = store.orderId!!, article = orderItem.article!!.key, unit = Article.Unit.Area(replaceAndFormat(orderItemArea!!)), tags = orderItem.tags
        )


        return createOrderItem(store, create, viewModel, montageInOrder = montageInOrder).map { (orderItems, newState) ->
            createItems(
                store.articles, orderItems, texts = viewModel.texts, state = newState, viewModel = viewModel, personalData = viewModel.personalData
            )
        }.fold(onSuccess = { it }, onNotSuccess = { scene.failed(result = it) })
    }

    suspend fun duplicateItem(scene: Scene.Input<ViewModel>, item: Item): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val copyItem = viewModel.state.orderItem?.find { item.id == it.id }

        return successfulOf(Unit).map {
            saveToCart(orderItem = copyItem!!, scene = scene)
        }.fold(onSuccess = { it }, onNotSuccess = { scene.failed(result = it) })

    }


    suspend fun removeItem(scene: Scene.Input<ViewModel>, id: Identifier<OrderItem>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val orderItem = viewModel.state.orderItem?.firstOrNull { it.id == id }
        val startUpCost = viewModel.state.orderItem?.firstOrNull { it.article?.isStartup == true }
        val containsInstallation = viewModel.state.orderItem?.firstOrNull { it.article?.isInstallation == true && it.article?.category?.name == "Basic" }
        val additionalMontage = viewModel.state.orderItem?.firstOrNull { it.article?.isAdditionalInstallation == true }

        val mirrorsInOrder = viewModel.state.orderItem?.filter { it.article?.kind == Article.Kind.None }

        val additionalArticles = viewModel.state.orderItem?.filter { it.article?.isCategoryMontage == true }

        val montageInOrder = if (containsInstallation?.id == id) {
            false
        } else viewModel.state.montageInOrder


        // DELETE additional articles When no mirrors
        if (mirrorsInOrder?.size == 1 && additionalArticles != null && additionalArticles.isNotEmpty() && orderItem?.article?.kind == Article.Kind.None) {
            additionalArticles.map {
                removeItem(scene, it.id)
            }
        }


        // DELETE STARTUPCOST ON MIRRORS
        if ((orderItem?.article?.kind == Article.Kind.None) && (startUpCost != null) && ((startUpCost.unit as? Article.Unit.Pieces)?.quantity!! == 1)) {
            removeItem(scene, startUpCost.id)
        }

        // UPDATE STARTUPCOST ON MIRRORS
        if ((orderItem?.article?.kind == Article.Kind.None) && (startUpCost != null) && ((startUpCost.unit as? Article.Unit.Pieces)?.quantity!! > 1)) {
            val quantity = mirrorsInOrder?.size?.minus(1)
            updateOrderItem(store, viewModel, startUpCost, quantity!!)
        }

        // Removes one Additional Installation
        if (containsInstallation != null && additionalMontage != null && (mirrorsInOrder?.size ?: 0) >= 3 && orderItem?.article?.kind == Article.Kind.None) {
            val quantity = mirrorsInOrder?.size?.minus(1)
            updateOrderItem(store, viewModel, additionalMontage, quantity!!)
        }

        //Removes one Additional Installation
        if (containsInstallation != null && additionalMontage != null && (mirrorsInOrder?.size ?: 0) > 2 && orderItem?.article?.kind == Article.Kind.None) {
            val quantity = mirrorsInOrder?.size?.minus(2)
            updateOrderItem(store, viewModel, additionalMontage, quantity!!)
        }
        // Removes all Additional Installation
        if (containsInstallation != null && additionalMontage != null && (mirrorsInOrder?.size ?: 0) == 2 && orderItem?.article?.kind == Article.Kind.None) {
            removeItem(scene, additionalMontage.id)
        }

        // Removes all Additional Installation
        if (additionalMontage != null && orderItem?.article?.isInstallation == true && orderItem.article?.category?.name == "Basic") {
            removeItem(scene, additionalMontage.id)
        }

        // Removes Installation
        if (containsInstallation != null && mirrorsInOrder?.size == 1 && orderItem?.article?.kind == Article.Kind.None) {
            removeItem(scene, containsInstallation.id)
        }

        val removedItem = viewModel.state.orderItem?.filter { item -> item.id == id }

        return store.orderAPI.deleteOrderItem(id).flatMap { refresh(store, viewModel.state.order!!.id) }.map { (order, orderItem) ->
            removedItem?.firstOrNull()?.let {
                dataLayerRemoveFrom(it)
            }
            val cost = (order.total as? Order.Total.Price)?.amount?.amount
            val newState = viewModel.state.copy(
                order = order, orderItem = orderItem, totalCost = cost?.toString() ?: "0", montageInOrder = montageInOrder

            )
            createItems(
                store.articles, orderItem, texts = viewModel.texts, state = newState, viewModel = viewModel, personalData = viewModel.personalData
            )
        }.fold(onSuccess = { it }, onNotSuccess = { scene.failed(result = it) })

    }


    private fun createItems(
        activeArticles: List<Article>, orderItems: List<OrderItem>, texts: Texts, state: State, viewModel: ViewModel, personalData: PersonalData, actions: List<Store.Action> = emptyList()
    ): Scene.Output<ViewModel.Cart> {
        val items = orderItems.map {
            val pay = (it.total as? Order.Total.Price)?.amount?.amount

            val dimensions = it.dimensions
            if (dimensions != null) {
                val area = (it.unit as? Article.Unit.Area)?.squareMeters
            }

            Item(
                title = it.article!!.specification,
                id = it.id,
                article = it.article!!,
                height = ((dimensions?.height?.times(1000)) ?: 0.0).roundToInt(),
                width = ((dimensions?.width?.times(1000)) ?: 0.0).roundToInt(),
                cost = pay.toString(),
                unit = (it.unit as? Article.Unit.Pieces)?.quantity ?: 1,
                imgLink = it.article?.publicImage,
            )
        }
        val attachments = createAttachment(activeArticles, orderItems)
        val montage = createMontage(activeArticles, orderItems)
        val cities = createCities(RydsCities.cityList)
        val mirrorsInOrder = orderItems.filter { it.article?.kind == Article.Kind.None }.size

        return sceneOf(
            viewModel.cart(
                texts = texts,
                state = state,
                items = items,
                attachments = attachments,
                montage = montage,
                cities = cities,
                personalData = personalData,
                mirrorsInOrder = mirrorsInOrder,
            ), actions
        )
    }


    suspend fun saveContactInformation(
        scene: Scene.Input<ViewModel>, firstName: String, lastName: String, email: String, phoneNumber: String, street: String, postCode: Int, city: String
    ): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val personalData = PersonalData(
            firstName = firstName, lastName = lastName, email = email, phoneNumber = phoneNumber, address = Address.Swedish(
                street = street, postalCode = postCode, city = city
            )
        )

        val edit = if (viewModel.state.montageInOrder) {
            Order.Edit(
                contact = modifiedOf(
                    Order.Contact.Full(
                        firstName = personalData.firstName, lastName = personalData.lastName, email = personalData.email, phoneNumber = personalData.phoneNumber
                    )
                ),
                delivery = modifiedOf(
                    Order.Delivery.Home(
                        Address.Swedish(
                            (personalData.address as? Address.Swedish)?.street!!, (personalData.address as? Address.Swedish)?.postalCode!!, (personalData.address as? Address.Swedish)?.city!!
                        )
                    )
                ),
            )
        } else {
            Order.Edit(
                contact = modifiedOf(
                    Order.Contact.Full(
                        firstName = personalData.firstName, lastName = personalData.lastName, email = personalData.email, phoneNumber = personalData.phoneNumber
                    )
                ),
                delivery = modifiedOf(Order.Delivery.PickUp),
            )
        }

        editOrder(store, viewModel, edit)

        val allStartupCost = viewModel.state.orderItem?.filter { it.article?.isStartup == true }
        val allKindArticle = viewModel.state.orderItem?.filter { it.article?.kind is Article.Kind.None }

        // UPPDATE STARTUPCOST ON MIRRORS
        if (allStartupCost?.size != allKindArticle?.size && (allStartupCost?.size ?: 0) < (allKindArticle?.size ?: 0)) {
            val create = OrderItem.Create(
                orderId = viewModel.state.order!!.id,
                article = Key("STARTUPCOST"),
                unit = Article.Unit.Pieces(allKindArticle?.size?.minus(allStartupCost?.size ?: 0) ?: 0),
            )
            createOrderItem(store, create, viewModel, montageInOrder = viewModel.state.montageInOrder).map { (orderItems, newState) ->
                viewModel.state.orderItem?.let { dataLayerCheckout(it) }
                return createItems(
                    store.articles, orderItems, texts = viewModel.texts, state = newState, viewModel = viewModel, personalData = personalData
                )
            }
        }

        viewModel.state.orderItem?.let { dataLayerCheckout(it) }

        return createItems(
            store.articles, viewModel.state.orderItem!!, texts = viewModel.texts, state = viewModel.state, viewModel = viewModel, personalData = personalData
        )
    }

    private fun createAttachment(activeArticles: List<Article>, orderItems: List<OrderItem>): List<Attachment> {
        val listToHeavy = orderItems.filter { it.weight >= 35.00 }

        val attachmentList = if (listToHeavy.isNotEmpty()) {
            activeArticles.filter { !it.isHiddenSuspension }
        } else {
            activeArticles
        }


        val attachmentsList = if (orderItems.any { it.article?.key == Key<Article>("GLUE") }) {
            attachmentList.filter { !it.isSafetyFilm }
        } else if (orderItems.any { it.article?.key == Key<Article>("SAFETYFILM") }) {
            attachmentList.filter { !it.isGlue }
        } else {
            attachmentList
        }

        return attachmentsList.filter {
            it.kind is Article.Kind.Extra && !it.isInstallation && it.isCategoryMontage
        }.map { item ->

            val isSelected = orderItems.filter { it.article?.id == item.id }

            Attachment(
                name = item.specification, selected = isSelected.isNotEmpty(), imgLink = item.publicImage, article = item, cost = item.price.roundToInt()

            )
        }

    }

    private fun createMontage(activeArticles: List<Article>, orderItems: List<OrderItem>) = activeArticles.filter {
        it.kind is Article.Kind.Extra && it.isInstallation
    }

        .map { item ->
            val isSelected = orderItems.filter { it.article?.id == item.id }
            Montage(
                name = item.specification, selected = isSelected.isNotEmpty(), imgLink = item.publicImage, article = item, cost = item.price.roundToInt()
            )

        }


    private fun createCities(activeCities: List<RydsCities.City>) = activeCities.map {
        RydsCities.City(
            businessArea = it.businessArea.trim(), city = it.city.trim(), companyName = it.companyName.trim(), contactPerson = it.contactPerson.trim(), phone = it.phone.trim(), address = it.address.trim(), workshopNumber = it.workshopNumber.trim(), email = it.email.trim(), id = it.id
        )
    }.sortedBy { it.city }


    private suspend fun editOrder(store: Store, viewModel: ViewModel, edit: Order.Edit) {
        store.orderAPI.editOrder(
            id = store.orderId!!, edit
        ).flatMap { refresh(store, store.orderId) }.map { (order, orderItems) ->
            val newState = viewModel.state.copy(order = order, orderItem = orderItems)

            createItems(
                store.articles, orderItems, texts = viewModel.texts, state = newState, viewModel = viewModel, personalData = viewModel.personalData
            )

        }
    }

    suspend fun addCityToOrder(scene: Scene.Input<ViewModel>, workShopId: String): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        store.orderAPI.editOrder(
            id = store.orderId!!, Order.Edit(
                delivery = modifiedOf(Order.Delivery.PickUp), tags = modifiedOf(
                    listOf(
                        Order.Tag.Manufacturer(name = workShopId, key = Key("WORKSHOPID")),
                    )
                )

            )
        )
        return successfulOf(Unit).flatMap { refresh(store, store.orderId) }.map { (order, orderItem) ->
            val newState = viewModel.state.copy(
                order = order,
                orderItem = orderItem,
                selectedCity = (order.tags.first() as? Order.Tag.Manufacturer)?.name ?: viewModel.texts.selectCity,
            )


            return createItems(
                store.articles, orderItem, texts = viewModel.texts, state = newState, viewModel = viewModel, personalData = viewModel.personalData

            )

        }.fold(onSuccess = { it }, onNotSuccess = { scene.failed(result = it) })
    }


    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene


        val texts = Texts(
            title = store.get(media = Key("screen:order"), content = Key("title")),
            titleMontage = store.get(media = Key("screen:order"), content = Key("titleMontage")),
            titleCustomer = store.get(media = Key("screen:order"), content = Key("titleCustomer")),
            titlePayment = store.get(media = Key("screen:order"), content = Key("titlePayment")),
            overview = store.get(media = Key("screen:order"), content = Key("overview")),
            subtitleMontage1 = store.get(media = Key("screen:order"), content = Key("subtitleMontage.1")),
            subtitleMontage2 = store.get(media = Key("screen:order"), content = Key("subtitleMontage.2")),
            subtitleMontage3 = store.get(media = Key("screen:order"), content = Key("subtitleMontage.3")),
            subtitleMontage4 = store.get(media = Key("screen:order"), content = Key("subtitleMontage.4")),
            toSeeAddress = store.get(media = Key("screen:order"), content = Key("toSeeAddress")),
            glue = store.get(media = Key("screen:order"), content = Key("glue")),
            screw = store.get(media = Key("screen:order"), content = Key("screw")),
            buttonTitle = store.get(media = Key("screen:order"), content = Key("buttonTitle")),
            buttonBack = store.get(media = Key("screen:order"), content = Key("buttonBack")),
            buttonClickHere = store.get(media = Key("screen:order"), content = Key("buttonClickHere")),
            buttonDuplicate = store.get(media = Key("screen:order"), content = Key("buttonDuplicate")),
            vat = store.get(media = Key("screen:order"), content = Key("vat")),
            unit = store.get(media = Key("screen:order"), content = Key("unit")),
            cost = store.get(media = Key("screen:order"), content = Key("cost")),
            total = store.get(media = Key("screen:order"), content = Key("total")),
            amount = store.get(media = Key("screen:order"), content = Key("amount")),
            selectCity = store.get(media = Key("screen:order"), content = Key("selectCity")),
            questionMark1 = store.get(media = Key("screen:order"), content = Key("questionMark.1")),
            questionMark2 = store.get(media = Key("screen:order"), content = Key("questionMark.2")),
            questionMark3 = store.get(media = Key("screen:order"), content = Key("questionMark.3")),
            questionMark4 = store.get(media = Key("screen:order"), content = Key("questionMark.4")),
            orderEmail = store.get(media = Key("screen:order"), content = Key("orderEmail")),
            linkRydsGlas = store.get(media = Key("screen:order"), content = Key("linkRydsGlas")),
            firstName = store.get(media = Key("screen:order"), content = Key("firstName")),
            lastName = store.get(media = Key("screen:order"), content = Key("lastName")),
            email = store.get(media = Key("screen:order"), content = Key("email")),
            phoneNumber = store.get(media = Key("screen:order"), content = Key("phoneNumber")),
            street = store.get(media = Key("screen:order"), content = Key("street")),
            postalCode = store.get(media = Key("screen:order"), content = Key("postalCode")),
            city = store.get(media = Key("screen:order"), content = Key("city")),
            buttonGoToPayment = store.get(media = Key("screen:order"), content = Key("buttonGoToPayment")),
            buttonGoBack = store.get(media = Key("screen:order"), content = Key("buttonGoBack")),
            errorMessage = store.get(media = Key("screen:order"), content = Key("errorMessage")),
            validationSelectCity = store.get(media = Key("screen:order"), content = Key("validationSelectCity")),
            validationContactInformation = store.get(
                media = Key("screen:order"), content = Key("validationContactInformation")
            ),
            validationCase1 = store.get(media = Key("screen:order"), content = Key("validationCase.1")),
            validationCase2 = store.get(media = Key("screen:order"), content = Key("validationCase.2")),
            validationCase3 = store.get(media = Key("screen:order"), content = Key("validationCase.3")),
            linkText = store.get(media = Key("screen:order"), content = Key("linkText")),
            instructions = store.get(media = Key("screen:order"), content = Key("instructions")),
            titleCoupon = store.get(media = Key("screen:order"), content = Key("titleCoupon")),
            couponPercentage1 = store.get(media = Key("screen:order"), content = Key("couponPercentage.1")),
            couponPercentage2 = store.get(media = Key("screen:order"), content = Key("couponPercentage.2")),
            buttonRedeem = store.get(media = Key("screen:order"), content = Key("buttonRedeem")),
            couponNoMatch = store.get(media = Key("screen:order"), content = Key("couponNoMatch")),
            originalCost = store.get(media = Key("screen:order"), content = Key("originalCost")),
            discountCost = store.get(media = Key("screen:order"), content = Key("discountCost")),
        )


        val existing = if (store.orderId != null) {
            refresh(store, store.orderId).map { (order, orderItem) ->
                techla_log("ORDER: Resumed")
                val montageInOrder = (orderItem.firstOrNull { it.article?.isInstallation == true } != null)

                val cost = (order.total as? Order.Total.Price)?.amount?.amount
                val personalData = PersonalData(
                    firstName = (order.contact as? Order.Contact.Full)?.firstName ?: "", lastName = (order.contact as? Order.Contact.Full)?.lastName ?: "", email = (order.contact as? Order.Contact.Full)?.email ?: "", phoneNumber = (order.contact as? Order.Contact.Full)?.phoneNumber ?: "", address = Address.Swedish(
                        street = ((order.delivery as? Order.Delivery.Home)?.address as? Address.Swedish)?.street ?: "", postalCode = ((order.delivery as? Order.Delivery.Home)?.address as? Address.Swedish)?.postalCode ?: 0, city = ((order.delivery as? Order.Delivery.Home)?.address as? Address.Swedish)?.city ?: ""
                    )
                )


                val newState = viewModel.state.copy(
                    order = order, orderItem = orderItem, cartOpen = false, totalCost = cost?.toString() ?: "0", selectedCity = (order.tags.first() as? Order.Tag.Manufacturer)?.name ?: texts.selectCity, montageInOrder = montageInOrder
                )

                createItems(
                    store.articles, orderItem, texts = texts, state = newState, viewModel = viewModel, personalData = personalData
                )

            }.fold(onSuccess = { it }, onNotSuccess = { scene.failed(result = it) })
        } else {
            null
        }

        return if (existing?.viewModel is ViewModel.Cart) {
            existing
        } else {
            techla_log("Create newOrder")
            createOrder(scene, texts)
        }
    }


    suspend fun createOrder(scene: Scene.Input<ViewModel>, texts: Texts): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val create = Order.Create(
            name = "Order", rounding = Order.Rounding.Fixed(0), tags = listOf(
                Order.Tag.Manufacturer(name = texts.selectCity, key = Key("WORKSHOPID"))
            )
        )
        val cities = createCities(RydsCities.cityList)
        return store.orderAPI.createOrder(create).flatMap { refresh(store, it.id) }.map { (order, orderItem) ->
            val actions = listOf(
                Store.Action.OrderCreated(orderId = order.id)
            )

            val newState = viewModel.state.copy(
                cartOpen = false,
                order = order,
                orderItem = orderItem,
                selectedCity = texts.selectCity,
                totalCost = "0",
                montageInOrder = false,
                activeAttachment = OptionAttachment.Glue,

                )


            val attachments = createAttachment(store.articles, orderItem)
            val montage = createMontage(store.articles, orderItem)
            val personalData = PersonalData(
                firstName = "",
                lastName = "",
                email = "",
                phoneNumber = "",
                address = Address.Swedish(
                    street = "", postalCode = 0, city = ""
                ),
            )
            val mirrorsInOrder = orderItem.filter { it.article?.kind == Article.Kind.None }.size


            return sceneOf<ViewModel>(
                viewModel.cart(
                    texts = texts,
                    state = newState,
                    items = emptyList(),
                    attachments = attachments,
                    montage = montage,
                    cities = cities,
                    personalData = personalData,
                    mirrorsInOrder = mirrorsInOrder,
                ), actions
            )
        }.fold(onSuccess = { it }, onNotSuccess = { scene.failed(result = it) })
    }

    suspend fun addCoupon(scene: Scene.Input<ViewModel>, coupon: String): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val orderId = viewModel.state.order!!.id

        val redeem = Coupon.Redeem(coupon = Key(coupon.uppercase()))
        return store.orderAPI.redeemCoupon(orderId, redeem).flatMap { refresh(store, orderId) }.map { (order, orderItem) ->
            val cost = (order.total as? Order.Total.Price)?.amount?.amount
            val newState = viewModel.state.copy(
                order = order,
                orderItem = orderItem,
                totalCost = cost?.toString() ?: "0",
                couponFailed = false,
            )
            createItems(
                store.articles, orderItem, texts = viewModel.texts, state = newState, viewModel = viewModel, personalData = viewModel.personalData
            )
        }.fold(onSuccess = { it }, onNotSuccess = {
            val newState = viewModel.state.copy(
                couponFailed = true,
            )
            createItems(
                store.articles, viewModel.state.orderItem ?: emptyList(), texts = viewModel.texts, state = newState, viewModel = viewModel, personalData = viewModel.personalData
            )
        })
    }

    suspend fun removeDiscount(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val orderId = viewModel.state.order!!.id

        val edit = Order.Edit(discount = modifiedOf(Order.Discount.None))
        return store.orderAPI.editOrder(orderId, edit).flatMap { refresh(store, orderId) }.map { (order, orderItem) ->
            val cost = (order.total as? Order.Total.Price)?.amount?.amount
            val newState = viewModel.state.copy(
                order = order,
                orderItem = orderItem,
                totalCost = cost?.toString() ?: "0",
            )
            createItems(
                store.articles, orderItem, texts = viewModel.texts, state = newState, viewModel = viewModel, personalData = viewModel.personalData
            )
        }.fold(onSuccess = { it }, onNotSuccess = { scene.failed(result = it) })
    }

    private suspend fun refresh(store: Store, id: Identifier<Order>) = store.orderAPI.run {
        getOrder(id).zip(listOrderItems(id))
    }


    private fun dataLayerAddToCart(orderItem: OrderItem) {
        Ecommerce.ga3AddToCart(orderItem)
        Ecommerce.ga4AddToCart(orderItem)
    }


    private fun dataLayerCheckout(orderItems: List<OrderItem>) {
        Ecommerce.ga3Checkout(orderItems)
        Ecommerce.ga4Checkout(orderItems)
    }

    private fun dataLayerRemoveFrom(orderItem: OrderItem) {
        Ecommerce.ga3RemoveFromCart(orderItem)
        Ecommerce.ga4RemoveFromCart(orderItem)

    }
}