package screens

import io.ktor.client.*
import support.Deployment
import support.Scene
import support.Store
import support.sceneOf
import techla.base.*
import techla.conversation.ConversationAPI
import techla.conversation.Message
import kotlin.time.ExperimentalTime

val Message.text
    get() =
        attachments
            .mapNotNull { if (it is Message.Attachment.InlineText) it else null }
            .firstOrNull()?.text ?: ""

val Message.image
    get() =
        attachments
            .mapNotNull { if (it is Message.Attachment.PublicImage) it else null }
            .firstOrNull()?.url?.toString() ?: ""

@ExperimentalTime
object InspirationScreen {
    data class MessageFeed(
        val messages: List<Message>?
    )

    data class Texts(
        val title: String,
        val description: String,
        val link: String,
        val linkText: String,
        val buttonGoBack: String,
        val errorMessage: String
    )

    data class InstaPost(
        val text: String,
        val image: String,
    )

    sealed class ViewModel(val messageFeed: MessageFeed) {
        object None : ViewModel(
            MessageFeed(null),
        )

        class Started(
            messageFeed: MessageFeed,
        ) : ViewModel(messageFeed)

        class Inspiration(
            messageFeed: MessageFeed,
            val instaPosts: List<InstaPost>,
            val texts: Texts
        ) : ViewModel(messageFeed)

        class Failed(
            messageFeed: MessageFeed,
            val errorMessage: String
        ) : ViewModel(messageFeed)

        fun started() =
            Started(messageFeed = messageFeed)

        fun inspiration(
            messageFeed: List<Message>,
            instaPosts: List<InstaPost>,
            texts: Texts
        ) =
            Inspiration(MessageFeed(messageFeed), instaPosts = instaPosts, texts = texts)

        fun failed(errorMessage: String) =
            Failed(messageFeed = messageFeed, errorMessage = errorMessage)

        fun failed(result: Either<List<Warning>, Throwable>) =
            failed(
                errorMessage = when (result) {
                    is Either.Left -> "(${result.value.joinToString(", ") { it.message }})"
                    is Either.Right -> "(${result.value.message})"
                }
            )


    }


    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf<ViewModel>(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return sceneOf(viewModel.started())
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene


        val conversationApi = ConversationAPI(HttpClient())

        conversationApi.host = Deployment.host
        conversationApi.token = store.token


        return conversationApi.resolve(listOf(Key("inspiration")))
            .map {
                val messages = it
                val action = Store.Action.MessageLoaded(messages = messages)

                val instaPosts = messages.map { message ->
                    InstaPost(
                        text = message.text,
                        image = message.image
                    )

                }
                val texts = Texts(
                    title = store.get(media = Key("screen:inspiration"), content = Key("title")),
                    description = store.get(media = Key("screen:inspiration"), content = Key("description")),
                    link = store.get(media = Key("screen:inspiration"), content = Key("link")),
                    linkText = store.get(media = Key("screen:inspiration"), content = Key("linkText")),
                    buttonGoBack = store.get(media = Key("screen:inspiration"), content = Key("buttonGoBack")),
                    errorMessage = store.get(media = Key("screen:inspiration"), content = Key("errorMessage")),
                    
                    )

                sceneOf(viewModel.inspiration(messageFeed = messages, instaPosts = instaPosts, texts = texts), action)
            }
            .fold(
                onSuccess = { it },
                onNotSuccess = { scene.failed(result = it) }
            )


    }
}