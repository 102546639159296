package components


import OrderContext
import StoreContext
import bootstrap.bButton
import bootstrap.bContainer
import bootstrap.bSpinner
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.h4
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.router.dom.Link
import screens.*
import support.className
import support.sceneOf
import techla.base.techla_log
import techla.order.Article
import techla.order.Order
import kotlin.time.ExperimentalTime

external interface OrderComponentProps : PropsWithChildren {
    var show: Boolean
    var title: String
    var className: String
    var isEditable: Boolean
}

@ExperimentalTime
val ORDER_COMPONENT = FC<OrderComponentProps>("ORDER_COMPONENT") { props ->
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useContext(OrderContext)

    fun closeCart() {
        MainScope().launch {
            val (new, actions) = OrderScreen.openCart(sceneOf(store, viewModel), isOpen = false)
            dispatch(actions)
            setViewModel(new)
        }
    }

    fun duplicate(item: OrderScreen.Item) {
        MainScope().launch {
            val (new, actions) = OrderScreen.duplicateItem(sceneOf(store, viewModel), item = item)
            dispatch(actions)
            setViewModel(new)
        }
    }

    fun removeItem(item: OrderScreen.Item) {
        MainScope().launch {
            val (new, actions) = OrderScreen.removeItem(sceneOf(store, viewModel), id = item.id)
            dispatch(actions)
            setViewModel(new)
        }
    }

    fun removeDiscount() {
        MainScope().launch {
            val (new, actions) = OrderScreen.removeDiscount(sceneOf(store, viewModel))
            dispatch(actions)
            setViewModel(new)
        }
    }

    if (viewModel is OrderScreen.ViewModel.Started) {
        div {
            bSpinner {
            }
        }
    }

    if (viewModel is OrderScreen.ViewModel.Cart) {
        if (props.show) {
            div {
                className = className("n-bg-overlay")
                onClick = { closeCart() }
            }
        }
        bContainer {
            fluid = true
            className = className(props.className)
            if (props.show) {
                img {
                    src = "svg/ico_close.svg"
                    alt = "close"
                    className = className("n-pointer")
                    height = 18.00
                    width = 18.00
                    onClick = {
                        closeCart()
                    }
                }
            }
            div {
                h3 {
                    className = className("text-center py-3")
                    +props.title
                }

                viewModel.items.filter { it.article.kind == Article.Kind.None }
                    .map { item ->

                        orderItemsComponent(
                            item = item,
                            texts = viewModel.texts,
                            delete = ::removeItem,
                            duplicate = ::duplicate,
                            className = "d-flex pb-3 py-1",
                            isEditable = props.isEditable
                        )
                    }
                viewModel.items.filter { it.article.isCategoryMontage }
                    .map { item ->
                        orderItemsComponent(
                            item = item,
                            texts = viewModel.texts,
                            delete = ::removeItem,
                            duplicate = ::duplicate,
                            className = "d-flex pb-3 py-1",
                            isEditable = props.isEditable
                        )
                    }

                viewModel.items.filter { it.article.isInstallation }
                    .map { item ->

                        orderItemsComponent(
                            item = item,
                            texts = viewModel.texts,
                            delete = ::removeItem,
                            duplicate = ::duplicate,
                            className = "d-flex pb-3 py-1 n-border",
                            isEditable = props.isEditable
                        )
                    }
                viewModel.items.filter { it.article.isAdditionalInstallation }
                    .map { item ->

                        orderItemsComponent(
                            item = item,
                            texts = viewModel.texts,
                            delete = ::removeItem,
                            duplicate = ::duplicate,
                            className = "d-flex pb-3 py-1",
                            isEditable = props.isEditable
                        )
                    }

                viewModel.items.filter { it.article.isStartup }
                    .map { item ->

                        orderItemsComponent(
                            item = item,
                            texts = viewModel.texts,
                            delete = ::removeItem,
                            duplicate = ::duplicate,
                            className = "d-flex pb-3 py-1",
                            isEditable = props.isEditable,
                        )
                    }
            }
            div {
                className = className("n-summary-action")
                div {
                    className = className("d-flex justify-content-center align-items-end my-3")
                    h1 {
                        className = className("my-0")
                        +"${viewModel.state.order.totalCost}${viewModel.texts.cost}"
                    }
                    p {
                        className = className("my-0 pb-1 pl-2")
                        +viewModel.texts.vat
                    }
                }
                // This really should not be calculated here, but OrderScreen is very confusing...
                (viewModel.state.order?.discount as? Order.Discount.Percentage)?.let { discount ->
                    div {
                        className = className("pt-2 pb-3 pr-2 w-100")
                        div {
                            className = className("d-flex flex-column")
                            div {
                                className = className("d-flex justify-content-between")
                                h4 {
                                    className = className("pt-1")
                                    +discount.name
                                }
                                if (props.isEditable) {
                                    img {
                                        src = "svg/ico_remove.svg"
                                        alt = "remove article"
                                        className = className("n-pointer")
                                        height = 31.00
                                        width = 25.00
                                        onClick = { removeDiscount() }
                                    }
                                }
                            }
                        }
                        div {
                            className = className("d-flex justify-content-between")
                            p {
                                className = className("m-0 pb-1")
                                +viewModel.texts.originalCost
                            }
                            p {
                                className = className("m-0 pb-1")
                                +"${viewModel.state.order.originalCost}:-"
                            }
                        }
                        div {
                            className = className("d-flex justify-content-between")
                            p {
                                className = className("m-0 pb-1")
                                +viewModel.texts.discountCost
                            }
                            p {
                                className = className("m-0 pb-1")
                                +"${viewModel.state.order.discountCost}:-"
                            }
                        }
                    }
                }
                if (props.show) {
                    Link {
                        to = "/checkout"
                        bButton {

                            onClick = { techla_log("Go to checkout") }
                            className = className("btn-block")
                            disabled = viewModel.mirrorsInOrder <= 0

                            +viewModel.texts.buttonTitle
                        }

                    }
                }
            }
        }
    }
}


@ExperimentalTime
fun ChildrenBuilder.orderComponent(show: Boolean, title: String, className: String, isEditable: Boolean = true) =
    ORDER_COMPONENT() {
        this.show = show
        this.title = title
        this.className = className
        this.isEditable = isEditable
    }

