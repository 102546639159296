package views

import bCol
import bootstrap.bContainer
import bootstrap.bRow
import react.FC
import react.PropsWithChildren
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.main
import react.router.dom.Link
import support.className

val NotFoundView = FC<PropsWithChildren>("NOTFOUND") {
    main {
        bContainer {
            className = className("py-5 n-bottom-space")
            bRow {
                className = className("pb-4 justify-content-center")
                bCol {
                    className = className("p-4 text-center")
                    xs = 12; md = 8
                    ReactHTML.img {
                        src = "svg/ico_page_not_found.svg"
                        className = className("py-4")
                    }
                    ReactHTML.h1 {
                        +"Kunde inte hitta sidan"
                    }
                    Link {
                        to = "/"
                        className = className("d-block greenlink pt-4")
                        +"Till Speglar.nu"
                    }
                }
            }

        }
    }
}