package components

import DesignContext
import react.*
import react.dom.html.ReactHTML.div
import support.className
import techla.base.Key
import techla.order.Article
import csstype.*
import emotion.react.css
import react.dom.html.ReactHTML.img
import kotlin.time.ExperimentalTime

external interface DesignShapesComponentProps : PropsWithChildren {
    var height: String
    var width: String
}

@ExperimentalTime
val DESIGN_SHAPE_COMPONENT = FC<DesignShapesComponentProps>("DESIGN_SHAPE_COMPONENT") { props ->
    val (viewModel, _) = useContext(DesignContext)

    val testHeight = if (props.height == "") 50.0 else props.height.toDouble()
    val testWidth = if (props.width == "") 50.0 else props.width.toDouble()
    val selectedArticle = viewModel.state.selectedShape?.article

    fun convertToPixels(cm: Double): Double {
        val onePixel = 37.7952755906
        val cmToPixel = cm.times(onePixel)

        return when {
            cmToPixel > onePixel.times(80) -> cmToPixel.div(180)
            cmToPixel > onePixel.times(70) -> cmToPixel.div(160)
            cmToPixel > onePixel.times(60) -> cmToPixel.div(150)
            cmToPixel > onePixel.times(50) -> cmToPixel.div(140)
            cmToPixel > onePixel.times(40) -> cmToPixel.div(130)
            cmToPixel > onePixel.times(30) -> cmToPixel.div(120)
            cmToPixel > onePixel.times(25) -> cmToPixel.div(110)
            else -> cmToPixel.div(9) //cmToPixel.div(5)
        }
    }

    when (selectedArticle?.key) {
        Key<Article>("RECTANGLE") -> {
            div {
                className = className("d-flex align-items-center svgShadowContainer ")
                img {
                    src = "svg/ico_rect.svg"
                    alt = selectedArticle.key.rawValue
                    css() {
                        height = convertToPixels(testHeight).px
                        width = convertToPixels(testWidth).px
                        objectFit = ObjectFit.cover
                    }
                }
            }
        }


        Key<Article>("SQUARE") -> {
            div {
                className = className("d-flex align-items-center svgShadowContainer ")
                img {
                    src = "svg/ico_square.svg"
                    alt = selectedArticle.key.rawValue
                    css {
                        height = convertToPixels(testWidth).px
                        width = convertToPixels(testWidth).px
                        objectFit = ObjectFit.cover
                    }
                }
            }
        }

        Key<Article>("CIRCLE") -> {
            div {
                className = className("d-flex align-items-center svgShadowContainer ")
                img {
                    src = "svg/ico_circle.svg"
                    alt = selectedArticle.key.rawValue

                    css {
                        height = convertToPixels(testWidth).px
                        width = convertToPixels(testWidth).px
                        borderRadius = (100.pct)
                    }
                }
            }
        }

        Key<Article>("ARCH") -> {
            div {
                className = className("d-flex align-items-center svgShadowContainer ")
                img {
                    src = "svg/ico_rect.svg"
                    alt = selectedArticle.key.rawValue
                    val countWidth = convertToPixels(testWidth)
                    height = convertToPixels(testHeight)
                    width = countWidth
                    css {

                        objectFit = ObjectFit.cover
                        borderTopLeftRadius = ((countWidth / 2.0).px)
                        borderTopRightRadius = ((countWidth / 2.0).px)
                    }
                }
            }
        }
    }
}


@ExperimentalTime
fun ChildrenBuilder.designShapeComponent(height: String, width: String) =
    DESIGN_SHAPE_COMPONENT() {
        this.height = height
        this.width = width
    }