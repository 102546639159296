package components


import OrderContext
import PaymentContext
import StoreContext
import bCol
import bootstrap.*
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.events.Event
import react.*
import react.dom.events.SyntheticEvent
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.h4
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.select
import react.router.useNavigate
import screens.*
import support.className
import support.sceneOf
import techla.base.Address
import techla.base.Key
import techla.base.techla_log
import techla.order.Article
import techla.order.Order
import kotlin.time.ExperimentalTime


@ExperimentalTime
val CheckoutComponent = FC<PropsWithChildren>("CHECKOUT_COMPONENT") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useContext(OrderContext)
    val (paymentViewModel, setPaymentViewModel) = useContext(PaymentContext)
    val (selectedCity, setSelectedCity) = useState(viewModel.state.selectedCity)
    val (showModal, setShowModal) = useState(false)
    val handleClose = { _: Event -> setShowModal(false) }
    val selectAttachment = { _: Event -> }
    val (image, setImage) = useState("")
    val (imageText, setImageText) = useState("")
    val (text, setText) = useState("")
    val (coupon, setCoupon) = useState("")
    val (firstName, setFirstName) = useState(viewModel.personalData.firstName)
    val (lastName, setLastName) = useState(viewModel.personalData.lastName)
    val (email, setEmail) = useState(viewModel.personalData.email)
    val (phoneNumber, setPhoneNumber) = useState(viewModel.personalData.phoneNumber)
    val (street, setStreet) = useState((viewModel.personalData.address as? Address.Swedish)?.street ?: "")
    val (postalCode, setPostalCode) = useState(
        if ((viewModel.personalData.address as? Address.Swedish)?.postalCode == 0) {
            ""
        } else (viewModel.personalData.address as? Address.Swedish)?.postalCode.toString()
    )
    val (city, setCity) = useState((viewModel.personalData.address as? Address.Swedish)?.city ?: "")
    val (isValid, setIsValid) = useState(true)
    val (showToaster, setShowToaster) = useState(false)
    val handleCloseToaster = { _: Event -> setShowToaster(false) }
    val (errorMessage, setErrorMessage) = useState(0)
    val (linkText, setLinkText) = useState("")
    val (instructions, setInstructions) = useState(viewModel.texts.instructions)
    val navigate = useNavigate()

    fun inputChange(event: Event) {
        val target = event.target as HTMLInputElement
        val name = target.name
        val value = target.value

        when (name) {
            "coupon" -> setCoupon(value.trimStart())
            "firstName" -> setFirstName(value.trimStart())
            "lastName" -> setLastName(value.trimStart())
            "email" -> setEmail(value.trim())
            "phoneNumber" -> setPhoneNumber(value.trim())
            "street" -> setStreet(value.trimStart())
            "postalCode" -> setPostalCode(value.trimStart())
            "city" -> setCity(value.trimStart())
        }
    }


    fun addToCart(article: Article) {
        MainScope().launch {
            val (new, actions) = OrderScreen.addOrRemoveItem(
                sceneOf(store, viewModel),
                article = article
            )
            dispatch(actions)
            setViewModel(new)
        }
    }

    fun goToPayment() {
        MainScope().launch {
            val (new, actions) = PaymentScreen.start(
                sceneOf(store, paymentViewModel)
            )
            dispatch(actions)
            setPaymentViewModel(new)
            navigate("/payment")
        }
    }

    fun addCoupon() {
        MainScope().launch {
            OrderScreen.addCoupon(sceneOf(store, viewModel), coupon).also { (new, actions) ->
                dispatch(actions)
                setViewModel(new)
            }
        }
    }

    fun saveContactInformation() {
        MainScope().launch {
            val (new, actions) = OrderScreen.saveContactInformation(
                sceneOf(store, viewModel), firstName, lastName, email, phoneNumber, street,
                postCode = if (postalCode == "") {
                    0
                } else removeWhiteSpaces(postalCode),
                city

            )
            dispatch(actions)
            setViewModel(new)
            goToPayment()

        }
    }

    fun validateInformation() {
        if (!viewModel.state.montageInOrder) {
            if (firstName.trim().length >= 2 &&
                lastName.trim().length >= 2 &&
                phoneNumber.trim().length in 9..12 &&
                email.contains("@") &&
                email.contains(".")
            ) {
                if (selectedCity == viewModel.texts.selectCity) {
                    setIsValid(false)
                    setShowToaster(true)
                    setErrorMessage(3)
                } else {
                    setIsValid(true)
                    saveContactInformation()
                }

            } else {
                if (selectedCity == viewModel.texts.selectCity) {
                    setErrorMessage(1)
                    setIsValid(false)
                    setShowToaster(true)
                } else {
                    setErrorMessage(2)
                    setIsValid(false)
                    setShowToaster(true)
                }

            }

        } else {
            if (viewModel.state.montageInOrder &&
                street.trim().length >= 2 &&
                postalCode.trimStart().length >= 4 &&
                city.trim().length >= 2 &&
                firstName.trim().length >= 2 &&
                lastName.trim().length >= 2 &&
                phoneNumber.trimStart().length in 9..13 &&
                email.contains("@") &&
                email.contains(".")
            ) {
                if (selectedCity == viewModel.texts.selectCity) {
                    setIsValid(false)
                    setShowToaster(true)
                    setErrorMessage(3)
                } else {
                    setIsValid(true)
                    saveContactInformation()
                }
            } else {
                if (selectedCity == viewModel.texts.selectCity) {
                    setErrorMessage(1)
                    setIsValid(false)
                    setShowToaster(true)
                } else {
                    setErrorMessage(2)
                    setIsValid(false)
                    setShowToaster(true)
                }
            }
        }
    }


    fun openQuestion(question: String, linkText: String) {
        setText(question)
        setImage("")
        setImageText("")
        setLinkText(linkText)
        setShowModal(true)
    }

    fun openImg(attachment: OrderScreen.Attachment) {
        setImage(attachment.imgLink!!)
        setImageText(attachment.name)
        setText("")
        setShowModal(true)
    }

    if (viewModel is OrderScreen.ViewModel.Started) {
        div {
            bSpinner {

            }
        }
    }
    fun selectCity(value: String) {
        setSelectedCity(value)

        MainScope().launch {
            val (new, actions) = OrderScreen.addCityToOrder(
                sceneOf(store, viewModel),
                workShopId = value
                //workShopNumber = value
            )
            dispatch(actions)
            setViewModel(new)
        }

    }

    if (viewModel is OrderScreen.ViewModel.Cart) {
        bContainer {
            className = className("py-5 px-0 n-bottom-space")
            bRow {
                className = className("pb-4")
                bCol {
                    xs = 12; md = 8

                    h2 {
                        className = className("pb-2")
                        +viewModel.texts.titleMontage
                    }
                    div {
                        className = className("p-4 border rounded")
                        h3 {
                            className = className("d-inline")
                            +viewModel.texts.subtitleMontage1
                        }
                        img {
                            src = "svg/ico_questionmark.svg"
                            alt = "question mark"
                            className = className("n-pointer pl-2 mt-n2")
                            onClick = { openQuestion(viewModel.texts.questionMark1, "") }
                        }

                        div {
                            className = className("pb-2 pt-3")
                            select {

                                defaultValue = selectedCity
                                onChange = { s -> selectCity(s.target.value) }
                                option {
                                    value = viewModel.texts.selectCity
                                    +viewModel.texts.selectCity
                                }
                                viewModel.cities.forEach {
                                    option {
                                        key = it.city
                                        value = it.id.toString()
                                        //attrs.value = it.workshopNumber
                                        +it.city
                                    }
                                }
                            }
                            if (!isValid && selectedCity == viewModel.texts.selectCity) {
                                bFormControlFeedback {
                                    type = "invalid"
                                    +viewModel.texts.validationSelectCity
                                }
                            }
                        }

                        p {
                            a {
                                href = "${viewModel.texts.linkRydsGlas}${replaceChar(support.RydsCities.cityList.firstOrNull { it.id.toString() == selectedCity }?.city?.trim() ?: "")}"
                                className = className("greenlink")
                                target = AnchorTarget._blank

                                +viewModel.texts.buttonClickHere
                            }
                            +" ${viewModel.texts.toSeeAddress}"
                        }

                        h3 {
                            className = className("d-inline-block pt-3")
                            +viewModel.texts.subtitleMontage2
                        }
                        img {
                            src = "svg/ico_questionmark.svg"
                            alt = "question mark"
                            className = className("n-pointer pl-2 mt-n2")
                            onClick = { openQuestion(viewModel.texts.questionMark2, "") }
                        }

                        div {
                            viewModel.montage
                                .map { item ->
                                    div {
                                        className = className("pt-3")

                                        bFormControl {
                                            className = className("d-inline n-checkbox")
                                            type = "checkbox"
                                            checked = item.selected!!
                                            onChange = { addToCart(item.article) }
                                        }
                                        img {
                                            src = item.imgLink
                                            className = className("img-fluid img-thumbnail ml-3 d-inline")
                                            alt = item.name

                                        }
                                        h4 {
                                            className = className("pl-3 d-inline mb-1 mt-1")
                                            onClick = { addToCart(item.article) }
                                            +item.name
                                        }
                                        h3 {
                                            className = className("d-inline float-right mb-1 mt-2")
                                            +"${item.cost}${viewModel.texts.cost}"
                                        }
                                    }
                                }
                        }

                        if (!viewModel.state.montageInOrder) {
                            h3 {
                                className = className("d-inline-block pt-4")
                                +viewModel.texts.subtitleMontage4
                            }
                            img {
                                src = "svg/ico_questionmark.svg"
                                alt = "question mark"
                                className = className("n-pointer pl-2 mt-n2")
                                onClick = { openQuestion(viewModel.texts.questionMark4, "") }
                            }
                            div {
                                viewModel.attachments.filter {
                                    it.article.key == Key<Article>(
                                        "SAFETYFILM"
                                    )
                                }
                                    .map { item ->
                                        div {
                                            className = className("pt-3")

                                            bFormControl {
                                                className = className("d-inline n-checkbox")
                                                type = "checkbox"
                                                checked = item.selected!!
                                                onChange = { addToCart(item.article) }
                                            }
                                            img {
                                                src = item.imgLink
                                                className = className("img-fluid img-thumbnail ml-3 d-inline")
                                                alt = item.name

                                            }
                                            h4 {
                                                className = className("pl-3 d-inline mb-1 mt-1")
                                                onClick = { addToCart(item.article) }
                                                +item.name
                                            }
                                            h3 {
                                                className = className("d-inline float-right mb-1 mt-2")
                                                +"${item.cost}${viewModel.texts.cost}"
                                            }
                                        }
                                    }
                            }
                            h3 {
                                className = className("d-inline-block pt-4")
                                +viewModel.texts.subtitleMontage3
                            }
                            img {
                                className = className("n-pointer pl-2 mt-n2")
                                src = "svg/ico_questionmark.svg"
                                alt = "question mark"
                                onClick =
                                    { openQuestion(viewModel.texts.questionMark3, viewModel.texts.linkText) }
                            }
                            bTabs {
                                defaultActiveKey = OrderScreen.OptionAttachment.Glue.toString()
                                onSelect = selectAttachment
                                className = className("pt-3")
                                bTab {
                                    title = viewModel.texts.glue
                                    eventKey = OrderScreen.OptionAttachment.Glue.toString()
                                    viewModel.attachments
                                        .filter {

                                            it.article.category?.name == OrderScreen.OptionAttachment.Glue.toString()
                                        }
                                        .map { attachment ->
                                            div {
                                                className = className("pt-4")
                                                bFormControl {
                                                    className = className("d-inline n-checkbox")
                                                    type = "checkbox"
                                                    checked = attachment.selected!!
                                                    onChange = { addToCart(attachment.article) }
                                                }
                                                img {
                                                    src = attachment.imgLink
                                                    alt = attachment.name
                                                    className = className("img-fluid img-thumbnail ml-3 n-pointer")
                                                    onClick = { openImg(attachment) }

                                                }
                                                h4 {
                                                    className = className("pl-3 d-inline mb-1 mt-1")
                                                    onClick = { addToCart(attachment.article) }
                                                    +attachment.name
                                                }
                                                h3 {
                                                    className = className("d-inline float-right mb-1 mt-2")
                                                    +"${attachment.cost}${viewModel.texts.cost}"
                                                }
                                            }
                                        }
                                }

                                bTab {
                                    title = viewModel.texts.screw
                                    eventKey = OrderScreen.OptionAttachment.Screw.toString()
                                    viewModel.attachments
                                        .filter {
                                            it.article.category?.name == OrderScreen.OptionAttachment.Screw.toString()
                                        }
                                        .map { attachment ->
                                            div {
                                                className = className("pt-4")
                                                bFormControl {
                                                    className = className("d-inline n-checkbox")
                                                    type = "checkbox"
                                                    checked = attachment.selected!!
                                                    onChange = { addToCart(attachment.article) }
                                                }
                                                img {
                                                    src = attachment.imgLink
                                                    alt = attachment.name
                                                    className = className("img-fluid img-thumbnail ml-3 n-pointer")
                                                    onClick = { openImg(attachment) }

                                                }
                                                h4 {
                                                    className = className("pl-3 d-inline mb-1 mt-1")
                                                    onClick = { addToCart(attachment.article) }
                                                    +attachment.name
                                                }
                                                h3 {
                                                    className = className("d-inline float-right mb-1 mt-2")
                                                    +"${attachment.cost}${viewModel.texts.cost}"
                                                }
                                            }
                                        }
                                }
                            }
                        }
                    }
                    h2 {
                        className = className("pb-2 pt-4")
                        +"RABATTKOD"
                    }
                    couponComponent(
                        coupon = coupon,
                        inputChange = ::inputChange,
                        addCoupon = ::addCoupon,
                        texts = viewModel.texts,
                        discount = viewModel.state.order?.discount ?: Order.Discount.None,
                        showError = viewModel.state.couponFailed,
                    )
                    h2 {
                        className = className("pb-2 pt-4")
                        +viewModel.texts.titleCustomer
                    }
                    contactComponent(
                        firstName = firstName,
                        lastName = lastName,
                        email = email,
                        phoneNumber = phoneNumber,
                        street = street,
                        postalCode = postalCode,
                        city = city,
                        inputChange = ::inputChange,
                        montageIsSelected = viewModel.state.montageInOrder,
                        texts = viewModel.texts,
                        isValid = isValid,
                        validationContactInformation = viewModel.texts.validationContactInformation
                    )

                    bButton {
                        onClick = { validateInformation() }
                        disabled = (viewModel.mirrorsInOrder == 0)
                        className = className("btn-lg btn-block mt-4")

                        +viewModel.texts.buttonGoToPayment
                    }
                }



                bCol {
                    className = className("p-4")
                    xs = 12; md = 4
                    orderComponent(
                        show = false,
                        title = viewModel.texts.overview,
                        className = "n-cart-checkout p-4"
                    )
                }
                footerComponent()
            }
        }
    }


    bModal {
        show = showModal
        onHide = { setShowModal(false) }
        centered = true
        size = "md"
        scrollable = true

        bModalHeader {
            closeButton = true
            closeLabel = "Close"
            +""
        }
        bModalBody {
            if (image != "") {
                img {
                    src = image
                    alt = imageText
                    className = className("w-100")
                }
            }
            p {
                +text
                if (linkText != "") {
                    a {
                        href = instructions
                        target = AnchorTarget._blank
                        +linkText
                    }
                }
            }
        }
    }



    bModal {
        show = (!isValid && showToaster)
        onHide = { setShowToaster(false) }
        centered = true
        size = "md"
        scrollable = true

        bModalHeader {
            closeButton = true
            closeLabel = "Close"
            +""
        }
        bModalBody {

            p {
                when (errorMessage) {
                    1 -> +viewModel.texts.validationCase1
                    2 -> +viewModel.texts.validationCase2
                    3 -> +viewModel.texts.validationCase3
                }
            }
        }
    }
}


@ExperimentalTime
fun ChildrenBuilder.checkoutComponent() =
    CheckoutComponent() {
    }
