package screens

import support.Scene
import support.sceneOf
import techla.base.Either
import techla.base.Key
import techla.base.Warning
import kotlin.time.ExperimentalTime


@ExperimentalTime
object FaqScreen {
    data class Texts(
        val title: String,
        val question1: String,
        val answer1: String,
        val question2: String,
        val answer2: String,
        val question3: String,
        val answer3: String,
        val question4: String,
        val answer4: String,
        val question5: String,
        val answer5: String,
        val question6: String,
        val answer6: String,
        val answer6Link: String,
        val question7: String,
        val answer7: String,
        val question8: String,
        val answer8: String,
        val question9: String,
        val answer9: String,
        val question10: String,
        val answer10: String,
        val buttonGoBack: String,
        val errorMessage: String,
        val answer5Email: String,
        val answer5Cal: String,
        val email: String,
        val phoneNumber: String,
        val star: String,
    )

    data class Faq(
        val question: String,
        val answer: String,
    )

    sealed class ViewModel() {
        object None : ViewModel()

        class Started() : ViewModel()

        class FaqReady(
            val texts: Texts,
            val faqs: List<Faq>
        ) : ViewModel()


        class Failed(
            val message: String
        ) : ViewModel()

        fun started() =
            Started()

        fun faqReady(
            texts: Texts,
            faqs: List<Faq>
        ) =
            FaqReady(texts = texts, faqs = faqs)

        fun failed(message: String) =
            Failed(message = message)

        fun failed(result: Either<List<Warning>, Throwable>) =
            failed(
                message = when (result) {
                    is Either.Left -> "(${result.value.joinToString(", ") { it.message }})"
                    is Either.Right -> "(${result.value.message})"
                }
            )
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf<ViewModel>(viewModel.failed(result))

    suspend fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return sceneOf(viewModel.started())
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val texts = Texts(
            title = store.get(media = Key("screen:faq"), content = Key("title")),
            question1 = store.get(media = Key("screen:faq"), content = Key("question.1")),
            answer1 = store.get(media = Key("screen:faq"), content = Key("answer.1")),
            question2 = store.get(media = Key("screen:faq"), content = Key("question.2")),
            answer2 = store.get(media = Key("screen:faq"), content = Key("answer.2")),
            question3 = store.get(media = Key("screen:faq"), content = Key("question.3")),
            answer3 = store.get(media = Key("screen:faq"), content = Key("answer.3")),
            question4 = store.get(media = Key("screen:faq"), content = Key("question.4")),
            answer4 = store.get(media = Key("screen:faq"), content = Key("answer.4")),
            question5 = store.get(media = Key("screen:faq"), content = Key("question.5")),
            answer5 = store.get(media = Key("screen:faq"), content = Key("answer.5")),
            question6 = store.get(media = Key("screen:faq"), content = Key("question.6")),
            answer6 = store.get(media = Key("screen:faq"), content = Key("answer.6")),
            answer6Link = store.get(media = Key("screen:faq"), content = Key("answer.6Link")),
            question7 = store.get(media = Key("screen:faq"), content = Key("question.7")),
            answer7 = store.get(media = Key("screen:faq"), content = Key("answer.7")),
            question8 = store.get(media = Key("screen:faq"), content = Key("question.8")),
            answer8 = store.get(media = Key("screen:faq"), content = Key("answer.8")),
            question9 = store.get(media = Key("screen:faq"), content = Key("question.9")),
            answer9 = store.get(media = Key("screen:faq"), content = Key("answer.9")),
            question10 = store.get(media = Key("screen:faq"), content = Key("question.10")),
            answer10 = store.get(media = Key("screen:faq"), content = Key("answer.10")),
            buttonGoBack = store.get(media = Key("screen:faq"), content = Key("buttonGoBack")),
            errorMessage = store.get(media = Key("screen:faq"), content = Key("errorMessage")),
            answer5Email = store.get(media = Key("screen:faq"), content = Key("answer.5Email")),
            answer5Cal = store.get(media = Key("screen:faq"), content = Key("answer.5Cal")),
            email = store.get(media = Key("screen:faq"), content = Key("email")),
            phoneNumber = store.get(media = Key("screen:faq"), content = Key("phoneNumber")),
            star = store.get(media = Key("screen:faq"), content = Key("star")),
        )

        return createFaqs(texts, viewModel)
        //return sceneOf(viewModel.faqReady(texts = texts))
    }


    private fun createFaqs(
        texts: Texts,
        viewModel: ViewModel,
    ): Scene.Output<ViewModel.FaqReady> {

        val faqs = listOf(
            Faq(
                question = texts.question1,
                answer = texts.answer1,
            ),
            Faq(
                question = texts.question2,
                answer = texts.answer2,
            ), Faq(
                question = texts.question3,
                answer = texts.answer3,
            ),
            Faq(
                question = texts.question4,
                answer = texts.answer4,
            ),
            Faq(
                question = texts.question5,
                answer = texts.answer5,
            ),
            Faq(
                question = texts.question6,
                answer = texts.answer6,
            ),
            Faq(
                question = texts.question7,
                answer = texts.answer7,
            ),
            Faq(
                question = texts.question8,
                answer = texts.answer8,
            ),
            Faq(
                question = texts.question9,
                answer = texts.answer9,
            ),
            Faq(
                question = texts.question10,
                answer = texts.answer10,
            ),
        ).sortedBy { it.question }


        return sceneOf(
            viewModel.faqReady(texts = texts, faqs = faqs)
        )
    }
}
