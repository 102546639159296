package bootstrap


import org.w3c.dom.events.Event
import react.*

@JsModule("react-bootstrap/Accordion")
private external val accordionModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bAccordion: ComponentType<AccordionProps> = accordionModule.default

@Suppress("UnsafeCastFromDynamic")
val bAccordionCollapse: ComponentType<AccordionCollapseProps> = accordionModule.default.Collapse

@Suppress("UnsafeCastFromDynamic")
val bAccordionItem: ComponentType<AccordionProps> = accordionModule.default.Item

@Suppress("UnsafeCastFromDynamic")
val bAccordionHeader: ComponentType<AccordionHeaderProps> = accordionModule.default.Header

external interface AccordionProps : StandardProps {
    var activeKey: String
    var defaultActiveKey: String
    var bsPrefix: String
    var onSelect: ((event: Event) -> Unit)
    var eventKey: String
}

external interface AccordionCollapseProps : StandardProps {
    var eventKey: String
    var `as`: dynamic
}

external interface AccordionHeaderProps : StandardProps {
    var bsPrefix: String
    var onClick: ((event: Event) -> Unit)

}