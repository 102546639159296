package components

import InspirationContext
import StoreContext
import bCol
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import bootstrap.*
import org.w3c.dom.events.Event
import react.*
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import screens.InspirationScreen
import support.className
import support.sceneOf


import techla.base.techla_log
import kotlin.time.ExperimentalTime

@ExperimentalTime
val INSPIRATION_COMPONENT = FC<PropsWithChildren>("INSPIRATION_COMPONENT ") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useContext(InspirationContext)
    val (showModal, setShowModal) = useState(false)
    val handleClose = { _: Event -> setShowModal(false) }
    val (image, setImage) = useState("")
    val (text, setText) = useState("")


    useEffect(viewModel) {
        when (viewModel) {
            is InspirationScreen.ViewModel.None -> {
                techla_log("NONE")
                MainScope().launch {
                    val (new, actions) = InspirationScreen.start(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)
                }
            }
            is InspirationScreen.ViewModel.Started -> {
                techla_log("STARTED")
                MainScope().launch {
                    val (new, actions) = InspirationScreen.load(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)
                }
            }

            is InspirationScreen.ViewModel.Inspiration -> {
                techla_log("INSPIRATION")
            }

            is InspirationScreen.ViewModel.Failed -> {
                techla_log("FAILED")
                techla_log(viewModel.errorMessage)

            }
        }
    }

    fun openImg(insta: InspirationScreen.InstaPost) {
        setImage(insta.image)
        setText(insta.text)
        setShowModal(true)
    }

    if (viewModel is InspirationScreen.ViewModel.Started) {
        bContainer {
            className = className("position-relative py-5")
            div {
                className = className("n-box p-4")
                bRow {
                    bCol {
                        bSpinner {

                        }
                    }
                }
            }
        }
    }
    if (viewModel is InspirationScreen.ViewModel.Inspiration) {

        bContainer {
            className = className("py-5")
            div {
                className = className("n-box p-4 n-bottom-space")
                bRow {
                    className = className("pb-4 justify-content-center")
                    bCol {
                        xs = 12; md = 8
                        h1 {
                            className = className("text-center")
                            +viewModel.texts.title
                        }
                        p {
                            className = className("text-center")
                            +viewModel.texts.description
                        }
                        a {
                            className = className("d-block text-center largelink")
                            target = AnchorTarget._blank
                            href = viewModel.texts.link
                            img {
                                className = className("pr-2")
                                alt = "Instagram Spreglar.nu"
                                src = "svg/ico_instagram.svg"
                            }
                            +viewModel.texts.linkText
                        }
                    }
                }
                bRow {
                    className = className("row-cols-1 row-cols-md-3 mb-0 mb-md-1")
                    viewModel.instaPosts.map { insta ->
                        a {
                            onClick = { openImg(insta) }
                            img {
                                src = insta.image
                                className = className("w-100 p-2 n-insta-img-height")
                            }
                        }
                    }
                }
            }
        }
    }

    bModal {
        show = showModal
        onHide = handleClose
        centered = true
        size = "md"
        scrollable = true
        bModalHeader {
            closeButton = true
            closeLabel = "Close"
            +""
        }
        bModalBody {
            img {
                src = image
                alt = text
                className = className("w-100")
            }
            p {
                className = className("")
                +text
            }
        }
    }


}


@ExperimentalTime
fun ChildrenBuilder.inspirationComponent() = INSPIRATION_COMPONENT() {}
