package bootstrap

import react.*

@JsModule("react-bootstrap/Tab")
private external val tabModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bTab: ComponentType<TabProps> = tabModule.default

external interface TabProps : PropsWithChildren {
    var title: String
    var disabled: String
    var tabClassName: String
    var eventKey: String
}
