package bootstrap

import csstype.ClassName
import org.w3c.dom.events.Event
import react.*

@JsModule("react-bootstrap/NavbarToggle")
private external val navbarToggleModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bNavbarToggle: ComponentType<NavbarToggleProps> = navbarToggleModule.default

external interface NavbarToggleProps : PropsWithChildren {
    var `as`: Any
    var label: String /* 'Toggle navigation' */
    var onClick: (Event) -> Unit
    var bsPrefix: String
    var className: ClassName
}
