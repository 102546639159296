package components

import FrontContext
import OrderContext
import StoreContext
import bootstrap.*
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.*
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.router.dom.*
import react.router.useLocation
import screens.FrontScreen
import screens.OrderScreen
import screens.isAdditionalInstallation
import screens.isStartup
import support.Deployment
import support.className
import support.sceneOf
import techla.base.techla_log
import kotlin.time.ExperimentalTime


@ExperimentalTime
val HEADER_COMPONENT = FC<PropsWithChildren>("HEADER_COMPONENT") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useContext(FrontContext)
    val (orderViewModel, setOrderViewModel) = useContext(OrderContext)
    val location = useLocation()
    val path = location.pathname

    useEffect(viewModel) {
        when (viewModel) {
            is FrontScreen.ViewModel.None -> {
                techla_log("FRONT -> NONE")
                MainScope().launch {
                    val (new, actions) = FrontScreen.start(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)

                }
            }

            is FrontScreen.ViewModel.Started -> {
                techla_log("FRONT -> STARTED")
                MainScope().launch {
                    val (new, actions) = FrontScreen.load(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)
                }
            }

            is FrontScreen.ViewModel.Ready -> {
                techla_log("FRONT -> READY")
            }

            is FrontScreen.ViewModel.Failed -> {
                techla_log("FRONT -> FAILED")
                techla_log(viewModel.message)
            }
        }
    }

    useEffect(orderViewModel) {
        when (orderViewModel) {
            is OrderScreen.ViewModel.None -> {
                techla_log("ORDER -> NONE")
                MainScope().launch {
                    val (new, actions) = OrderScreen.start(sceneOf(store, orderViewModel))
                    dispatch(actions)
                    setOrderViewModel(new)
                }
            }

            is OrderScreen.ViewModel.Started -> {
                techla_log("ORDER -> STARTED")
                MainScope().launch {
                    val (new, actions) = OrderScreen.load(sceneOf(store, orderViewModel))
                    dispatch(actions)
                    setOrderViewModel(new)
                }
            }

            is OrderScreen.ViewModel.Cart -> {
                techla_log("ORDER -> READY")
            }

            is OrderScreen.ViewModel.Failed -> {
                techla_log("ORDER -> FAILED")
                techla_log(orderViewModel.message)
            }
        }
    }

    fun openCart() {
        MainScope().launch {
            val (new, actions) = OrderScreen.openCart(sceneOf(store, orderViewModel), isOpen = true)
            dispatch(actions)
            setOrderViewModel(new)
        }
    }

    fun closeCart() {
        MainScope().launch {
            val (new, actions) = OrderScreen.openCart(sceneOf(store, orderViewModel), isOpen = false)
            dispatch(actions)
            setOrderViewModel(new)
        }
    }


    bContainer {
        fluid = true
        className = className("n-header px-0")

        bContainer {
            fluid = true
            className = className("px-3 px-md-3 p-lg-0 px-lg-4")
            bNavbar {
                className = className("p-0 py-md-0")
                expand = "lg"; variant = "dark"
                div {
                    className = className("n-logo-cont m-0 p-0")
                    div {
                        className = className("n-logo-spegla d-flex flex-nowrap")
                        Link {
                            to = "/"
                            className = className("m-0 p-0 d-flex n-logo-spegla2")

                            img {
                                className = className("n-logo-spegla2")
                                alt = "Speglar.nu"
                                src = "svg/logo_speglar.svg"
                                onClick = { closeCart() }
                            }
                        }
                        ReactHTML.a {
                            href = "https://www.rydsglas.se/"
                            target = AnchorTarget._blank
                            className = className("m-0 p-0 d-flex n-logo-spegla2")

                            img {
                                className = className("n-logo-spegla2")
                                alt = "Ryds glas"
                                src = "svg/logo_samarbete_ryds_glas.svg"
                                onClick = { closeCart() }
                            }
                        }
                    }
                }

                div {
                    className = className("n-basket-pos ")
                    div {
                        className = className("d-inline")
                        a {
                            onClick = { openCart() }
                            img {
                                className = className("pl-2 pr-3 pr-lg-0")
                                alt = "cart"
                                src = "svg/ico_cart.svg"
                            }
                        }
                        div {
                            className = className("n-cart-sum text-center")
                            +"${
                                orderViewModel.state.orderItem?.filter {
                                    it.article?.isStartup == false && it.article?.isAdditionalInstallation == false
                                }?.size ?: 0
                            }"
                        }
                    }
                    bNavbarToggle {
                        className = className("custom-toggler")
                    }
                }
                bNavbarCollapse {
                    className = className("justify-content-center pt-3")
                    bNav {
                        className = className("text-center p-4 p-lg-0")
                        bNavItem {
                            className = className(if (path == "/") "menuActive" else "")
                            NavLink {
                                to = "/"
                                className = className("menu nav-link")
                                +viewModel.texts.headerStart
                            }
                        }
                        bNavItem {
                            className = className(if (path == "/helper") "menuActive" else "")

                            NavLink {
                                to = "/helper"
                                className = className("menu nav-link")

                                +viewModel.texts.headerHelper
                            }
                        }
                        bNavItem {
                            className = className(if (path == "/inspiration") "menuActive" else "")
                            NavLink {
                                to = "/inspiration"
                                className = className("menu nav-link")
                                +viewModel.texts.headerInspiration
                            }
                        }
                        bNavItem {
                            className = className(if (path == "/faq") "menuActive" else "")
                            NavLink {
                                to = "/faq"
                                className = className("menu nav-link")
                                +viewModel.texts.headerFaq
                            }
                        }
                    }
                    div {
                        className = className("n-menu-extra-cont d-inline-block d-flex justify-content-center pb-3 pb-lg-0")
                        div {
                            className = className("float-right")
                            a {
                                className = className("contact d-inline")
                                href = "mailto:${viewModel.texts.email}"
                                target = AnchorTarget._blank

                                +viewModel.texts.email
                            }
                            p {
                                className = className("text-white d-inline")
                                +"｜"
                            }
                            a {
                                className = className("contact")
                                href = "tel:${viewModel.texts.phone}"
                                +viewModel.texts.phone
                            }
                        }
                    }

                }
            }
        }

    }


    if (orderViewModel.state.cartOpen) {
        orderComponent(show = true, title = orderViewModel.texts.title, className = "n-cart n-sidebar-scroll p-4")
    }


}

@ExperimentalTime
fun ChildrenBuilder.headerComponent() =
    HEADER_COMPONENT() {
    }