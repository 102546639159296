package components

import DesignContext
import OrderContext
import StoreContext
import bCol
import bootstrap.*
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.js.jso
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.Window
import org.w3c.dom.events.Event
import react.*
import react.dom.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h4
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.router.dom.Link
import screens.*
import support.className
import support.sceneOf
import support.useDelayedAsyncEffect
import techla.base.Key
import techla.base.techla_log
import techla.order.Article
import kotlin.math.roundToInt
import kotlin.time.ExperimentalTime

external interface DataLayer {
    fun push(params: dynamic): Any
}

val Window.dataLayer: DataLayer
    get() = asDynamic().dataLayer as DataLayer

@ExperimentalTime
val DESIGN_COMPONENT = FC<PropsWithChildren>("DESIGN_COMPONENT") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useContext(DesignContext)
    val (orderViewModel, setOrderViewModel) = useContext(OrderContext)
    val (width, setWidth) = useState("")
    val (height, setHeight) = useState("")
    val (sliderMoved, setSliderMoved) = useState(0)


    fun isValid(value: String): String {
        return when (value.toDoubleOrNull()) {
            is Double -> value
            else -> value.dropLast(1)
        }
    }

    fun widthChange(event: Event) {
        val target = event.target as HTMLInputElement
        val value = target.value
        setWidth(isValid(value))
        setSliderMoved(sliderMoved + 1)
    }

    fun heightChange(event: Event) {

        val target = event.target as HTMLInputElement
        val value = target.value
        setHeight(isValid(value))
        setSliderMoved(sliderMoved + 1)
    }


    useDelayedAsyncEffect(timeMillis = 500, sliderMoved) {
        if (height != "" &&
            width != ""
        ) {
            val selectedArticle = viewModel.state.selectedShape?.article
            if (sliderMoved > 0 && selectedArticle != null) {
                val maxHeight = getMaxTag(selectedArticle.max, "HEIGHT")?.toDouble()
                val maxWidth = getMaxTag(selectedArticle.max, "WIDTH")?.toDouble()
                val minHeight = getMinTag(selectedArticle.min, "HEIGHT")?.toDouble()
                val minWith = getMinTag(selectedArticle.min, "WIDTH")?.toDouble()

                if (height.toDouble() >= minHeight!! && width.toDouble() >= minWith!!) {
                    MainScope().launch {
                        val (new, actions) = DesignScreen.changeDimensions(
                            sceneOf(store, viewModel),
                            //TODO Fix max and min value from article
                            width = if (width.toDouble() > maxWidth!!) maxWidth.toDouble() else width.toDouble(),
                            height = if (height.toDouble() > maxHeight!!) maxHeight.toDouble() else height.toDouble()
                        )
                        dispatch(actions)
                        setViewModel(new)
                        setSliderMoved(0)
                    }
                }
            }
        }
    }


    useEffect(viewModel) {
        when (viewModel) {
            is DesignScreen.ViewModel.None -> {
                techla_log("DESIGN -> NONE")
                MainScope().launch {
                    val (new, actions) = DesignScreen.start(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)

                }
            }
            is DesignScreen.ViewModel.Started -> {
                techla_log("DESIGN -> STARTED")
                MainScope().launch {
                    val (new, actions) = DesignScreen.load(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)
                }
            }

            is DesignScreen.ViewModel.Design -> {
                techla_log("DESIGN -> READY")
                setWidth((viewModel.state.width * 1000).roundToInt().toString())
                setHeight((viewModel.state.height * 1000).roundToInt().toString())
            }
            is DesignScreen.ViewModel.Failed -> {
                techla_log("DESIGN -> FAILED")
                techla_log(viewModel.message)
            }
        }
    }

    fun selectShape(shape: DesignScreen.Shape) {
        shape.article
        MainScope().launch {
            val (new, actions) = DesignScreen.selectShape(sceneOf(store, viewModel), shape = shape)
            dispatch(actions)
            setViewModel(new)
        }
    }

    fun addToCart() {
        MainScope().launch {
            val (new, actions) = OrderScreen.saveToCart(
                sceneOf(store, orderViewModel),
                orderItem = viewModel.state.orderItem!!
            )
            dispatch(actions)
            setOrderViewModel(new)

        }


    }

    fun selectClassName(article: Article?, container: String): String {

        return if (container == "left") {
            when (article?.key) {
                Key<Article>("ARCH") -> "n-design-controls-box-arch"
                else -> "n-design-controls-box"
            }
        } else {
            when (article?.key) {
                Key<Article>("ARCH") -> "n-design-box-arch"
                else -> "n-design-box"
            }
        }


    }




    if (viewModel is DesignScreen.ViewModel.Started) {
        div {
            bContainer {
                bSpinner {

                }
            }
        }
    }


    if (viewModel is DesignScreen.ViewModel.Design) {
        bContainer {
            bRow {
                bCol {
                    h1 {
                        className = className("text-center")
                        +viewModel.texts.title
                    }
                }
            }

            bRow {
                bCol {
                    className = className("p-0")
                    xs = 12; lg = 8
                    div {
                        className = className("${selectClassName(viewModel.state.selectedShape?.article, "")} p-4 imgContainer")
                        designShapeComponent(height = height, width = width)
                    }
                }
                bCol {
                    className = className("p-0")
                    xs = 12; lg = 4
                    div {
                        className = className("${selectClassName(viewModel.state.selectedShape?.article, "left")} px-4 pt-2 pt-md-5 n-bottom-space")

                        div {
                            className = className("d-flex align-items-center pb-3 py-1")
                            viewModel.shapes.map { shape ->
                                div {
                                    className = className("pt-2 pr-2 n-pointer")
                                    onClick = { selectShape(shape) }
                                    if (shape.selected) {
                                        img {
                                            alt = "selected ${shape.name}"
                                            src = shape.imgLink
                                            className = className("n-selected-shape")
                                        }
                                    } else {
                                        img {
                                            alt = shape.name
                                            src = shape.imgLink
                                        }
                                    }
                                }
                            }
                            div {
                                className = className("pt-2 pr-2")
                                Link {
                                    to = "/ownDesign"
                                    className = className("greenlink")
                                    +"egen"
                                }
                            }
                        }

                        div {
                            className = className("d-flex align-items-center")
                            div {
                                className = className("pt-2 pr-2")
                                h4 {
                                    className = className("n-measure-width")
                                    +viewModel.width.title
                                }
                            }
                            div {
                                className = className("flex-grow-1")
                                bFormControl {
                                    type = "range"
                                    value = width
                                    max = viewModel.width.maxValue.toString()
                                    min = viewModel.width.minValue.toString()
                                    step = viewModel.width.step.toString()
                                    onChange = ::widthChange
                                    className = className("n-slider")
                                }
                            }
                            div {
                                className = className("pl-2")
                                bFormControl {
                                    value = width
                                    max = viewModel.width.maxValue.toString()
                                    min = viewModel.width.minValue.toString()
                                    onChange = ::widthChange
                                    className = className("n-measure-input")
                                }

                            }
                            div {
                                className = className("pt-3 pl-1")
                                p {
                                    +viewModel.texts.unit
                                }
                            }
                        }
                        if (viewModel.state.selectedShape?.widthAlwaysEqualToHeight == false) {
                            div {
                                className = className("d-flex align-items-center")
                                div {
                                    className = className("pt-2 pr-2")
                                    h4 {
                                        className = className("n-measure-width")
                                        +viewModel.height.title
                                    }
                                }
                                div {
                                    className = className("flex-grow-1")
                                    bFormControl {
                                        type = "range"
                                        value = height
                                        max = viewModel.height.maxValue.toString()
                                        min = viewModel.height.minValue.toString()
                                        step = viewModel.height.step.toString()
                                        onChange = ::heightChange
                                        className = className("n-slider")
                                    }
                                }
                                div {
                                    className = className("pl-2")
                                    bFormControl {
                                        value = height
                                        max = viewModel.height.maxValue.toString()
                                        min = viewModel.height.minValue.toString()
                                        onChange = ::heightChange
                                        className = className("n-measure-input")
                                    }

                                }
                                div {
                                    className = className("pt-3 pl-1")
                                    p {
                                        +viewModel.texts.unit
                                    }
                                }
                            }
                        }
                        div {
                            className = className("d-flex justify-content-center")
                            div {
                                p {
                                    className = className("text-muted small text-center")
                                    +viewModel.texts.mirrorInfo
                                }
                                if (viewModel.state.selectedShape?.article?.key == Key<Article>("ARCH")) {
                                    div {
                                        className = className("d-flex flex-column justify-content-center align-items-start")

                                        div {
                                            className = className("d-flex flex-column")
                                            div {
                                                className = className("disclaimer small p-3")
                                                dangerouslySetInnerHTML = jso {
                                                    __html = remarkable.render(viewModel.disclaimer, null)
                                                }
                                            }
                                            div {
                                                className = className("triangle")

                                            }
                                        }
                                        img {
                                            src = "svg/icon_hint.svg"
                                            alt = ""

                                        }
                                    }
                                }
                            }

                        }

                        div {
                            className = className("n-summary-action")
                            div {
                                className = className("d-flex justify-content-center align-items-end mt-3 mb-1")
                                h1 {
                                    className = className("my-0")
                                    if (sliderMoved == 0) {
                                        +viewModel.designOrder.cost
                                    } else {
                                        +""
                                    }
                                    +":-"
                                }
                                p {
                                    className = className("my-0 pb-1 pl-2")
                                    +"${viewModel.texts.vat} "
                                }
                            }
                            div {
                                className = className("d-flex justify-content-center")
                                p {
                                    className = className("text-muted small")
                                    +" ${viewModel.texts.startCost}"
                                }
                            }
                            div {
                                bButton {
                                    className = className("btn-block")
                                    onClick = { addToCart() }
                                    disabled = sliderMoved != 0
                                    +viewModel.texts.buttonTitle
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@ExperimentalTime
fun ChildrenBuilder.designComponent() = DESIGN_COMPONENT() {}