package components

import HelperContext
import StoreContext
import bCol
import bootstrap.*
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.js.jso
import react.*
import react.dom.html.ReactHTML.div
import remarkable.Remarkable
import screens.HelperScreen
import support.className
import support.sceneOf
import techla.base.techla_log
import kotlin.time.ExperimentalTime

val remarkable = Remarkable(null, null)

@ExperimentalTime
val HELPER_COMPONENT = FC<PropsWithChildren>("HELPER_COMPONENT ") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useContext(HelperContext)

    useEffect(viewModel) {
        when (viewModel) {
            is HelperScreen.ViewModel.None -> {
                techla_log("None")
                MainScope().launch {
                    val (new, actions) = HelperScreen.start(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)
                }
            }
            is HelperScreen.ViewModel.Started -> {
                techla_log("HELPER STARTED")
                MainScope().launch {
                    val (new, actions) = HelperScreen.load(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)
                }
            }
            is HelperScreen.ViewModel.HelperReady -> {
                techla_log("HELPER READY")
            }
            is HelperScreen.ViewModel.Failed -> {
                techla_log("HELPER READY")
                techla_log(viewModel.message)
            }
        }
    }

    if (viewModel is HelperScreen.ViewModel.Started) {
        div {
            bContainer {
                bSpinner {
                }
            }
        }
    }

    if (viewModel is HelperScreen.ViewModel.HelperReady) {
        bContainer{
            className = className("position-relative py-5")
            div{
                className = className( "n-box p-4 n-bottom-space")
                bRow{
                    className = className("pb-4 justify-content-center")
                    bCol {
                         xs = 12; md = 8
                        div {
                            dangerouslySetInnerHTML = jso {
                                __html = remarkable.render(viewModel.texts.instructions, null)
                            }
                        }
                    }
                }
            }
        }
    }
}

@ExperimentalTime
fun ChildrenBuilder.helperComponent() = HELPER_COMPONENT(){}
