package support

import io.ktor.client.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import kotlinx.serialization.builtins.ListSerializer
import techla.base.TechlaJson
import techla.base.techla_log

object RydsCities {
    @Serializable
    data class City(
        val businessArea: String,
        val city: String,
        val companyName: String,
        val contactPerson: String,
        val phone: String,
        val address: String,
        val workshopNumber: String,
        val email: String,
        val id: Int,
    )

    val cityList get() = cities!!

    suspend fun load(store: Store) {
        if (cities == null) {
            val response = store.httpClient.get<HttpResponse>("https://storage.googleapis.com/techla-4ababd96-f873-4ba0-9bca-800870c9f601/rg/cities.json")
            val body = response.readText()
            cities = TechlaJson.decodeFromString(ListSerializer(City.serializer()), body)
        }
    }

    private var cities: List<City>? = null
}










