package components

import bCol
import bootstrap.*
import org.w3c.dom.events.Event
import react.*
import react.dom.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import screens.OrderScreen
import support.className
import techla.order.Order
import kotlin.time.ExperimentalTime

@ExperimentalTime
external interface CouponComponentProps : PropsWithChildren {
    var coupon: String
    var inputChange: ((Event) -> Unit)
    var addCoupon: (() -> Unit)
    var texts: OrderScreen.Texts
    var discount: Order.Discount
    var showError: Boolean
}

@ExperimentalTime
val COUPON_COMPONENT = FC<CouponComponentProps>("COUPON_COMPONENT") { props ->
    fun inputChange(event: Event) {
        props.inputChange(event)
    }

    div {
        className = className("p-4 border rounded")
        bCol {
            className = className("pb-2")
            bFormLabel { +props.texts.titleCoupon }
            when (val discount = props.discount) {
                is Order.Discount.Percentage -> {
                    div {
                        className = className("p-4 rounded n-success-color")
                        p {
                            className = className("mb-0")
                            +(props.texts.couponPercentage1 + discount.name + props.texts.couponPercentage2)
                        }
                    }
                }
                else -> {
                    div {
                        className = className("d-flex justify-content-between")
                        div {
                            className = className("w-75")
                            bFormControl {
                                value = props.coupon
                                name = "coupon"
                                onChange = ::inputChange
                            }
                        }
                        div {
                            className = className("w-25")
                            bButton {
                                onClick = { props.addCoupon() }
                                className = className("ml-3")
                                disabled = props.coupon.isEmpty()

                                +props.texts.buttonRedeem
                            }
                        }
                    }
                    if (props.showError) {
                        div {
                            className = className("p-4 mt-2 rounded n-error-color")
                            p {
                                className = className("mb-0")
                                +props.texts.couponNoMatch
                            }
                        }
                    }
                }
            }
        }
    }
}

@ExperimentalTime
fun ChildrenBuilder.couponComponent(
    coupon: String,
    inputChange: ((Event) -> Unit),
    addCoupon: (() -> Unit),
    texts: OrderScreen.Texts,
    discount: Order.Discount,
    showError: Boolean,
) =
    COUPON_COMPONENT() {
        this.coupon = coupon
        this.inputChange = inputChange
        this.addCoupon = addCoupon
        this.texts = texts
        this.discount = discount
        this.showError = showError
    }