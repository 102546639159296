package support

import StoreContext
import csstype.ClassName
import kotlinx.coroutines.*
import react.*
import screens.OrderScreen
import techla.base.techla_log
import kotlin.time.ExperimentalTime

@ExperimentalTime
fun ChildrenBuilder.useAsyncEffect(vararg dependencies: dynamic, effect: suspend (coroutineScope: CoroutineScope) -> Unit) {
    useEffect(dependencies) {
        val job = MainScope().launch {
            effect(this)
        }
        cleanup {
            job.cancel()
        }
    }
}

@ExperimentalTime
fun ChildrenBuilder.useDelayedAsyncEffect(timeMillis: Long, vararg dependencies: dynamic, effect: suspend (coroutineScope: CoroutineScope) -> Unit) {
    useEffect(dependencies) {
        val job = MainScope().launch {
            delay(timeMillis)
            if (isActive) {
                effect(this)
            }
        }
        cleanup {
            job.cancel()
        }
    }
}

@ExperimentalTime
fun <T> ChildrenBuilder.useSceneEffect(viewModel: T, setViewModel: StateSetter<T>, effect: suspend (Scene.Input<T>) -> Scene.Output<T>?) {
    val (context, dispatch) = useContext(StoreContext)
    useAsyncEffect(viewModel) { coroutineScope ->
        effect(sceneOf(context, viewModel))?.also { (viewModel, actions) ->
            if (coroutineScope.isActive) {
                actions?.run { dispatch(this) }
                viewModel?.run { setViewModel(this) }
            }
        }
    }
}

@ExperimentalTime
fun <T> ChildrenBuilder.useDelayedSceneEffect(
    viewModel: T, setViewModel: StateSetter<T>, timeMillis: Long, effect: suspend (Scene.Input<T>) -> Scene.Output<T>?
) {
    val (context, dispatch) = useContext(StoreContext)
    useDelayedAsyncEffect(timeMillis = timeMillis, viewModel) { coroutineScope ->
        effect(sceneOf(context, viewModel))?.also { (viewModel, actions) ->
            if (coroutineScope.isActive) {
                actions?.run { dispatch(this) }
                viewModel?.run { setViewModel(this) }
            }
        }
    }
}


fun className(classNames: List<String>): ClassName {
    return ClassName(classNames.joinToString(" "))
}

fun className(vararg className: String?): ClassName {
    return ClassName(className.filterNotNull().joinToString(" "))
}
