package support


import io.ktor.client.*
import kotlinx.browser.localStorage
import kotlinx.serialization.builtins.ListSerializer
import techla.base.Identifier
import techla.base.Key
import techla.base.TechlaJson
import techla.content.*
import techla.conversation.Message
import techla.guard.GuardAPI
import techla.order.Article
import techla.order.Order
import techla.order.OrderAPI
import techla.payment.PaymentAPI
import techla.notification.NotificationAPI

import kotlin.time.ExperimentalTime

data class Store(
    val httpClient: HttpClient = HttpClient(),
    val token: String? = localStorage.getItem("token"),
    val medias: List<Media> = localStorage.getItem("medias")
        ?.let { TechlaJson.decodeFromString(ListSerializer(Media.serializer()), it) } ?: emptyList(),
    val articles: List<Article> = localStorage.getItem("articles")
        ?.let { TechlaJson.decodeFromString(ListSerializer(Article.serializer()), it) } ?: emptyList(),
    val messages: List<Message>? = localStorage.getItem("messages")
        ?.let { TechlaJson.decodeFromString(ListSerializer(Message.serializer()), it) },
    val orderId: Identifier<Order>? = localStorage.getItem("orderId")?.let { Identifier(it) },
    val cookie: Boolean = localStorage.getItem("cookie").toBoolean(),

    ) {
    fun get(media: Key<Tag>, content: Key<Content>) =
        medias[media].getOrNull(content)?.text ?: "<Needs translation>"

    sealed class Action {
        data class ApplicationStarted(val token: String) : Action()
        data class MediasLoaded(val medias: List<Media>) : Action()
        data class ArticleLoaded(val articles: List<Article>) : Action()
        data class MessageLoaded(val messages: List<Message>) : Action()
        data class OrderCreated(val orderId: Identifier<Order>) : Action()
        data class AgreedCookies(val cookie: Boolean) : Action()
        object Reset : Action()
    }


    fun reduce(action: Action) =
        when (action) {
            is Action.ApplicationStarted -> {
                localStorage.setItem("token", action.token)
                copy(token = action.token)
            }
            is Action.MediasLoaded -> {
                localStorage.setItem(
                    "medias",
                    TechlaJson.encodeToString(ListSerializer(Media.serializer()), action.medias)
                )
                copy(medias = action.medias)
            }
            is Action.ArticleLoaded -> {
                localStorage.setItem(
                    "articles",
                    TechlaJson.encodeToString(ListSerializer(Article.serializer()), action.articles)
                )
                copy(articles = action.articles)
            }
            is Action.MessageLoaded -> {
                localStorage.setItem(
                    "messages",
                    TechlaJson.encodeToString(ListSerializer(Message.serializer()), action.messages)
                )
                copy(messages = action.messages)
            }
            is Action.OrderCreated -> {
                localStorage.setItem("orderId", action.orderId.rawValue)
                copy(orderId = action.orderId)
            }
            is Action.Reset -> {
                localStorage.setItem("orderId", "")
                copy(orderId = Identifier(""))
            }
            is Action.AgreedCookies -> {
                localStorage.setItem("cookie", action.cookie.toString())
                copy(cookie = action.cookie)
            }
        }

    fun reduce(actions: List<Action>) =
        actions.fold(this) { store, action -> store.reduce(action) }
}

@ExperimentalTime
val Store.guardAPI
    get() =
        GuardAPI(httpClient).also { api ->
            api.host = Deployment.host
            api.token = token
        }

@ExperimentalTime
val Store.contentAPI
    get() =
        ContentAPI(httpClient).also { api ->
            api.host = Deployment.host
            api.token = token
        }

@ExperimentalTime
val Store.orderAPI
    get() =
        OrderAPI(httpClient).also { api ->
            api.host = Deployment.host
            api.token = token
        }

@ExperimentalTime
val Store.paymentAPI
    get() =
        PaymentAPI(httpClient).also { api ->
            api.host = Deployment.host
            api.token = token
        }

@ExperimentalTime
val Store.notificationAPI
    get() =
        NotificationAPI(httpClient).also { api ->
            api.host = Deployment.host
            api.token = token
        }