package support

import components.dataLayer
import kotlinx.browser.window
import kotlinx.js.jso
import screens.dimensions
import screens.quantity
import techla.order.Article
import techla.order.Order
import techla.order.OrderItem
import kotlin.math.ceil
import kotlin.time.ExperimentalTime

val OrderItem.itemPrice : Int get() =
    ceil((((total as? Order.Total.Price)?.amount?.amount) ?: 0.0) / quantity.toDouble()).toInt()

@ExperimentalTime
object Ecommerce {
    fun ga3ViewContent(orderItem: OrderItem) {
        val cost = orderItem.itemPrice
        window.dataLayer.push(
            jso() {
                ecommerce = null
            }
        )
        window.dataLayer.push(
            jso {
                event = "viewContent"
                ecommerce = jso {
                    detail = jso {
                        products = arrayOf<dynamic>(
                            jso {
                                name = orderItem.article?.specification
                                id = orderItem.article?.id?.rawValue
                                price = "$cost"
                                brand = "Speglar.nu"
                                category = if (orderItem.article?.kind == Article.Kind.None) {
                                    "Speglar"
                                } else "Tillbehör"
                                variant = if (orderItem.article?.kind == Article.Kind.None) {
                                    "Höjd: ${orderItem.dimensions?.height} m, Bredd: ${orderItem.dimensions?.width} m"
                                } else ""
                            }
                        )
                    }
                }
            }
        )
    }

    fun ga3AddToCart(orderItem: OrderItem) {
        val listOfProducts: MutableList<Any> = mutableListOf()

        val cost = orderItem.itemPrice
        val kind = if (orderItem.article?.kind == Article.Kind.None) {
            "Speglar"
        } else "Tillbehör"

        listOfProducts.add(
            jso<dynamic> {
                name = orderItem.article?.specification
                id = orderItem.article?.id?.rawValue
                price = "$cost"
                brand = "Speglar.nu"
                category = kind
                variant = if (orderItem.article?.kind == Article.Kind.None) {
                    "Höjd: ${orderItem.dimensions?.height ?: ""} m, Bredd: ${orderItem.dimensions?.width ?: ""} m"
                } else ""
                quantity = orderItem.quantity
            } as Any)

        window.dataLayer.push(
            jso {
                ecommerce = null
            }
        )
        window.dataLayer.push(
            jso {
                event = "addToCart"
                ecommerce = jso {
                    currencyCode = "SEK"
                    add = jso {
                        products = listOfProducts.toTypedArray<dynamic>()
                    }
                }
            }
        )
    }

    fun ga3Checkout(orderItems: List<OrderItem>) {
        val listOfProducts: MutableList<Any> = mutableListOf()

        for (orderItem in orderItems) {
            val cost = orderItem.itemPrice
            val kind = if (orderItem.article?.kind == Article.Kind.None) {
                "Speglar"
            } else "Tillbehör"

            listOfProducts.add(
                jso<dynamic> {
                    name = orderItem.article?.specification
                    id = orderItem.article?.id?.rawValue
                    price = "$cost"
                    brand = "Speglar.nu"
                    category = kind
                    variant = if (orderItem.article?.kind == Article.Kind.None) {
                        "Höjd: ${orderItem.dimensions?.height} m, Bredd: ${orderItem.dimensions?.width} m"
                    } else ""
                    quantity = orderItem.quantity
                } as Any)
        }


        window.dataLayer.push(
            jso {
                ecommerce = null
            }
        )
        window.dataLayer.push(
            jso {
                event = "checkout"
                ecommerce = jso {
                    actionField = jso {
                        step = 1
                        step = "save contact information"
                    }
                    products = listOfProducts.toTypedArray<dynamic>()
                }
            }
        )
    }

    fun ga3RemoveFromCart(orderItem: OrderItem) {
        val listOfProducts: MutableList<Any> = mutableListOf()

        val cost = orderItem.itemPrice
        val kind = if (orderItem.article?.kind == Article.Kind.None) {
            "Speglar"
        } else "Tillbehör"

        listOfProducts.add(
            jso<dynamic> {
                name = orderItem.article?.specification
                id = orderItem.article?.id?.rawValue
                price = "$cost"
                brand = "Speglar.nu"
                category = kind
                variant = if (orderItem.article?.kind == Article.Kind.None) {
                    "Höjd: ${orderItem.dimensions?.height ?: ""} m, Bredd: ${orderItem.dimensions?.width ?: ""} m"
                } else ""
                quantity = orderItem.quantity
            } as Any)

        window.dataLayer.push(
            jso {
                ecommerce = null
            }
        )
        window.dataLayer.push(
            jso {
                event = "removeFromCart"
                ecommerce = jso {
                    currencyCode = "SEK"
                    add = jso {
                        products = listOfProducts.toTypedArray<dynamic>()
                    }
                }
            }
        )
    }

    fun ga3Purchase(orderItems: List<OrderItem>?, order: Order?) {
        val listOfProducts: MutableList<Any> = mutableListOf()

        if (orderItems != null) {
            for (orderItem in orderItems) {
                val cost = orderItem.itemPrice
                val kind = if (orderItem.article?.kind == Article.Kind.None) {
                    "Speglar"
                } else "Tillbehör"

                listOfProducts.add(
                    jso<dynamic> {
                        name = orderItem.article?.specification
                        id = orderItem.article?.id?.rawValue
                        price = "$cost"
                        brand = "Speglar.nu"
                        category = kind
                        variant = if (orderItem.article?.kind == Article.Kind.None) {
                            "Bredd: ${orderItem.dimensions?.width ?: "-"} m, Höjd: ${orderItem.dimensions?.height ?: "-"} m "
                        } else ""
                        quantity = orderItem.quantity
                        coupon = (order?.discount as? Order.Discount.Percentage)?.name ?: ""
                    } as Any)
            }
        }

        window.dataLayer.push(
            jso {
                ecommerce = null
            }
        )
        window.dataLayer.push(
            jso {
                event = "purchase"
                ecommerce = jso {
                    purchase = jso {
                        actionField = jso {
                            id = order?.id?.rawValue
                            affiliation = "Online Store"
                            revenue = (order?.total as? Order.Total.Price)?.amount?.amount
                            tax = (order?.total as? Order.Total.Price)?.taxAmount?.amount
                            shipping = "0"
                            coupon = (order?.discount as? Order.Discount.Percentage)?.name
                        }
                        products = listOfProducts.toTypedArray<dynamic>()
                    }
                }
            }
        )
    }


    fun ga4ViewContent(orderItem: OrderItem) {
        val cost = orderItem.itemPrice
        window.dataLayer.push(
            jso() {
                ecommerce = null
            }
        )
        window.dataLayer.push(
            jso {
                event = "ga4.view_item"
                ecommerce = jso {
                    currency = "SEK"
                    value = (orderItem.total as? Order.Total.Price)?.amount?.amount
                    items = arrayOf<dynamic>(
                        jso {
                            item_id = orderItem.article?.id?.rawValue
                            item_name = orderItem.article?.specification
                            price = cost
                            item_brand = "Speglar.nu"
                            item_category = if (orderItem.article?.kind == Article.Kind.None) {
                                "Speglar"
                            } else "Tillbehör"
                            item_variant = if (orderItem.article?.kind == Article.Kind.None) {
                                "Höjd: ${orderItem.dimensions?.height} m, Bredd: ${orderItem.dimensions?.width} m"
                            } else ""
                        }
                    )
                }
            }
        )
    }

    fun ga4AddToCart(orderItem: OrderItem) {
        val listOfProducts: MutableList<Any> = mutableListOf()

        val cost = orderItem.itemPrice
        val kind = if (orderItem.article?.kind == Article.Kind.None) {
            "Speglar"
        } else "Tillbehör"

        listOfProducts.add(
            jso<dynamic> {
                item_id = orderItem.article?.id?.rawValue
                item_name = orderItem.article?.specification
                price = cost
                item_brand = "Speglar.nu"
                item_category = kind
                item_variant = if (orderItem.article?.kind == Article.Kind.None) {
                    "Höjd: ${orderItem.dimensions?.height ?: ""} m, Bredd: ${orderItem.dimensions?.width ?: ""} m"
                } else ""
                quantity = orderItem.quantity
            } as Any)

        window.dataLayer.push(
            jso {
                ecommerce = null
            }
        )
        window.dataLayer.push(
            jso {
                event = "ga4.add_to_cart"
                ecommerce = jso {
                    currency = "SEK"
                    value = cost
                    items = listOfProducts.toTypedArray<dynamic>()
                }
            }
        )
    }

    fun ga4Checkout(orderItems: List<OrderItem>) {
        val listOfProducts: MutableList<Any> = mutableListOf()

        orderItems.forEachIndexed { i, orderItem ->
            val cost = orderItem.itemPrice
            val kind = if (orderItem.article?.kind == Article.Kind.None) {
                "Speglar"
            } else "Tillbehör"

            listOfProducts.add(
                jso<dynamic> {
                    index = i
                    item_id = orderItem.article?.id?.rawValue
                    item_name = orderItem.article?.specification
                    price = cost
                    item_brand = "Speglar.nu"
                    item_category = kind
                    item_variant = if (orderItem.article?.kind == Article.Kind.None) {
                        "Höjd: ${orderItem.dimensions?.height} m, Bredd: ${orderItem.dimensions?.width} m"
                    } else ""
                    quantity = orderItem.quantity
                } as Any)
        }

        window.dataLayer.push(
            jso {
                ecommerce = null
            }
        )
        window.dataLayer.push(
            jso {
                event = "ga4.begin_checkout"
                ecommerce = jso {
                    currency = "SEK"
                    value = (orderItems.firstOrNull()?.order?.total as? Order.Total.Price)?.amount?.amount
                    items = listOfProducts.toTypedArray<dynamic>()
                }
            }
        )
    }

    fun ga4RemoveFromCart(orderItem: OrderItem) {
        val listOfProducts: MutableList<Any> = mutableListOf()

        val cost = orderItem.itemPrice
        val kind = if (orderItem.article?.kind == Article.Kind.None) {
            "Speglar"
        } else "Tillbehör"

        listOfProducts.add(
            jso<dynamic> {
                item_id = orderItem.article?.id?.rawValue
                item_name = orderItem.article?.specification
                price = cost
                item_brand = "Speglar.nu"
                item_category = kind
                item_variant = if (orderItem.article?.kind == Article.Kind.None) {
                    "Höjd: ${orderItem.dimensions?.height ?: ""} m, Bredd: ${orderItem.dimensions?.width ?: ""} m"
                } else ""
                quantity = orderItem.quantity
            } as Any)

        window.dataLayer.push(
            jso {
                ecommerce = null
            }
        )
        window.dataLayer.push(
            jso {
                event = "ga4.remove_from_cart"
                ecommerce = jso {
                    currency = "SEK"
                    value = cost
                    items = listOfProducts.toTypedArray<dynamic>()
                }
            }
        )
    }

    fun ga4Purchase(orderItems: List<OrderItem>?, order: Order?) {
        val listOfProducts: MutableList<Any> = mutableListOf()

        orderItems?.forEachIndexed { i, orderItem ->
            val cost = orderItem.itemPrice
            val kind = if (orderItem.article?.kind == Article.Kind.None) {
                "Speglar"
            } else "Tillbehör"

            listOfProducts.add(
                jso<dynamic> {
                    index = i
                    item_id = orderItem.article?.id?.rawValue
                    item_name = orderItem.article?.specification
                    price = cost
                    item_brand = "Speglar.nu"
                    item_category = kind
                    item_variant = if (orderItem.article?.kind == Article.Kind.None) {
                        "Bredd: ${orderItem.dimensions?.width ?: "-"} m, Höjd: ${orderItem.dimensions?.height ?: "-"} m "
                    } else ""
                    quantity = orderItem.quantity
                } as Any)
        }

        window.dataLayer.push(
            jso {
                ecommerce = null
            }
        )
        window.dataLayer.push(
            jso {
                event = "ga4.purchase"
                ecommerce = jso {
                    transaction_id = order?.id?.rawValue
                    value = (order?.total as? Order.Total.Price)?.amount?.amount
                    tax = (order?.total as? Order.Total.Price)?.taxAmount?.amount
                    currency = "SEK"
                    coupon = (order?.discount as? Order.Discount.Percentage)?.name ?: ""
                    items = listOfProducts.toTypedArray<dynamic>()
                }
            }
        )
    }
}