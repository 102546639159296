package bootstrap

import csstype.ClassName
import org.w3c.dom.events.Event
import react.*

@JsModule("react-bootstrap/Navbar")
private external val navbarModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bNavbar: ComponentType<NavbarProps> = navbarModule.default

external interface NavbarProps : PropsWithChildren {
    var `as`: Any
    var bg: String
    var collapseOnSelect: Boolean
    var expand: dynamic /* true | 'sm' | 'md' | 'lg' | 'xl' */
    var expanded: Boolean
    var fixed: String /* 'top' | 'bottom' */
    var onSelect: (Event) -> Unit
    var onToggle: (Event) -> Unit
    var role: String /* 'navigation' */
    var sticky: String /* 'top' */
    var variant: String /* 'light' | 'dark' */
    var bsPrefix: String
    var className: ClassName
}
