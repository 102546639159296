package bootstrap

import react.*

@JsModule("react-bootstrap/Spinner")
private external val spinnerModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bSpinner: ComponentType<SpinnerProps> = spinnerModule.default

external interface SpinnerProps : PropsWithChildren {
    var animation: String /* 'border' | 'grow' */
    var variant: String
}
