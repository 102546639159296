package components


import FaqContext
import FrontContext
import HelperContext
import StoreContext
import bootstrap.*
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.*
import react.dom.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import screens.FaqScreen
import screens.FrontScreen
import screens.HelperScreen
import screens.OrderScreen
import support.className
import support.sceneOf
import techla.base.techla_log
import kotlin.time.ExperimentalTime

@ExperimentalTime
val COOKIE_COMPONENT = FC<PropsWithChildren>("COOKIE_COMPONENT ") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useContext(FrontContext)


    fun acceptCookies() {
        techla_log("Accept")
        MainScope().launch {
            val (new, actions) = FrontScreen.saveInLocalStorage(sceneOf(store, viewModel), cookie = true)
            dispatch(actions)
            setViewModel(new)
        }
    }


    if (!store.cookie) {

        bContainer {
            className = className("py-3 px-0 n-cookies-index fixed-bottom")
            div {
                className = className("n-cookies px-5 py-2")
                p {
                    className = className("px-0 px-md-5 text-white")
                    +"Vi använder cookies för att se till att vi ger dig den bästa upplevelsen på vår hemsida. Om du fortsätter att använda den här webbplatsen kommer vi att anta att du godkänner detta."
                }
                bButton {
                    className = className("ml-auto")
                    onClick = { acceptCookies() }
                    +"OK"
                }
            }
        }
    }

}

@ExperimentalTime
fun ChildrenBuilder.cookieComponent() =
    COOKIE_COMPONENT() {}
