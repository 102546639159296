package components

import bCol
import bootstrap.*
import org.w3c.dom.events.Event
import react.*
import react.dom.*
import react.dom.html.ReactHTML.div
import screens.OrderScreen
import support.className
import kotlin.time.ExperimentalTime

@ExperimentalTime
external interface ContactComponentProps : PropsWithChildren {
    var firstName: String
    var lastName: String
    var email: String
    var phoneNumber: String
    var street: String
    var postalCode: String
    var city: String
    var inputChange: ((Event) -> Unit)
    var montageIsSelected: Boolean
    var texts: OrderScreen.Texts
    var isValid: Boolean
    var validationContactInformation: String

}

@ExperimentalTime
val CONTACT_COMPONENT = FC<ContactComponentProps>("CONTACT_COMPONENT") { props ->
    fun inputChange(event: Event) {
        props.inputChange(event)
    }

    div {
        className = className("p-4 border rounded")

        bRow {
            className = className("g-2")
            bCol {
                className = className("pb-2")
                xs = 12; lg = 6
                bFormLabel { +props.texts.firstName }
                bFormControl {
                    value = props.firstName
                    name = "firstName"
                    onChange = ::inputChange
                }
                if (!props.isValid && props.firstName.trim().length <= 2) {
                    bFormControlFeedback {
                        type = "invalid"
                        +props.validationContactInformation
                    }
                }
            }

            bCol {
                className = className("pb-2")
                xs = 12; lg = 6
                bFormLabel { +props.texts.lastName }
                bFormControl {
                    value = props.lastName
                    name = "lastName"
                    onChange = ::inputChange
                }
                if (!props.isValid && props.lastName.trim().length <= 2) {
                    bFormControlFeedback {
                        type = "invalid"
                        +props.validationContactInformation
                    }
                }
            }

            bCol {
                className = className("pb-2")
                xs = 12; lg = 6
                bFormLabel { +props.texts.email }
                bFormControl {
                    value = props.email
                    name = "email"
                    onChange = ::inputChange
                }
                if (!props.isValid && !props.email.contains("@") || !props.isValid && !props.email.contains(".")) {
                    bFormControlFeedback {
                        type = "invalid"
                        +props.validationContactInformation
                    }
                }
            }

            bCol {
                className = className("pb-2")
                xs = 12; lg = 6
                bFormLabel { +props.texts.phoneNumber }
                bFormControl {
                    value = props.phoneNumber
                    name = "phoneNumber"
                    onChange = ::inputChange
                }
                if (!props.isValid && props.phoneNumber.trimStart().length !in 9..13) {
                    bFormControlFeedback {
                        type = "invalid"
                        +props.validationContactInformation
                    }
                }
            }
            if (props.montageIsSelected) {

                bCol {
                    className = className("pb-2")
                    xs = 12; lg = 6
                    bFormLabel { +props.texts.street }
                    bFormControl {
                        value = props.street
                        name = "street"
                        onChange = ::inputChange

                    }
                    if (!props.isValid && props.street.trim().length <= 2) {
                        bFormControlFeedback {
                            type = "invalid"
                            +props.validationContactInformation
                        }
                    }
                }
                bCol {
                    className = className("pb-2")
                    xs = 12; lg = 6
                    bFormLabel { +props.texts.postalCode }
                    bFormControl {
                        value = props.postalCode
                        name = "postalCode"
                        onChange = ::inputChange
                    }
                    if (!props.isValid && props.postalCode.trimStart().length <= 4) {
                        bFormControlFeedback {
                            type = "invalid"
                            +props.validationContactInformation
                        }
                    }
                }
                bCol {
                    className = className("pb-2")
                    xs = 12; lg = 6
                    bFormLabel { +props.texts.city }
                    bFormControl {
                        value = props.city
                        name = "city"
                        onChange = ::inputChange
                    }
                    if (!props.isValid && props.city.trim().length <= 2) {
                        bFormControlFeedback {
                            type = "invalid"
                            +props.validationContactInformation
                        }
                    }
                }
            }
        }
    }
}


@ExperimentalTime
fun ChildrenBuilder.contactComponent(
    firstName: String, lastName: String, email: String,
    phoneNumber: String,
    street: String,
    postalCode: String,
    city: String, inputChange: ((Event) -> Unit),
    montageIsSelected: Boolean,
    texts: OrderScreen.Texts,
    isValid: Boolean,
    validationContactInformation: String

) =
    CONTACT_COMPONENT() {
        this.firstName = firstName
        this.lastName = lastName
        this.email = email
        this.phoneNumber = phoneNumber
        this.street = street
        this.postalCode = postalCode
        this.city = city
        this.inputChange = inputChange
        this.montageIsSelected = montageIsSelected
        this.texts = texts
        this.isValid = isValid
        this.validationContactInformation = validationContactInformation
    }