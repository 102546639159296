import kotlinx.browser.document
import kotlinx.browser.window
import react.create
import react.dom.client.createRoot
import kotlin.time.ExperimentalTime

@ExperimentalTime
fun main() {
    js("import('./css/style.css')")

    window.onload = {
        val rootElement = document.getElementById("app")
        val root = createRoot(rootElement!!)
        root.render(App.create())
    }
}
