package components

import OwnDesignContext
import StoreContext
import bCol
import bootstrap.*
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.*
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import screens.OwnDesignScreen
import support.className
import support.sceneOf
import techla.base.techla_log
import kotlin.time.ExperimentalTime

@ExperimentalTime
val OWN_DESIGN_COMPONENT = FC<PropsWithChildren>("OWN_DESIGN_COMPONENT") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useContext(OwnDesignContext)


    useEffect(viewModel) {
        when (viewModel) {
            is OwnDesignScreen.ViewModel.None -> {
                techla_log("None")
                MainScope().launch {
                    val (new, actions) = OwnDesignScreen.start(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)
                }
            }
            is OwnDesignScreen.ViewModel.Started -> {
                techla_log("STARTED")
                MainScope().launch {
                    val (new, actions) = OwnDesignScreen.load(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)
                }
            }
            is OwnDesignScreen.ViewModel.OwnDesignReady -> {
                techla_log("FAQ READY")

            }
            is OwnDesignScreen.ViewModel.Failed -> {
                techla_log("FAQ READY")
                techla_log(viewModel.message)

            }
        }
    }

    if (viewModel is OwnDesignScreen.ViewModel.Started) {
        div {
            bContainer {
                bSpinner {

                }
            }
        }
    }

    if (viewModel is OwnDesignScreen.ViewModel.OwnDesignReady) {
        bContainer {
            className = className("py-5 n-bottom-space")
            bRow {
                className = className("pb-4 justify-content-center")
                bCol {
                    className = className("p-4 text-center")
                    xs = 12; md = 8
                    img {
                        src = "svg/ico_custom_shape.svg"
                        className = className("py-4")
                    }
                    h1 {
                        +viewModel.texts.title
                    }
                    p {
                        +viewModel.texts.subText
                    }
                    p {
                        +viewModel.texts.contactText
                    }
                    a {
                        target = AnchorTarget._blank
                        href = "mailto:${viewModel.texts.email}"
                        className = className("largelink")
                        +viewModel.texts.email
                    }
                }
            }
        }
    }
}

@ExperimentalTime
fun ChildrenBuilder.ownDesignComponent() = OWN_DESIGN_COMPONENT() {}
