package bootstrap

import csstype.ClassName
import react.*

@JsModule("react-bootstrap/FormControl")
private external val formControlModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bFormControlFeedback: ComponentType<FormControlFeedbackProps> = formControlModule.default.Feedback

external interface FormControlFeedbackProps : PropsWithChildren {
    var `as`: dynamic /* 'input' | elementType */
    var className: ClassName
    var tooltip: Boolean
    var type: String /* 'valid' | 'invalid' */
    var bsPrefix: String
}
