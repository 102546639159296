package screens

import kotlinx.browser.window
import support.*
import techla.base.*
import techla.guard.ApplicationAuthentication
import techla.order.Article
import kotlin.time.ExperimentalTime

@ExperimentalTime
object FrontScreen {
    data class Texts(
        val footerTime: String,
        val footerMontage: String,
        val footerPickup: String,
        val footerKlarna: String,
        val footerTerms: String,
        val headerFaq: String,
        val headerInspiration: String,
        val headerHelper: String,
        val headerStart: String,
        val phone: String,
        val email: String,
        val buttonGoBack: String,
        val errorMessage: String,
        val star: String,
        val termsTitle: String,
        val generalTitle: String,
        val generalBody: String,
        val orderTitle: String,
        val orderBody: String,
        val priceTitle: String,
        val priceBody: String,
        val paymentTitle1: String,
        val paymentTitle2: String,
        val paymentBody1: String,
        val paymentBody2: String,
        val paymentLink: String,
        val deliveryTimeTitle: String,
        val deliveryTimeBody: String,
        val deliveryDelayTitle: String,
        val deliveryDelayBody: String,
        val guaranteeTitle: String,
        val guaranteeBody: String,
        val withdrawalTitle: String,
        val withdrawalBody: String,
        val complaintTitle: String,
        val complaintBody: String,
        val uncollectedMirrorsTitle: String,
        val uncollectedMirrorsBody: String,
        val personalDataTitle: String,
        val personalDataBody: String,
        val cookiesTitle: String,
        val cookiesBody: String,
        val reservationTitle: String,
        val reservationBody: String,
        val klarnaTitle: String,
        val KlarnaBody1: String,
        val KlarnaBody2: String,
        val KlarnaLink: String,

        )

    enum class Navigation {
        DESIGN, INSPIRATION, FAQ
    }

    sealed class ViewModel(val texts: Texts) {
        object None : ViewModel(
            Texts(
                "-",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
            )
        )

        class Started(
            texts: Texts,
        ) : ViewModel(texts)

        class Ready(
            texts: Texts,
        ) : ViewModel(texts)

        class Failed(
            texts: Texts,
            val message: String
        ) : ViewModel(texts)


        fun started() =
            Started(texts = texts)

        fun ready(texts: Texts) = Ready(texts = texts)

        fun failed(message: String) =
            Failed(texts = texts, message = message)

        fun failed(result: Either<List<Warning>, Throwable>) =
            failed(
                message = when (result) {
                    is Either.Left -> "(${result.value.joinToString(", ") { it.message }})"
                    is Either.Right -> "(${result.value.message})"
                }
            )
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf<ViewModel>(viewModel.failed(result))

    suspend fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        RydsCities.load(store = store)

        val create = ApplicationAuthentication.Create(
            applicationKey = Deployment.applicationKey,
            applicationSecret = Deployment.applicationSecret,
            device = Device.Web(window.navigator.userAgent),
        )
        try {
            return store.guardAPI.createApplicationAuthentication(create)
                .map { applicationAuthentication ->
                    techla_log("Created: ${applicationAuthentication.createdAt}")
                    val action = Store.Action.ApplicationStarted(token = applicationAuthentication.token!!)
                    sceneOf<ViewModel>(viewModel.started(), action)
                }
                .fold(
                    onSuccess = { it },
                    onNotSuccess = { scene.failed(result = it) }
                )
        } catch (e: Throwable) {
            techla_log("Error: $e")
            throw e
        }
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.contentAPI.findMedias()
            .zip(store.orderAPI.findArticles())
            .map { (medias, articles) ->
                val media = medias.firstOrNull()
                val activeArticles = articles
                    .filter { (it.status is Article.Status.Active) }

                val actions = listOf(
                    Store.Action.MediasLoaded(medias = medias),
                    Store.Action.ArticleLoaded(articles = activeArticles),
                )

                val updated = store.reduce(actions)
                val texts = Texts(
                    footerTime = updated.get(media = Key("screen:front"), content = Key("footerTime")),
                    footerMontage = updated.get(media = Key("screen:front"), content = Key("footerMontage")),
                    footerPickup = updated.get(media = Key("screen:front"), content = Key("footerPickup")),
                    footerKlarna = updated.get(media = Key("screen:front"), content = Key("footerKlarna")),
                    footerTerms = updated.get(media = Key("screen:front"), content = Key("footerTerms")),
                    headerFaq = updated.get(media = Key("screen:front"), content = Key("headerFaq")),
                    headerInspiration = updated.get(media = Key("screen:front"), content = Key("headerInspiration")),
                    headerStart = updated.get(media = Key("screen:front"), content = Key("headerStart")),
                    phone = updated.get(media = Key("screen:front"), content = Key("phone")),
                    email = updated.get(media = Key("screen:front"), content = Key("email")),
                    buttonGoBack = updated.get(media = Key("screen:front"), content = Key("buttonGoBack")),
                    errorMessage = updated.get(media = Key("screen:front"), content = Key("errorMessage")),
                    headerHelper = updated.get(media = Key("screen:front"), content = Key("headerHelper")),
                    star = updated.get(media = Key("screen:front"), content = Key("star")),

                    termsTitle = updated.get(media = Key("screen:front"), content = Key("termsTitle")),
                    generalTitle = updated.get(media = Key("screen:front"), content = Key("generalTitle")),
                    generalBody = updated.get(media = Key("screen:front"), content = Key("generalBody")),
                    orderTitle = updated.get(media = Key("screen:front"), content = Key("orderTitle")),
                    orderBody = updated.get(media = Key("screen:front"), content = Key("orderBody")),
                    priceTitle = updated.get(media = Key("screen:front"), content = Key("priceTitle")),
                    priceBody = updated.get(media = Key("screen:front"), content = Key("priceBody")),
                    paymentTitle1 = updated.get(media = Key("screen:front"), content = Key("paymentTitle.1")),
                    paymentTitle2 = updated.get(media = Key("screen:front"), content = Key("paymentTitle.2")),
                    paymentBody1 = updated.get(media = Key("screen:front"), content = Key("paymentBody.1")),
                    paymentBody2 = updated.get(media = Key("screen:front"), content = Key("paymentBody.2")),
                    paymentLink = updated.get(media = Key("screen:front"), content = Key("paymentLink")),
                    deliveryTimeTitle = updated.get(media = Key("screen:front"), content = Key("deliveryTimeTitle")),
                    deliveryTimeBody = updated.get(media = Key("screen:front"), content = Key("deliveryTimeBody")),
                    deliveryDelayTitle = updated.get(media = Key("screen:front"), content = Key("deliveryDelayTitle")),
                    deliveryDelayBody = updated.get(media = Key("screen:front"), content = Key("deliveryDelayBody")),
                    guaranteeTitle = updated.get(media = Key("screen:front"), content = Key("guaranteeTitle")),
                    guaranteeBody = updated.get(media = Key("screen:front"), content = Key("guaranteeBody")),
                    withdrawalTitle = updated.get(media = Key("screen:front"), content = Key("withdrawalTitle")),
                    withdrawalBody = updated.get(media = Key("screen:front"), content = Key("withdrawalBody")),
                    complaintTitle = updated.get(media = Key("screen:front"), content = Key("complaintTitle")),
                    complaintBody = updated.get(media = Key("screen:front"), content = Key("complaintBody")),
                    uncollectedMirrorsTitle = updated.get(
                        media = Key("screen:front"),
                        content = Key("uncollectedMirrorsTitle")
                    ),
                    uncollectedMirrorsBody = updated.get(
                        media = Key("screen:front"),
                        content = Key("uncollectedMirrorsBody")
                    ),
                    personalDataTitle = updated.get(media = Key("screen:front"), content = Key("personalDataTitle")),
                    personalDataBody = updated.get(media = Key("screen:front"), content = Key("personalDataBody")),
                    cookiesTitle = updated.get(media = Key("screen:front"), content = Key("cookiesTitle")),
                    cookiesBody = updated.get(media = Key("screen:front"), content = Key("cookiesBody")),
                    reservationTitle = updated.get(media = Key("screen:front"), content = Key("reservationTitle")),
                    reservationBody = updated.get(media = Key("screen:front"), content = Key("reservationBody")),
                    klarnaTitle = updated.get(media = Key("screen:front"), content = Key("klarnaTitle")),
                    KlarnaBody1 = updated.get(media = Key("screen:front"), content = Key("KlarnaBody.1")),
                    KlarnaBody2 = updated.get(media = Key("screen:front"), content = Key("KlarnaBody.2")),
                    KlarnaLink = updated.get(media = Key("screen:front"), content = Key("KlarnaLink")),


                    )
                return sceneOf<ViewModel>(viewModel.ready(texts = texts), actions)

            }
            .fold(
                onSuccess = { it },
                onNotSuccess = { scene.failed(result = it) }
            )


    }

    fun saveInLocalStorage(scene: Scene.Input<ViewModel>, cookie: Boolean): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val action = listOf(
            Store.Action.AgreedCookies(cookie = cookie),
        )

        return sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts), action)
    }
/*
    suspend fun change(scene: Scene.Input<ViewModel>, navigation: Navigation): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return when (navigation) {
            Navigation.DESIGN -> {
                sceneOf<ViewModel>(viewModel.design(texts = viewModel.texts))
            }
            Navigation.INSPIRATION -> {
                sceneOf<ViewModel>(viewModel.inspiration(texts = viewModel.texts))
            }
            Navigation.FAQ -> {
                sceneOf<ViewModel>(viewModel.faq(texts = viewModel.texts))
            }
        }
    }*/
}


