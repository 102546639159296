package components


import FaqContext
import StoreContext
import bCol
import bootstrap.*
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.js.jso
import react.*
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.sup
import screens.FaqScreen
import support.className
import support.sceneOf
import techla.base.techla_log
import kotlin.time.ExperimentalTime


@ExperimentalTime
val FaqComponent = FC<PropsWithChildren>("FAQ_COMPONENT ") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useContext(FaqContext)
    val (isActive, setIsActive) = useState(100)


    useEffect(viewModel) {
        when (viewModel) {
            is FaqScreen.ViewModel.None -> {
                techla_log("None")
                MainScope().launch {
                    val (new, actions) = FaqScreen.start(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)
                }
            }
            is FaqScreen.ViewModel.Started -> {
                techla_log("STARTED")
                MainScope().launch {
                    val (new, actions) = FaqScreen.load(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)
                }
            }
            is FaqScreen.ViewModel.FaqReady -> {
                techla_log("FAQ READY")

            }
            is FaqScreen.ViewModel.Failed -> {
                techla_log("FAQ READY")
                techla_log(viewModel.message)

            }
        }
    }

    if (viewModel is FaqScreen.ViewModel.Started) {
        div {
            bContainer {
                bSpinner {

                }
            }
        }
    }



    if (viewModel is FaqScreen.ViewModel.FaqReady) {
        bContainer {
            className = className("position-relative py-5")
            div {
                className = className("n-box p-4 n-bottom-space")
                bRow {
                    bCol {
                        h1 {
                            className = className("text-center")
                            +viewModel.texts.title
                        }
                    }

                }

                bRow {
                    className = className("pb-4")
                    bCol {
                        bAccordion {
                            activeKey = isActive.toString()
                            viewModel.faqs.mapIndexed { index: Int, faq: FaqScreen.Faq ->
                                bAccordionItem {
                                    eventKey = "$index"
                                    bCardHeader {
                                        div {
                                            onClick = { setIsActive(if (isActive == index) viewModel.faqs.size + 1 else index) }
                                            if (faq.question == viewModel.texts.question3) {
                                                +faq.question
                                                sup { +viewModel.texts.star }
                                            } else {
                                                +faq.question
                                            }

                                            if (isActive != index) {
                                                div {
                                                    className = className("float-right d-inline")
                                                    img {
                                                        className = className("n-pointer")
                                                        alt = "Open arrow"
                                                        src = "svg/ico_arrow_down.svg"
                                                    }
                                                }
                                            } else {
                                                div {
                                                    className = className("float-right d-inline")
                                                    img {
                                                        className = className("n-pointer")
                                                        alt = "Close arrow"
                                                        src = "svg/ico_arrow_up.svg"
                                                    }
                                                }
                                            }
                                        }
                                    }



                                    bAccordionCollapse {
                                        eventKey = "$index"
                                        when (faq.answer) {
                                            viewModel.texts.answer5 -> {
                                                bCardBody {
                                                    +"${faq.answer} ${viewModel.texts.answer5Email} "
                                                    a {
                                                        className = className("greenlink")
                                                        href = "mailto:${viewModel.texts.email}"


                                                        +viewModel.texts.email
                                                    }
                                                    +" ${viewModel.texts.answer5Cal} "
                                                    a {
                                                        className = className("greenlink")
                                                        href = "tel:${viewModel.texts.phoneNumber}"
                                                        +viewModel.texts.phoneNumber
                                                    }
                                                    +"."

                                                }
                                            }
                                            viewModel.texts.answer6 -> {
                                                bCardBody {
                                                    a {
                                                        className = className("greenlink")
                                                        href = viewModel.texts.answer6Link
                                                        target = AnchorTarget._blank
                                                        +faq.answer
                                                    }
                                                }

                                            }
                                            else -> {
                                                bCardBody {

                                                    div {
                                                        dangerouslySetInnerHTML = jso {
                                                            __html = remarkable.render(faq.answer, null)
                                                        }


                                                        dangerouslySetInnerHTML = jso {
                                                            __html = remarkable.render(faq.answer, null)
                                                        }
                                                    }


                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }


            }
        }
    }
}

@ExperimentalTime
fun ChildrenBuilder.faqComponent() = FaqComponent() {}
