package components

import FrontContext
import bCol
import bootstrap.*
import react.*
import react.dom.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h4
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.sup
import react.router.dom.NavLink
import react.router.useNavigate
import screens.FrontScreen
import support.className
import kotlin.time.ExperimentalTime

@ExperimentalTime
val FOOTER_COMPONENT = FC<PropsWithChildren>("FOOTER_COMPONENT") {
    val (viewModel, setViewModel) = useContext(FrontContext)
    val (redirect, setRedirect) = useState(0)
    val navigate = useNavigate()


    if (redirect != 0) {
        navigate("/faq")
    }

    if (viewModel !is FrontScreen.ViewModel.None && viewModel !is FrontScreen.ViewModel.Failed) {
        cookieComponent()
        bContainer {
            fluid = true
            className = className("fixed-bottom n-footer")
            bContainer {
                bRow {
                    bCol {
                        className = className("p-0")
                        xs = 3; md = 3
                        div {
                            onClick = { setRedirect(1) }
                            img {
                                src = "svg/ico_48.svg"
                                alt = viewModel.texts.footerTime
                                className = className("mx-auto d-block p-2")


                            }
                            h4 {
                                className = className("text-center text-white n-usp")
                                +viewModel.texts.footerTime
                                sup { +viewModel.texts.star }
                            }
                        }
                    }
                    bCol {
                        className = className("p-0")
                        xs = 3; md = 3
                        div {
                            onClick = { setRedirect(1) }

                            img {
                                src = "svg/ico_tools.svg"
                                alt = viewModel.texts.footerMontage
                                className = className("mx-auto d-block p-2")

                            }
                            h4 {
                                className = className("text-center text-white n-usp")
                                +viewModel.texts.footerMontage
                            }
                        }
                    }
                    bCol {
                        className = className("p-0")
                        xs = 3; md = 3
                        div {
                            onClick = { setRedirect(1) }

                            img {
                                src = "svg/ico_pickup.svg"
                                alt = viewModel.texts.footerPickup
                                className = className("mx-auto d-block p-2")
                            }
                            h4 {
                                className = className("text-center text-white n-usp")
                                +viewModel.texts.footerPickup
                            }
                        }
                    }
                    bCol {
                        className = className("p-0")
                        xs = 3; md = 3
                        div {

                            img {
                                src = "svg/ico_klarna.svg"
                                alt = viewModel.texts.footerKlarna
                                className = className("mx-auto d-block p-2")

                            }
                            h4 {
                                className = className("text-center text-white n-usp")
                                +viewModel.texts.footerKlarna
                            }
                        }
                    }
                    bNavItem {
                        className = className("mx-auto")
                        NavLink {
                            to = "/terms"
                            className = className("menu")
                            +viewModel.texts.footerTerms
                        }
                    }
                }
            }


        }
    }

}

@ExperimentalTime
fun ChildrenBuilder.footerComponent() = FOOTER_COMPONENT() {}

