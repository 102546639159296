package screens


import support.*
import techla.base.*
import techla.order.Article
import techla.order.Order
import techla.order.OrderItem
import kotlin.math.roundToInt
import kotlin.time.ExperimentalTime

val Article.min
    get() =
        tags.filterIsInstance<Article.Tag.Min>().map { it }

val Article.max
    get() =
        tags.filterIsInstance<Article.Tag.Max>().map { it }

val Article.isRectangle
    get() =
        key.rawValue == "RECTANGLE"

val Article.isSquare
    get() =
        key.rawValue == "SQUARE"

val Article.isCircle
    get() =
        key.rawValue == "CIRCLE"

val Article.isArch
    get() =
        key.rawValue == "ARCH"

val Article.Unit.squareMeters
    get() =
        when (this) {
            is Article.Unit.Area -> squareMeters
            else -> null
        }

fun getDimensionsTag(tags: List<Article.Tag>, rawValue: String) =
    tags.firstOrNull {
        it.key?.rawValue == rawValue
    }?.let { it as Article.Tag.Dimensions }


fun getMinTag(tags: List<Article.Tag>, rawValue: String) =
    tags.firstOrNull {
        it.key?.rawValue == rawValue
    }?.let { it as Article.Tag.Min }?.unit?.squareMeters?.times(1000)?.roundToInt()


fun getMaxTag(tags: List<Article.Tag>, rawValue: String) =
    tags.firstOrNull {
        it.key?.rawValue == rawValue
    }?.let { it as Article.Tag.Max }?.unit?.squareMeters?.times(1000)?.roundToInt()

@ExperimentalTime
object DesignScreen {
    data class Texts(
        val title: String,
        val shapeTitle: String,
        val height: String,
        val width: String,
        val buttonTitle: String,
        val vat: String,
        val unit: String,
        val cost: String,
        val errorMessage: String,
        val buttonGoBack: String,
        val startCost: String,
        val mirrorInfo: String,
        val disclaimer: String,
    )

    data class State(
        val usp: Boolean,
        val order: Order?,
        val orderItem: OrderItem?,
        val width: Double,
        val height: Double,
        val selectedShape: Shape?,
    ) {
        // We always report "uncut" sizes, i.e. as rectangles
        val uncutSquareMeters
            get() =
                width * height
    }

    data class DesignOrder(
        val article: Article?,
        val unit: Int,
        val cost: String,
    )

    data class Shape(
        val name: String,
        val selected: Boolean,
        val article: Article,
        val imgLink: String,
        val widthAlwaysEqualToHeight: Boolean,
    )

    data class Slider(
        val value: Double,
        val minValue: Double,
        val maxValue: Double,
        val title: String,
        val step: Int,
    )

    sealed class ViewModel(val texts: Texts, val state: State) {
        object None : ViewModel(
            Texts("", "", "", "", "", "", "", "", "", "", "", "", ""),
            State(false, null, null, 0.0, 0.0, null)
        )

        class Started(texts: Texts, state: State) : ViewModel(texts, state)

        class Design(
            texts: Texts,
            state: State,
            val shapes: List<Shape>,
            val width: Slider,
            val height: Slider,
            val designOrder: DesignOrder,
            val disclaimer: String
        ) : ViewModel(texts, state)

        class Failed(
            texts: Texts,
            state: State,
            val message: String
        ) : ViewModel(texts, state)

        fun none() = Started(texts = texts, state = state)

        fun started() = Started(texts = texts, state = state)

        fun design(
            texts: Texts,
            state: State,
            shapes: List<Shape>,
            width: Slider,
            height: Slider,
            designOrder: DesignOrder,
            disclaimer: String,
        ) =
            Design(
                texts = texts,
                state = state,
                shapes = shapes,
                width = width,
                height = height,
                designOrder = designOrder,
                disclaimer = disclaimer
            )


        fun failed(message: String) =
            Failed(texts = texts, state = state, message = message)

        fun failed(result: Either<List<Warning>, Throwable>) =
            failed(
                message = when (result) {
                    is Either.Left -> "(${result.value.joinToString(", ") { it.message }})"
                    is Either.Right -> "(${result.value.message})"
                }
            )
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf<ViewModel>(viewModel.failed(result))

    private fun Scene.Input<ViewModel>.restart() =
        sceneOf<ViewModel>(viewModel.none())

    suspend fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return sceneOf(viewModel.started())
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val texts = Texts(
            title = store.get(media = Key("screen:design"), content = Key("title")),
            shapeTitle = store.get(media = Key("screen:design"), content = Key("shapeTitle")),
            height = store.get(media = Key("screen:design"), content = Key("height")),
            width = store.get(media = Key("screen:design"), content = Key("width")),
            buttonTitle = store.get(media = Key("screen:design"), content = Key("buttonTitle")),
            vat = store.get(media = Key("screen:design"), content = Key("vat")),
            unit = store.get(media = Key("screen:design"), content = Key("unit")),
            cost = store.get(media = Key("screen:design"), content = Key("cost")),
            errorMessage = store.get(media = Key("screen:design"), content = Key("errorMessage")),
            buttonGoBack = store.get(media = Key("screen:design"), content = Key("buttonGoBack")),
            startCost = store.get(media = Key("screen:design"), content = Key("startCost")),
            mirrorInfo = store.get(media = Key("screen:design"), content = Key("mirrorInfo")),
            disclaimer = store.get(media = Key("screen:design"), content = Key("disclaimer")),
        )

        // Set up initial values
        val initialState = viewModel.state.copy(
            width = 0.3,
            height = 0.8,
            selectedShape = shapeList(store.articles, null).firstOrNull(),
        )
        // Create order, then order item
        return successfulOf(Unit)
            .flatMap { createTemporaryOrder(store, initialState) }
            .flatMap { (order, orderItem) ->
                // Update state to save the values
                val updatedState = initialState.copy(
                    order = order,
                    orderItem = orderItem,
                )
                dataLayerViewContent(orderItem)
                // Refresh the design order and return a new view model
                refreshDesignOrder(store = store, state = updatedState)
                    .map { (designOrder) ->
                        createShapes(
                            store.articles,
                            texts = texts,
                            state = updatedState,
                            designOrder = designOrder,
                            viewModel = viewModel
                        )
                    }
            }
            .fold(
                onSuccess = { it },
                onNotSuccess = { techla_log("6 $it"); scene.failed(result = it) }
            )
    }

    suspend fun selectShape(scene: Scene.Input<ViewModel>, shape: Shape): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val showUsp = shape.article.key == Key<Article>("ARCH")

        techla_log("$showUsp")
        // Update state with new values
        val state = viewModel.state.copy(
            usp = showUsp,
            selectedShape = shape,
            width = viewModel.state.width,
            height = if (shape.widthAlwaysEqualToHeight) viewModel.state.width else viewModel.state.height,
        )

        // Refresh the design order and return a new view model
        return refreshDesignOrder(store = store, state = state)
            .map { (designOrder, orderItem) ->
                // Note that we ignore the updated state above intentionally
                val newState = viewModel.state.copy(
                    orderItem = orderItem,
                    selectedShape = shape,
                )
                dataLayerViewContent(orderItem)
                createShapes(
                    store.articles,
                    texts = viewModel.texts,
                    state = newState,
                    designOrder = designOrder,
                    viewModel = viewModel
                )
            }
            .fold(
                onSuccess = { it },
                onNotSuccess = { scene.failed(result = it) }
            )
    }


    suspend fun changeDimensions(
        scene: Scene.Input<ViewModel>,
        width: Double,
        height: Double
    ): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val shape = viewModel.state.selectedShape ?: return scene.failed(rightOf(TechlaError.PreconditionFailed("Missing selected shape")))
        val formattedWidth = format(width * 0.001).replaceFirst(",", ".").toDouble()
        val formattedHeight = format(height * 0.001).replaceFirst(",", ".").toDouble()

        // Update state with new values
        val state = viewModel.state.copy(
            width = formattedWidth,
            height = if (shape.widthAlwaysEqualToHeight) formattedWidth else formattedHeight
        )

        // Refresh the design order and return a new view model
        return refreshDesignOrder(store = store, state = state)
            .map { (designOrder, orderItem) ->
                val newState = viewModel.state.copy(
                    orderItem = orderItem,
                    width = formattedWidth,
                    height = formattedHeight,
                )
                createShapes(
                    store.articles,
                    texts = viewModel.texts,
                    state = newState,
                    designOrder = designOrder,
                    viewModel = viewModel
                )
            }
            .fold(
                onSuccess = { it },
                onNotSuccess = { scene.restart() }
            )

    }

    private suspend fun createTemporaryOrder(store: Store, state: State) =
        store.orderAPI.createOrder(
            Order.Create(
                name = "Design",
                status = Order.Status.Temporary,
                rounding = Order.Rounding.Fixed(0),
                tags =
                listOf(
                    Order.Tag.Manufacturer(name = "", key = Key("WORKSHOPID"))

                )
            )
        )
            .flatMap { order ->
                val article = state.selectedShape?.article?.key
                    ?: return@flatMap failedOf(TechlaError.NotFound("Missing selected shape"))
                store.orderAPI.createOrderItem(
                    create = OrderItem.Create(
                        orderId = order.id,
                        article = article,
                        unit = Article.Unit.Area(format(state.uncutSquareMeters).replaceFirst(",", ".").toDouble()),
                        tags = listOf(
                            OrderItem.Tag.Dimensions(width = state.width, height = state.height, key = Key("Storlek"))
                        )
                    )
                ).map { orderItem ->
                    Pair(order, orderItem)
                }
            }

    private suspend fun refreshDesignOrder(store: Store, state: State) =
        store.orderAPI.editOrderItem(
            // TODO: Better error handling
            id = state.orderItem?.id!!,
            edit = OrderItem.Edit(
                article = modifiedOf(state.selectedShape?.article?.key!!),
                unit = modifiedOf(Article.Unit.Area(format(state.uncutSquareMeters).replaceFirst(",", ".").toDouble())),
                tags = modifiedOf(
                    listOf(
                        OrderItem.Tag.Dimensions(width = state.width, height = state.height, key = Key("Storlek"))
                    )
                )
            )
        )
            .map { orderItem ->
                val cost = (orderItem.order?.total as? Order.Total.Price)?.amount?.amount ?: 0.0
                Pair(
                    DesignOrder(
                        article = null,
                        unit = 1,
                        cost = cost.toString()
                    ), orderItem
                )

            }


    private fun createShapes(
        activeArticles: List<Article>,
        texts: Texts,
        state: State,
        designOrder: DesignOrder,
        viewModel: ViewModel,
        actions: List<Store.Action> = emptyList()
    ): Scene.Output<ViewModel.Design> {
        val selectedArticle = state.selectedShape?.article
        val shapes = shapeList(activeArticles, selectedArticle)

        val maxWidthSlider = selectedArticle?.let { getDimensionsTag(it.tags, "SLIDER_MAX") }?.width?.times(10) ?: 0.0
        val maxHeightSlider = selectedArticle?.let { getDimensionsTag(it.tags, "SLIDER_MAX") }?.height?.times(10) ?: 0.0

        val minWidthSlider = selectedArticle?.let { getDimensionsTag(it.tags, "SLIDER_MIN") }?.width?.times(10) ?: 0.0
        val minHeightSlider = selectedArticle?.let { getDimensionsTag(it.tags, "SLIDER_MIN") }?.height?.times(10) ?: 0.0

        val height = Slider(state.height, minHeightSlider, maxHeightSlider, texts.height, 1)
        val width = Slider(state.width, minWidthSlider, maxWidthSlider, texts.width, 1)

        return sceneOf(
            viewModel.design(
                texts = texts,
                state = state,
                shapes = shapes,
                width = width,
                height = height,
                designOrder = designOrder,
                disclaimer = texts.disclaimer
            ),
            actions
        )
    }

    private fun shapeList(activeArticles: List<Article>, selected: Article?) =
        activeArticles
            .filter { it.kind is Article.Kind.None }
            .map {
                Shape(
                    name = it.specification,
                    selected = it == selected,
                    article = it,
                    imgLink = when (it.key) {
                        Key<Article>("RECTANGLE") -> "svg/ico_rect.svg"
                        Key<Article>("SQUARE") -> "svg/ico_square.svg"
                        Key<Article>("CIRCLE") -> "svg/ico_circle.svg"
                        Key<Article>("ARCH") -> "svg/ico_arch.svg"
                        else -> "svg/ico_triangle.svg"
                    },
                    widthAlwaysEqualToHeight = when (it.key) {
                        Key<Article>("RECTANGLE") -> false
                        Key<Article>("SQUARE") -> true
                        Key<Article>("CIRCLE") -> true
                        Key<Article>("ARCH") -> false
                        else -> false
                    },
                )
            }

    private fun dataLayerViewContent(orderItem: OrderItem) {
        Ecommerce.ga3ViewContent(orderItem)
        Ecommerce.ga4ViewContent(orderItem)
    }
}