package bootstrap

import csstype.ClassName
import org.w3c.dom.events.Event
import react.*

@JsModule("react-bootstrap/Tabs")
private external val tabsModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bTabs: ComponentType<TabsProps> = tabsModule.default

external interface TabsProps : PropsWithChildren {
    var defaultActiveKey: String
    var activeKey: String
    var variant: String
    var className: ClassName

    var onSelect: ((event: Event) -> Unit)
}
