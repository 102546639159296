package components

import csstype.*
import kotlinx.js.jso
import react.*
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.tbody
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.tr
import react.dom.html.TdAlign
import react.dom.html.TdVAlign
import react.dom.html.ThAlign
import screens.*
import support.className
import techla.base.Key
import techla.order.Article
import techla.order.OrderItem
import kotlin.math.roundToInt
import kotlin.time.ExperimentalTime


inline val Number.percent: Width
    get() = "${this}%".unsafeCast<Width>()

inline val Number.maxPercent: MaxWidth
    get() = "${this}%".unsafeCast<MaxWidth>()

inline val String.textTransform: TextTransform
    get() = unsafeCast<TextTransform>()

inline val String.textDecoration: TextDecoration
    get() = unsafeCast<TextDecoration>()

inline val String.fontFamily: FontFamily
    get() = unsafeCast<FontFamily>()

@ExperimentalTime
external interface EmailProps : PropsWithChildren {
    var viewModel: PaymentScreen.ViewModel.PaymentSuccessful
    var orderItemPrice: ((OrderItem) -> String)
}
/*
val logoorange = kotlinext.js.require("img/ufs_logo_orange.png").default as String
val headerimg = kotlinext.js.require("img/co2_header.jpg").default as String
val icocar = kotlinext.js.require("img/ico_car.png").default as String
val redimg = kotlinext.js.require("img/red.gif").default as String
val logoblack = kotlinext.js.require("img/ufs_logo_black.png").default as String

exempel:
 img(src = logoorange) {
 */


@ExperimentalTime
fun ChildrenBuilder.emailTable(
    border: Length? = null,
    width: Width? = null,
    cellPadding: Length? = null,
    cellSpacing: Length? = null,
    alignContent: AlignContent? = null,
    borderSpacing: Length? = null,
    borderCollapse: BorderCollapse? = null,
    background: Color? = null,
    backgroundColor: Color? = null,
    fontFamily: FontFamily? = null,
    fontSize: Length? = null,
    fontStyle: FontStyle? = null,
    fontVariantCaps: FontVariantCaps? = null,
    fontWeight: FontWeight? = null,
    letterSpacing: LetterSpacing? = null,
    textAlign: TextAlign? = null,
    textIndent: Length? = null,
    textTransform: TextTransform? = null,
    whiteSpace: WhiteSpace? = null,
    wordSpacing: Length? = null,
    textDecoration: TextDecoration? = null,
    maxWidth: MaxWidth? = null,
    borderStyle: LineStyle? = null,
    borderWidth: Length? = null,
    block: ChildrenBuilder.() -> Unit
) {
    table {
        style = jso {
            border?.let { this.border = it }
            width?.let { this.width = it }
            maxWidth?.let { this.maxWidth = it }
            borderSpacing?.let { this.borderSpacing = it }
            borderCollapse?.let { this.borderCollapse = it }
            alignContent?.let { this.alignContent = it }
            background?.let { this.background = it }
            backgroundColor?.let { this.backgroundColor = it }
            fontFamily?.let { this.fontFamily = it }
            fontSize?.let { this.fontSize = it }
            fontStyle?.let { this.fontStyle = it }
            fontVariantCaps?.let { this.fontVariantCaps = it }
            fontWeight?.let { this.fontWeight = it }
            letterSpacing?.let { this.letterSpacing = it }
            textAlign?.let { this.textAlign = it }
            textIndent?.let { this.textIndent = it }
            textTransform?.let { this.textTransform = it }
            whiteSpace?.let { this.whiteSpace = it }
            wordSpacing?.let { this.wordSpacing = it }
            textDecoration?.let { this.textDecoration = it }
            borderStyle?.let { this.borderStyle = it }
            borderWidth?.let { this.borderWidth = it }
        }
        cellPadding?.let { this.cellPadding = it.toString() }
        cellSpacing?.let { this.cellSpacing = it.toString() }

        block()
    }
}

fun ChildrenBuilder.emailTh(
    align: ThAlign? = null,
    valign: TdVAlign? = null, // TODO ******
    dir: String? = null,
    backgroundColor: Color? = null,
    color: Color? = null,
    display: Display? = null,
    width: Width? = null,
    borderCollapse: BorderCollapse? = null,
    padding: Length? = null,
    fontWeight: FontWeight? = null,
    fontSize: Length? = null,
    fontFamily: FontFamily? = null,
    borderTopLeftRadius: Length? = null,
    borderTopRightRadius: Length? = null,
    borderBottomLeftRadius: Length? = null,
    borderBottomRightRadius: Length? = null,
    border: Length? = null,
    background: Color? = null,
    colSpan: Int? = null,

    block: ChildrenBuilder.() -> Unit
) {
    th {
        style = jso {
            borderCollapse?.let { this.borderCollapse = it }
            padding?.let { this.padding = it }
            borderTopLeftRadius?.let { this.borderTopLeftRadius = it }
            borderTopRightRadius?.let { this.borderTopRightRadius = it }
            borderTopRightRadius?.let { this.borderTopRightRadius = it }
            borderBottomLeftRadius?.let { this.borderBottomLeftRadius = it }
            borderBottomRightRadius?.let { this.borderBottomRightRadius = it }
            color?.let { this.color = it }
            backgroundColor?.let { this.backgroundColor = it }
            width?.let { this.width = it }
            fontWeight?.let { this.fontWeight = it }
            fontSize?.let { this.fontSize = it }
            display?.let { this.display = it }
            fontFamily?.let { this.fontFamily = it }
            border?.let { this.border = it }
            background?.let { this.background = it }
        }
        align?.let { this.align = it }
       // valign?.let { this.valign = it }
        dir?.let { this.dir = it }
        colSpan?.let { this.colSpan = it }

        block()

    }
}


fun ChildrenBuilder.ahref(
    target: AnchorTarget? = null,
    href: String? = null,
    color: Color? = null,

    block: ChildrenBuilder.() -> Unit
) {
    a {
        style = jso {
            color?.let { this.color = it }
        }
        target?.let { this.target = it }
        href?.let { this.href = it }

        block()
    }
}


fun ChildrenBuilder.emailTd(
    align: TdAlign? = null,
    valign: TdVAlign? = null,
    dir: String? = null,
    colSpan: Int? = null,
    backgroundColor: Color? = null,
    background: Color? = null,
    display: Display? = null,
    borderCollapse: BorderCollapse? = null,
    padding: Length? = null,
    paddingLeft: Length? = null,
    paddingRight: Length? = null,
    paddingTop: Length? = null,
    paddingBottom: Length? = null,
    borderTopWidth: Length? = null,
    borderTopStyle: LineStyle? = null,
    borderTopColor: Color? = null,
    width: Width? = null,
    maxHeight: Length? = null,
    overflow: Overflow? = null,
    float: Float? = null,
    fontFamily: FontFamily? = null,
    lineHeight: Length? = null,
    fontWeight: FontWeight? = null,
    fontSize: Length? = null,
    color: Color? = null,
    whiteSpace: WhiteSpace? = null,
    textTransform: TextTransform? = null,
    height: Height? = null,

    block: ChildrenBuilder.() -> Unit
) {
    td {
        style = jso {
            borderCollapse?.let { this.borderCollapse = it }
            padding?.let { this.padding = it }
            paddingLeft?.let { this.paddingLeft = it }
            paddingRight?.let { this.paddingRight = it }
            paddingTop?.let { this.paddingTop = it }
            paddingBottom?.let { this.paddingBottom = it }
            borderTopWidth?.let { this.borderTopWidth = it }
            borderTopStyle?.let { this.borderTopStyle = it }
            borderTopColor?.let { this.borderTopColor = it }
            color?.let { this.color = it }
            backgroundColor?.let { this.backgroundColor = it }
            background?.let { this.background = it }
            width?.let { this.width = it }
            maxHeight?.let { this.maxHeight = it }
            lineHeight?.let { this.lineHeight = it }
            fontWeight?.let { this.fontWeight = it }
            fontSize?.let { this.fontSize = it }
            whiteSpace?.let { this.whiteSpace = it }
            display?.let { this.display = it }
            float?.let { this.float = it }
            overflow?.let { this.overflow = it }
            fontFamily?.let { this.fontFamily = it }
            textTransform?.let { this.textTransform = it }
            height?.let { this.height = it }
        }

        align?.let { this.align = it }
        valign?.let { this.valign = it }
        dir?.let { this.dir = it }
        colSpan?.let { this.colSpan = it }

        block()
    }
}

@ExperimentalTime
val EMAIL = FC<EmailProps>("EMAIL") { props ->
    emailTable(
        border = 0.px,
        width = 100.percent,
        cellPadding = 0.px,
        cellSpacing = 0.px,
        background = rgb(0,170,136),
        borderSpacing = 0.px,
        borderCollapse = BorderCollapse.collapse,
        fontFamily = "Helvetica".fontFamily,
        fontSize = 12.px,
        fontStyle = FontStyle.normal,
        fontVariantCaps = FontVariantCaps.normal,
        fontWeight = FontWeight.normal,
        letterSpacing = LetterSpacing.normal,
        textAlign = TextAlign.start,
        textIndent = 0.px,
        textTransform = "none".textTransform,
        whiteSpace = WhiteSpace.normal,
        wordSpacing = 0.px,
        backgroundColor = rgb(255,255,255),
        textDecoration = "none".textDecoration

    ) {
        tbody {
            tr {
                emailTd(align = TdAlign.center, valign = TdVAlign.top, borderCollapse = BorderCollapse.collapse) {
                    emailTable(
                        border = 0.px,
                        width = 600.px,
                        cellPadding = 0.px,
                        cellSpacing = 0.px,
                        borderSpacing = 0.px,
                        borderCollapse = BorderCollapse.collapse,
                        maxWidth = 100.maxPercent
                    ) {
                        tbody {
                            tr {
                                emailTd(
                                    align = TdAlign.left,
                                    valign = TdVAlign.top,
                                    borderCollapse = BorderCollapse.collapse,
                                    paddingTop = 0.px,
                                    paddingBottom = 0.px,
                                    paddingLeft = 10.px,
                                    paddingRight = 10.px

                                ) {
                                    emailTable(
                                        border = 0.px,
                                        width = 100.percent,
                                        cellPadding = 0.px,
                                        cellSpacing = 0.px,
                                        borderSpacing = 0.px,
                                        borderCollapse = BorderCollapse.collapse
                                    ) {
                                        tbody {
                                            tr {
                                                emailTd(align = TdAlign.left, valign = TdVAlign.top, borderCollapse = BorderCollapse.collapse) {
                                                    emailTable(
                                                        border = 0.px,
                                                        width = 100.percent,
                                                        cellPadding = 0.px,
                                                        cellSpacing = 0.px,
                                                        borderSpacing = 0.px,
                                                        borderCollapse = BorderCollapse.collapse
                                                    ) {
                                                        tbody {
                                                            tr {
                                                                emailTd(
                                                                    align = TdAlign.left,
                                                                    valign = TdVAlign.middle,
                                                                    borderCollapse = BorderCollapse.collapse,
                                                                ) {
                                                                    img {
                                                                        src = "https://www.noboto.io/dev/speglar/speglar_email_logo.jpg"
                                                                        style = jso {
                                                                            width = 580.0.px
                                                                            border = 0.px
                                                                        }
                                                                    }
                                                                }
                                                                emailTd(
                                                                    align = TdAlign.right,
                                                                    valign = TdVAlign.middle,
                                                                    borderCollapse = BorderCollapse.collapse
                                                                ) {
                                                                    emailTable(
                                                                        border = 0.px,
                                                                        width = 100.percent,
                                                                        cellPadding = 0.px,
                                                                        cellSpacing = 0.px,
                                                                        borderSpacing = 0.px,
                                                                        borderCollapse =  BorderCollapse.collapse//"0"
                                                                    ) {
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


// Tack för din beställning
    emailTable(
        border = 0.px,
        width = 100.percent,
        cellPadding = 0.px,
        cellSpacing = 0.px,
        background = rgb(255,255,255),
        borderSpacing = 0.px,
        borderCollapse = BorderCollapse.collapse,
        fontFamily = "Helvetica".fontFamily,
        fontSize = 12.px,
        fontStyle = FontStyle.normal,
        fontVariantCaps = FontVariantCaps.normal,
        fontWeight = FontWeight.normal,
        letterSpacing = LetterSpacing.normal,
        textAlign = TextAlign.start,
        textIndent = 0.px,
        textTransform = "none".textTransform,
        whiteSpace = WhiteSpace.normal,
        wordSpacing = 0.px,
        backgroundColor = rgb(255,255,255),
        textDecoration = "none".textDecoration

    ) {
        tbody {
            tr {
                emailTd(align = TdAlign.center, valign = TdVAlign.top, borderCollapse = BorderCollapse.collapse) {
                    emailTable(
                        border = 0.px,
                        width = 600.px,
                        cellPadding = 0.px,
                        cellSpacing = 0.px,
                        borderSpacing = 0.px,
                        borderCollapse = BorderCollapse.collapse,
                        maxWidth = 100.maxPercent
                    ) {
                        tbody {
                            tr {
                                emailTd(
                                    align = TdAlign.left,
                                    valign = TdVAlign.top,
                                    borderCollapse = BorderCollapse.collapse,
                                    paddingTop = 0.px,
                                    paddingBottom = 0.px,
                                    paddingLeft = 10.px,
                                    paddingRight = 10.px

                                ) {
                                    emailTable(
                                        border = 0.px,
                                        width = 100.percent,
                                        cellPadding = 0.px,
                                        cellSpacing = 0.px,
                                        borderSpacing = 0.px,
                                        borderCollapse = BorderCollapse.collapse
                                    ) {
                                        tbody {
                                            tr {
                                                emailTh(
                                                    dir = "ltr",
                                                    valign = TdVAlign.top,
                                                    borderCollapse = BorderCollapse.collapse,
                                                    display = Display.block,
                                                    width = 580.px,
                                                    padding = 0.px,
                                                    fontWeight = FontWeight.normal
                                                ) {
                                                    emailTable(
                                                        border = 0.px,
                                                        width = 580.px,
                                                        cellPadding = 0.px,
                                                        cellSpacing = 0.px,
                                                        borderSpacing = 0.px,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        maxWidth = 100.maxPercent
                                                    ) {
                                                        tbody {
                                                            tr {
                                                                emailTd(
                                                                    align = TdAlign.left,
                                                                    valign = TdVAlign.top,
                                                                    borderCollapse = BorderCollapse.collapse,
                                                                    paddingLeft = 10.px,
                                                                    paddingRight = 10.px,
                                                                    paddingTop = 20.px
                                                                ) {
                                                                    emailTable(
                                                                        border = 0.px,
                                                                        width = 100.percent,
                                                                        cellPadding = 0.px,
                                                                        cellSpacing = 0.px,
                                                                        borderSpacing = 0.px,
                                                                        borderCollapse =  BorderCollapse.collapse//"0"
                                                                    ) {
                                                                        tbody {
                                                                            tr {
                                                                                emailTd(
                                                                                    align = TdAlign.center,
                                                                                    dir = "ltr",
                                                                                    valign = TdVAlign.top,
                                                                                    borderCollapse = BorderCollapse.collapse,
                                                                                    fontFamily = "Verdana".fontFamily,
                                                                                    lineHeight = 28.px,
                                                                                    fontSize = 28.px,
                                                                                    paddingBottom = 25.px,
                                                                                    paddingTop = 35.px
                                                                                ) {
                                                                                    +props.viewModel.emailText.thankYouMessage
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


// Dina upgifter ****** Ram
    emailTable(
        border = 0.px,
        width = 100.percent,
        cellPadding = 0.px,
        cellSpacing = 0.px,
        background = rgb(255,255,255),
        borderSpacing = 0.px,
        borderCollapse = BorderCollapse.collapse,
        fontFamily = "Helvetica".fontFamily,
        fontSize = 12.px,
        fontStyle = FontStyle.normal,
        fontVariantCaps = FontVariantCaps.normal,
        fontWeight = FontWeight.normal,
        letterSpacing = LetterSpacing.normal,
        textAlign = TextAlign.start,
        textIndent = 0.px,
        textTransform = "none".textTransform,
        whiteSpace = WhiteSpace.normal,
        wordSpacing = 0.px,
        backgroundColor = rgb(255,255,255),
        textDecoration = "none".textDecoration

    ) {
        tbody {
            tr {
                emailTd(align = TdAlign.center, valign = TdVAlign.top, borderCollapse = BorderCollapse.collapse) {
                    emailTable(
                        border = 0.px,
                        width = 580.px,
                        cellPadding = 0.px,
                        cellSpacing = 0.px,
                        borderSpacing = 0.px,
                        borderCollapse = BorderCollapse.collapse,
                        maxWidth = 100.maxPercent
                    ) {
                        tbody {
                            tr {
                                emailTd(
                                    align = TdAlign.left,
                                    valign = TdVAlign.top,
                                    borderCollapse = BorderCollapse.collapse,
                                    paddingLeft = 20.px,
                                    paddingRight = 20.px
                                ) {
                                    emailTable(
                                        border = 0.px,
                                        width = 100.percent,
                                        cellPadding = 0.px,
                                        cellSpacing = 0.px,
                                        borderSpacing = 0.px,
                                        borderCollapse = BorderCollapse.collapse,
                                        borderWidth = 0.px
                                    ) {

                                        tbody {
                                            tr {
                                                emailTd(
                                                    align = TdAlign.left,
                                                    colSpan = 2,
                                                    dir = "ltr",
                                                    valign = TdVAlign.top,
                                                    borderCollapse = BorderCollapse.collapse,
                                                    fontFamily = "Verdana".fontFamily,
                                                    lineHeight = 17.px,
                                                    fontSize = 20.px,
                                                    paddingLeft = 0.px,
                                                    paddingBottom = 20.px,
                                                    paddingTop = 20.px,
                                                    textTransform = "uppercase".textTransform,
                                                    fontWeight = FontWeight.normal
                                                ) {
                                                    +"Dina uppgifter"
                                                }
                                            }
                                            tr {
                                                emailTd(
                                                    align = TdAlign.left,
                                                    dir = "ltr",
                                                    valign = TdVAlign.top,
                                                    borderCollapse = BorderCollapse.collapse,
                                                    fontFamily = "Verdana".fontFamily,
                                                    lineHeight = 17.px,
                                                    fontSize = 15.px,
                                                    paddingLeft = 0.px,
                                                    paddingRight = 10.px,
                                                    paddingBottom = 10.px,
                                                    fontWeight = FontWeight.normal
                                                ) {
                                                    +props.viewModel.emailInformation.firstName
                                                }
                                                emailTd(
                                                    align = TdAlign.right,
                                                    dir = "ltr",
                                                    valign = TdVAlign.top,
                                                    borderCollapse = BorderCollapse.collapse,
                                                    fontFamily = "Verdana".fontFamily,
                                                    lineHeight = 17.px,
                                                    fontSize = 15.px,
                                                    paddingLeft = 10.px,
                                                    paddingRight = 0.px,
                                                    paddingBottom = 10.px
                                                ) {
                                                    +props.viewModel.emailInformation.lastName
                                                }
                                            }
                                            tr {
                                                emailTd(
                                                    align = TdAlign.left,
                                                    dir = "ltr",
                                                    valign = TdVAlign.top,
                                                    borderCollapse = BorderCollapse.collapse,
                                                    fontFamily = "Verdana".fontFamily,
                                                    lineHeight = 17.px,
                                                    fontSize = 15.px,
                                                    paddingLeft = 0.px,
                                                    paddingRight = 10.px,
                                                    paddingBottom = 10.px,
                                                    fontWeight = FontWeight.normal
                                                ) {
                                                    +props.viewModel.emailInformation.email
                                                }
                                                emailTd(
                                                    align = TdAlign.right,
                                                    dir = "ltr",
                                                    valign = TdVAlign.top,
                                                    borderCollapse = BorderCollapse.collapse,
                                                    fontFamily = "Verdana".fontFamily,
                                                    lineHeight = 17.px,
                                                    fontSize = 15.px,
                                                    paddingLeft = 10.px,
                                                    paddingRight = 0.px,
                                                    paddingBottom = 10.px
                                                ) {
                                                    +props.viewModel.emailInformation.phoneNumber
                                                }
                                            }
                                            if (props.viewModel.emailInformation.street != null) {
                                                tr {
                                                    emailTd(
                                                        align = TdAlign.left,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingLeft = 0.px,
                                                        paddingRight = 10.px,
                                                        paddingBottom = 10.px,
                                                        fontWeight = FontWeight.normal
                                                    ) {
                                                        +props.viewModel.emailInformation.street!!
                                                    }
                                                    emailTd(
                                                        align = TdAlign.right,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingLeft = 10.px,
                                                        paddingRight = 0.px,
                                                        paddingBottom = 10.px
                                                    ) {
                                                        props.viewModel.emailInformation.postalCode!!
                                                    }
                                                }
                                                tr {
                                                    emailTd(
                                                        align = TdAlign.left,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingLeft = 0.px,
                                                        paddingRight = 10.px,
                                                        paddingBottom = 10.px,
                                                        fontWeight = FontWeight.normal
                                                    ) {
                                                        +props.viewModel.emailInformation.city!!
                                                    }
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }

    emailTable(
        border = 0.px,
        width = 100.percent,
        cellPadding = 0.px,
        cellSpacing = 0.px,
        background = rgb(255,255,255),
        borderSpacing = 0.px,
        borderCollapse = BorderCollapse.collapse,
        fontFamily = "Helvetica".fontFamily,
        fontSize = 12.px,
        fontStyle = FontStyle.normal,
        fontVariantCaps = FontVariantCaps.normal,
        fontWeight = FontWeight.normal,
        letterSpacing = LetterSpacing.normal,
        textAlign = TextAlign.start,
        textIndent = 0.px,
        textTransform = "none".textTransform,
        whiteSpace = WhiteSpace.normal,
        wordSpacing = 0.px,
        backgroundColor = rgb(255,255,255),
        textDecoration = "none".textDecoration
    ) {
        tbody {
            tr {
                emailTd(align = TdAlign.center, valign = TdVAlign.top, borderCollapse = BorderCollapse.collapse) {
                    emailTable(
                        border = 0.px,
                        width = 580.px,
                        cellPadding = 0.px,
                        cellSpacing = 0.px,
                        borderSpacing = 0.px,
                        borderCollapse = BorderCollapse.collapse,
                        maxWidth = 100.maxPercent
                    ) {

                        tbody {
                            tr {
                                emailTd(
                                    align = TdAlign.left,
                                    valign = TdVAlign.top,
                                    borderCollapse = BorderCollapse.collapse,
                                    paddingLeft = 20.px,
                                    paddingRight = 20.px
                                ) {
                                    emailTable(
                                        border = 0.px,
                                        width = 100.percent,
                                        cellPadding = 0.px,
                                        cellSpacing = 0.px,
                                        borderSpacing = 0.px,
                                        borderCollapse = BorderCollapse.collapse,
                                        borderWidth = 0.px
                                    ) {


                                        tbody {
                                            tr {
                                                emailTd(
                                                    align = TdAlign.left,
                                                    dir = "ltr",
                                                    valign = TdVAlign.top,
                                                    borderCollapse = BorderCollapse.collapse,
                                                    fontFamily = "Verdana".fontFamily,
                                                    lineHeight = 17.px,
                                                    fontSize = 15.px,
                                                    paddingLeft = 0.px,
                                                    paddingTop = 25.px,
                                                    paddingBottom = 5.px
                                                ) {
                                                    +"Vald verkstad:"
                                                }
                                            }
                                            tr {
                                                emailTd(
                                                    align = TdAlign.left,
                                                    dir = "ltr",
                                                    valign = TdVAlign.top,
                                                    borderCollapse = BorderCollapse.collapse,
                                                    fontFamily = "Verdana".fontFamily,
                                                    lineHeight = 17.px,
                                                    fontSize = 20.px,
                                                    paddingLeft = 0.px,
                                                    paddingBottom = 35.px,
                                                    fontWeight = FontWeight.normal
                                                ) {
                                                    +props.viewModel.emailInformation.selectedCity
                                                }
                                            }

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


//Översikt
    emailTable(
        border = 0.px,
        width = 100.percent,
        cellPadding = 0.px,
        cellSpacing = 0.px,
        background = rgb(244,244,244),
        borderSpacing = 0.px,
        borderCollapse = BorderCollapse.collapse,
        fontFamily = "Helvetica".fontFamily,
        fontSize = 12.px,
        fontStyle = FontStyle.normal,
        fontVariantCaps = FontVariantCaps.normal,
        fontWeight = FontWeight.normal,
        letterSpacing = LetterSpacing.normal,
        textAlign = TextAlign.start,
        textIndent = 0.px,
        textTransform = "none".textTransform,
        whiteSpace = WhiteSpace.normal,
        wordSpacing = 0.px,
        backgroundColor = rgb(255,255,255),
        textDecoration = "none".textDecoration
    ) {
        tbody {
            tr {
                emailTd(align = TdAlign.center, valign = TdVAlign.top, borderCollapse = BorderCollapse.collapse) {
                    emailTable(
                        border = 0.px,
                        width = 580.px,
                        cellPadding = 0.px,
                        cellSpacing = 0.px,
                        borderSpacing = 0.px,
                        borderCollapse = BorderCollapse.collapse,
                        maxWidth = 100.maxPercent
                    ) {

                        tbody {
                            tr {
                                emailTd(
                                    align = TdAlign.left,
                                    valign = TdVAlign.top,
                                    borderCollapse = BorderCollapse.collapse,
                                    paddingLeft = 20.px,
                                    paddingRight = 20.px
                                ) {
                                    emailTable(
                                        border = 0.px,
                                        width = 100.percent,
                                        cellPadding = 0.px,
                                        cellSpacing = 0.px,
                                        borderSpacing = 0.px,
                                        borderCollapse = BorderCollapse.collapse,
                                        borderWidth = 0.px
                                    ) {

                                        tbody {
                                            tr {
                                                emailTd(
                                                    colSpan = 2,
                                                    align = TdAlign.center,
                                                    dir = "ltr",
                                                    valign = TdVAlign.top,
                                                    borderCollapse = BorderCollapse.collapse,
                                                    fontFamily = "Verdana".fontFamily,
                                                    lineHeight = 17.px,
                                                    fontSize = 18.px,
                                                    paddingTop = 35.px,
                                                    paddingBottom = 15.px,
                                                    fontWeight = FontWeight.normal,
                                                    backgroundColor = rgb(244, 244, 244)
                                                ) {
                                                    +props.viewModel.emailText.titleOverview
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

//Article.kind.none
    props.viewModel.emailInformation.orderItems?.filter { it.article?.kind == Article.Kind.None }
        ?.forEach { orderItem ->
            emailTable(
                border = 0.px,
                width = 100.percent,
                cellPadding = 0.px,
                cellSpacing = 0.px,
                background = rgb(244,244,244),
                borderSpacing = 0.px,
                borderCollapse = BorderCollapse.collapse,
                fontFamily = "Helvetica".fontFamily,
                fontSize = 12.px,
                fontStyle = FontStyle.normal,
                fontVariantCaps = FontVariantCaps.normal,
                fontWeight = FontWeight.normal,
                letterSpacing = LetterSpacing.normal,
                textAlign = TextAlign.start,
                textIndent = 0.px,
                textTransform = "none".textTransform,
                whiteSpace = WhiteSpace.normal,
                wordSpacing = 0.px,
                backgroundColor = rgb(255,255,255),
                textDecoration = "none".textDecoration
            ) {
                tbody {
                    tr {
                        emailTd(align = TdAlign.center, valign = TdVAlign.top, borderCollapse = BorderCollapse.collapse) {
                            emailTable(
                                border = 0.px,
                                width = 580.px,
                                cellPadding = 0.px,
                                cellSpacing = 0.px,
                                borderSpacing = 0.px,
                                borderCollapse = BorderCollapse.collapse,
                                maxWidth = 100.maxPercent
                            ) {
                                tbody {
                                    tr {
                                        emailTd(
                                            align = TdAlign.left,
                                            valign = TdVAlign.top,
                                            borderCollapse = BorderCollapse.collapse,
                                            paddingLeft = 20.px,
                                            paddingRight = 20.px
                                        ) {
                                            emailTable(
                                                border = 0.px,
                                                width = 100.percent,
                                                cellPadding = 0.px,
                                                cellSpacing = 0.px,
                                                borderSpacing = 0.px,
                                                borderCollapse = BorderCollapse.collapse,
                                                borderWidth = 0.px
                                            ) {


                                                tbody {
                                                    tr {
                                                        emailTd(
                                                            align = TdAlign.left,
                                                            dir = "ltr",
                                                            valign = TdVAlign.top,
                                                            borderCollapse = BorderCollapse.collapse,
                                                            fontFamily = "Verdana".fontFamily,
                                                            lineHeight = 17.px,
                                                            fontSize = 15.px,
                                                            paddingLeft = 30.px,
                                                            paddingRight = 10.px,
                                                            textTransform = "Uppercase".textTransform,
                                                            paddingTop = 5.px,
                                                            backgroundColor = rgb(244, 244, 244),
                                                        ) {
                                                            if (orderItem.article?.publicImage != "") {
                                                                img {
                                                                    alt = orderItem.article?.specification
                                                                    src = orderItem.article?.publicImage
                                                                    className = className("n-cart-img")
                                                                    style = jso {
                                                                        width = 40.0.px
                                                                        border = 0.px
                                                                    }
                                                                }
                                                            }
                                                            +" ${orderItem.article?.specification}"
                                                        }
                                                        emailTd(
                                                            align = TdAlign.right,
                                                            dir = "ltr",
                                                            valign = TdVAlign.top,
                                                            borderCollapse = BorderCollapse.collapse,
                                                            fontFamily = "Verdana".fontFamily,
                                                            lineHeight = 17.px,
                                                            fontSize = 15.px,
                                                            paddingRight = 30.px,
                                                            paddingTop = 10.px,
                                                            paddingBottom = 5.px,
                                                            backgroundColor = rgb(244, 244, 244),
                                                        ) {
                                                            +"${(orderItem.total as? techla.order.Order.Total.Price)?.amount?.amount!!}:-"
                                                        }
                                                    }
                                                    tr {
                                                        emailTd(
                                                            align = TdAlign.left,
                                                            colSpan = 2,
                                                            dir = "ltr",
                                                            valign = TdVAlign.top,
                                                            borderCollapse = BorderCollapse.collapse,
                                                            fontFamily = "Verdana".fontFamily,
                                                            lineHeight = 17.px,
                                                            fontSize = 15.px,
                                                            paddingLeft = 30.px,
                                                            paddingRight = 10.px,
                                                            paddingTop = 10.px,
                                                            paddingBottom = 10.px,
                                                            fontWeight = FontWeight.normal,
                                                            backgroundColor = rgb(244, 244, 244),
                                                        ) {
                                                            if (orderItem.article?.kind == Article.Kind.None) {

                                                                +"Bredd ${
                                                                    (orderItem.dimensions)?.width?.times(
                                                                        1000
                                                                    )?.roundToInt()
                                                                } mm, Höjd: "
                                                                +"${
                                                                    (orderItem.dimensions)?.height?.times(
                                                                        1000
                                                                    )?.roundToInt()
                                                                } mm"
                                                            } else if (orderItem.article?.key == Key<Article>(
                                                                    "ADDITIONALINSTALLATION"
                                                                ) || orderItem.article?.key == Key<Article>(
                                                                    "STARTUPCOST"
                                                                )
                                                            ) {
                                                                +"${orderItem.quantity} st"
                                                            } else if (orderItem.article?.key == Key<Article>(
                                                                    "INSTALLATION"
                                                                )
                                                            ) {
                                                                +"Du blir uppringd för tidsbokning"
                                                            }
                                                        }
                                                    }
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    //Article - MONTAGE
    props.viewModel.emailInformation.orderItems?.filter { it.article?.category?.key == Key<Article>("MONTAGE") }
        ?.forEach { orderItem ->
            emailTable(
                border = 0.px,
                width = 100.percent,
                cellPadding = 0.px,
                cellSpacing = 0.px,
                background = rgb(244,244,244),
                borderSpacing = 0.px,
                borderCollapse = BorderCollapse.collapse,
                fontFamily = "Helvetica".fontFamily,
                fontSize = 12.px,
                fontStyle = FontStyle.normal,
                fontVariantCaps = FontVariantCaps.normal,
                fontWeight = FontWeight.normal,
                letterSpacing = LetterSpacing.normal,
                textAlign = TextAlign.start,
                textIndent = 0.px,
                textTransform = "none".textTransform,
                whiteSpace = WhiteSpace.normal,
                wordSpacing = 0.px,
                backgroundColor = rgb(255,255,255),
                textDecoration = "none".textDecoration
            ) {
                tbody {
                    tr {
                        emailTd(align = TdAlign.center, valign = TdVAlign.top, borderCollapse = BorderCollapse.collapse) {
                            emailTable(
                                border = 0.px,
                                width = 580.px,
                                cellPadding = 0.px,
                                cellSpacing = 0.px,
                                borderSpacing = 0.px,
                                borderCollapse = BorderCollapse.collapse,
                                maxWidth = 100.maxPercent
                            ) {
                                tbody {
                                    tr {
                                        emailTd(
                                            align = TdAlign.left,
                                            valign = TdVAlign.top,
                                            borderCollapse = BorderCollapse.collapse,
                                            paddingLeft = 20.px,
                                            paddingRight = 20.px
                                        ) {
                                            emailTable(
                                                border = 0.px,
                                                width = 100.percent,
                                                cellPadding = 0.px,
                                                cellSpacing = 0.px,
                                                borderSpacing = 0.px,
                                                borderCollapse = BorderCollapse.collapse,
                                                borderWidth = 0.px
                                            ) {


                                                tbody {
                                                    tr {
                                                        emailTd(
                                                            align = TdAlign.left,
                                                            dir = "ltr",
                                                            valign = TdVAlign.top,
                                                            borderCollapse = BorderCollapse.collapse,
                                                            fontFamily = "Verdana".fontFamily,
                                                            lineHeight = 17.px,
                                                            fontSize = 15.px,
                                                            paddingLeft = 30.px,
                                                            paddingRight = 10.px,
                                                            textTransform = "Uppercase".textTransform,
                                                            paddingTop = 5.px,
                                                            backgroundColor = rgb(244, 244, 244),
                                                        ) {
                                                            if (orderItem.article?.publicImage != "") {
                                                                img {
                                                                    alt = orderItem.article?.specification
                                                                    src = orderItem.article?.publicImage
                                                                    className = className("n-cart-img")
                                                                    style = jso {
                                                                        width = 40.0.px
                                                                        border = 0.px
                                                                    }
                                                                }
                                                            }
                                                            +" ${orderItem.article?.specification}"
                                                        }
                                                        emailTd(
                                                            align = TdAlign.right,
                                                            dir = "ltr",
                                                            valign = TdVAlign.top,
                                                            borderCollapse = BorderCollapse.collapse,
                                                            fontFamily = "Verdana".fontFamily,
                                                            lineHeight = 17.px,
                                                            fontSize = 15.px,
                                                            paddingRight = 30.px,
                                                            paddingTop = 10.px,
                                                            paddingBottom = 5.px,
                                                            backgroundColor = rgb(244, 244, 244),
                                                        ) {
                                                            +"${(orderItem.total as? techla.order.Order.Total.Price)?.amount?.amount!!}:-"
                                                        }
                                                    }
                                                    tr {
                                                        emailTd(
                                                            align = TdAlign.left,
                                                            colSpan = 2,
                                                            dir = "ltr",
                                                            valign = TdVAlign.top,
                                                            borderCollapse = BorderCollapse.collapse,
                                                            fontFamily = "Verdana".fontFamily,
                                                            lineHeight = 17.px,
                                                            fontSize = 15.px,
                                                            paddingLeft = 30.px,
                                                            paddingRight = 10.px,
                                                            paddingTop = 10.px,
                                                            paddingBottom = 10.px,
                                                            fontWeight = FontWeight.normal,
                                                            backgroundColor = rgb(244, 244, 244),
                                                        ) {
                                                            if (orderItem.article?.kind == Article.Kind.None) {

                                                                +"${
                                                                    (orderItem.dimensions)?.height?.times(
                                                                        1000
                                                                    )?.roundToInt()
                                                                } mm x "
                                                                +"${
                                                                    (orderItem.dimensions)?.width?.times(
                                                                        1000
                                                                    )?.roundToInt()
                                                                } mm"
                                                            } else if (orderItem.article?.key == Key<Article>(
                                                                    "ADDITIONALINSTALLATION"
                                                                ) || orderItem.article?.key == Key<Article>(
                                                                    "STARTUPCOST"
                                                                )
                                                            ) {
                                                                +"${orderItem.quantity} st"
                                                            } else if (orderItem.article?.key == Key<Article>(
                                                                    "INSTALLATION"
                                                                )
                                                            ) {
                                                                +"Du blir uppringd för tidsbokning"
                                                            }
                                                        }
                                                    }
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    //Article - INSTALLATION
    props.viewModel.emailInformation.orderItems?.filter {
        it.article?.key == Key<Article>(
            "INSTALLATION"
        )
    }?.forEach { orderItem ->
        emailTable(
            border = 0.px,
            width = 100.percent,
            cellPadding = 0.px,
            cellSpacing = 0.px,
            background = rgb(244,244,244),
            borderSpacing = 0.px,
            borderCollapse = BorderCollapse.collapse,
            fontFamily = "Helvetica".fontFamily,
            fontSize = 12.px,
            fontStyle = FontStyle.normal,
            fontVariantCaps = FontVariantCaps.normal,
            fontWeight = FontWeight.normal,
            letterSpacing = LetterSpacing.normal,
            textAlign = TextAlign.start,
            textIndent = 0.px,
            textTransform = "none".textTransform,
            whiteSpace = WhiteSpace.normal,
            wordSpacing = 0.px,
            backgroundColor = rgb(255,255,255),
            textDecoration = "none".textDecoration
        ) {
            tbody {
                tr {
                    emailTd(align = TdAlign.center, valign = TdVAlign.top, borderCollapse = BorderCollapse.collapse) {
                        emailTable(
                            border = 0.px,
                            width = 580.px,
                            cellPadding = 0.px,
                            cellSpacing = 0.px,
                            borderSpacing = 0.px,
                            borderCollapse = BorderCollapse.collapse,
                            maxWidth = 100.maxPercent
                        ) {
                            tbody {
                                tr {
                                    emailTd(
                                        align = TdAlign.left,
                                        valign = TdVAlign.top,
                                        borderCollapse = BorderCollapse.collapse,
                                        paddingLeft = 20.px,
                                        paddingRight = 20.px
                                    ) {
                                        emailTable(
                                            border = 0.px,
                                            width = 100.percent,
                                            cellPadding = 0.px,
                                            cellSpacing = 0.px,
                                            borderSpacing = 0.px,
                                            borderCollapse = BorderCollapse.collapse,
                                            borderWidth = 0.px
                                        ) {


                                            tbody {
                                                tr {
                                                    emailTd(
                                                        align = TdAlign.left,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingLeft = 30.px,
                                                        paddingRight = 10.px,
                                                        textTransform = "Uppercase".textTransform,
                                                        paddingTop = 5.px,
                                                        backgroundColor = rgb(244, 244, 244),
                                                    ) {
                                                        if (orderItem.article?.publicImage != "") {
                                                            img {
                                                                alt = orderItem.article?.specification
                                                                src = orderItem.article?.publicImage
                                                                className = className("n-cart-img")
                                                                style = jso {
                                                                    width = 40.0.px
                                                                    border = 0.px
                                                                }
                                                            }
                                                        }
                                                        +" ${orderItem.article?.specification}"
                                                    }
                                                    emailTd(
                                                        align = TdAlign.right,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingRight = 30.px,
                                                        paddingTop = 10.px,
                                                        paddingBottom = 5.px,
                                                        backgroundColor = rgb(244, 244, 244),
                                                    ) {
                                                        +"${(orderItem.total as? techla.order.Order.Total.Price)?.amount?.amount!!}:-"
                                                    }
                                                }
                                                tr {
                                                    emailTd(
                                                        align = TdAlign.left,
                                                        colSpan = 2,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingLeft = 30.px,
                                                        paddingRight = 10.px,
                                                        paddingTop = 10.px,
                                                        paddingBottom = 10.px,
                                                        fontWeight = FontWeight.normal,
                                                        backgroundColor = rgb(244, 244, 244),
                                                    ) {
                                                        if (orderItem.article?.kind == Article.Kind.None) {

                                                            +"${
                                                                (orderItem.dimensions)?.height?.times(
                                                                    1000
                                                                )?.roundToInt()
                                                            } mm x "
                                                            +"${
                                                                (orderItem.dimensions)?.width?.times(
                                                                    1000
                                                                )?.roundToInt()
                                                            } mm"
                                                        } else if (orderItem.article?.key == Key<Article>(
                                                                "ADDITIONALINSTALLATION"
                                                            ) || orderItem.article?.key == Key<Article>(
                                                                "STARTUPCOST"
                                                            )
                                                        ) {
                                                            +"${orderItem.quantity} st"
                                                        } else if (orderItem.article?.key == Key<Article>(
                                                                "INSTALLATION"
                                                            )
                                                        ) {
                                                            +"Du blir uppringd för tidsbokning"
                                                        }
                                                    }
                                                }
                                            }

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }
    //Article - ADDITIONALINSTALLATION
    props.viewModel.emailInformation.orderItems?.filter {
        it.article?.key == Key<Article>(
            "ADDITIONALINSTALLATION"
        )
    }?.forEach { orderItem ->
        emailTable(
            border = 0.px,
            width = 100.percent,
            cellPadding = 0.px,
            cellSpacing = 0.px,
            background = rgb(244,244,244),
            borderSpacing = 0.px,
            borderCollapse = BorderCollapse.collapse,
            fontFamily = "Helvetica".fontFamily,
            fontSize = 12.px,
            fontStyle = FontStyle.normal,
            fontVariantCaps = FontVariantCaps.normal,
            fontWeight = FontWeight.normal,
            letterSpacing = LetterSpacing.normal,
            textAlign = TextAlign.start,
            textIndent = 0.px,
            textTransform = "none".textTransform,
            whiteSpace = WhiteSpace.normal,
            wordSpacing = 0.px,
            backgroundColor = rgb(255,255,255),
            textDecoration = "none".textDecoration
        ) {
            tbody {
                tr {
                    emailTd(align = TdAlign.center, valign = TdVAlign.top, borderCollapse = BorderCollapse.collapse) {
                        emailTable(
                            border = 0.px,
                            width = 580.px,
                            cellPadding = 0.px,
                            cellSpacing = 0.px,
                            borderSpacing = 0.px,
                            borderCollapse = BorderCollapse.collapse,
                            maxWidth = 100.maxPercent
                        ) {
                            tbody {
                                tr {
                                    emailTd(
                                        align = TdAlign.left,
                                        valign = TdVAlign.top,
                                        borderCollapse = BorderCollapse.collapse,
                                        paddingLeft = 20.px,
                                        paddingRight = 20.px
                                    ) {
                                        emailTable(
                                            border = 0.px,
                                            width = 100.percent,
                                            cellPadding = 0.px,
                                            cellSpacing = 0.px,
                                            borderSpacing = 0.px,
                                            borderCollapse = BorderCollapse.collapse,
                                            borderWidth = 0.px
                                        ) {


                                            tbody {
                                                tr {
                                                    emailTd(
                                                        align = TdAlign.left,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingLeft = 30.px,
                                                        paddingRight = 10.px,
                                                        textTransform = "Uppercase".textTransform,
                                                        paddingTop = 5.px,
                                                        backgroundColor = rgb(244, 244, 244),
                                                    ) {
                                                        if (orderItem.article?.publicImage != "") {
                                                            img {
                                                                alt = orderItem.article?.specification
                                                                src = orderItem.article?.publicImage
                                                                className = className("n-cart-img")
                                                                style = jso {
                                                                    width = 40.0.px
                                                                    border = 0.px
                                                                }
                                                            }
                                                        }
                                                        +" ${orderItem.article?.specification}"
                                                    }
                                                    emailTd(
                                                        align = TdAlign.right,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingRight = 30.px,
                                                        paddingTop = 10.px,
                                                        paddingBottom = 5.px,
                                                        backgroundColor = rgb(244, 244, 244),
                                                    ) {
                                                        +"${(orderItem.total as? techla.order.Order.Total.Price)?.amount?.amount!!}:-"
                                                    }
                                                }
                                                tr {
                                                    emailTd(
                                                        align = TdAlign.left,
                                                        colSpan = 2,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingLeft = 30.px,
                                                        paddingRight = 10.px,
                                                        paddingTop = 10.px,
                                                        paddingBottom = 10.px,
                                                        fontWeight = FontWeight.normal,
                                                        backgroundColor = rgb(244, 244, 244),
                                                    ) {
                                                        if (orderItem.article?.kind == Article.Kind.None) {

                                                            +"${
                                                                (orderItem.dimensions)?.height?.times(
                                                                    1000
                                                                )?.roundToInt()
                                                            } mm x "
                                                            +"${
                                                                (orderItem.dimensions)?.width?.times(
                                                                    1000
                                                                )?.roundToInt()
                                                            } mm"
                                                        } else if (orderItem.article?.key == Key<Article>(
                                                                "ADDITIONALINSTALLATION"
                                                            ) || orderItem.article?.key == Key<Article>(
                                                                "STARTUPCOST"
                                                            )
                                                        ) {
                                                            +"${orderItem.quantity} st"
                                                        } else if (orderItem.article?.key == Key<Article>(
                                                                "INSTALLATION"
                                                            )
                                                        ) {
                                                            +"Du blir uppringd för tidsbokning"
                                                        }
                                                    }
                                                }
                                            }

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }
    //Article - STARTUPCOST
    props.viewModel.emailInformation.orderItems?.filter {
        it.article?.key == Key<Article>(
            "STARTUPCOST"
        )
    }?.forEach { orderItem ->
        emailTable(
            border = 0.px,
            width = 100.percent,
            cellPadding = 0.px,
            cellSpacing = 0.px,
            background = rgb(244,244,244),
            borderSpacing = 0.px,
            borderCollapse = BorderCollapse.collapse,
            fontFamily = "Helvetica".fontFamily,
            fontSize = 12.px,
            fontStyle = FontStyle.normal,
            fontVariantCaps = FontVariantCaps.normal,
            fontWeight = FontWeight.normal,
            letterSpacing = LetterSpacing.normal,
            textAlign = TextAlign.start,
            textIndent = 0.px,
            textTransform = "none".textTransform,
            whiteSpace = WhiteSpace.normal,
            wordSpacing = 0.px,
            backgroundColor = rgb(255,255,255),
            textDecoration = "none".textDecoration
        ) {
            tbody {
                tr {
                    emailTd(align = TdAlign.center, valign = TdVAlign.top, borderCollapse = BorderCollapse.collapse) {
                        emailTable(
                            border = 0.px,
                            width = 580.px,
                            cellPadding = 0.px,
                            cellSpacing = 0.px,
                            borderSpacing = 0.px,
                            borderCollapse = BorderCollapse.collapse,
                            maxWidth = 100.maxPercent
                        ) {
                            tbody {
                                tr {
                                    emailTd(
                                        align = TdAlign.left,
                                        valign = TdVAlign.top,
                                        borderCollapse = BorderCollapse.collapse,
                                        paddingLeft = 20.px,
                                        paddingRight = 20.px
                                    ) {
                                        emailTable(
                                            border = 0.px,
                                            width = 100.percent,
                                            cellPadding = 0.px,
                                            cellSpacing = 0.px,
                                            borderSpacing = 0.px,
                                            borderCollapse = BorderCollapse.collapse,
                                            borderWidth = 0.px
                                        ) {


                                            tbody {
                                                tr {
                                                    emailTd(
                                                        align = TdAlign.left,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingLeft = 30.px,
                                                        paddingRight = 10.px,
                                                        textTransform = "Uppercase".textTransform,
                                                        paddingTop = 5.px,
                                                        backgroundColor = rgb(244, 244, 244),
                                                    ) {
                                                        if (orderItem.article?.publicImage != "") {
                                                            img {
                                                                alt = orderItem.article?.specification
                                                                src = orderItem.article?.publicImage
                                                                className = className("n-cart-img")
                                                                style = jso {
                                                                    width = 40.0.px
                                                                    border = 0.px
                                                                }
                                                            }
                                                        }
                                                        +" ${orderItem.article?.specification}"
                                                    }
                                                    emailTd(
                                                        align = TdAlign.right,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingRight = 30.px,
                                                        paddingTop = 10.px,
                                                        paddingBottom = 5.px,
                                                        backgroundColor = rgb(244, 244, 244),
                                                    ) {
                                                        +"${(orderItem.total as? techla.order.Order.Total.Price)?.amount?.amount!!}:-"
                                                    }
                                                }
                                                tr {
                                                    emailTd(
                                                        align = TdAlign.left,
                                                        colSpan = 2,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingLeft = 30.px,
                                                        paddingRight = 10.px,
                                                        paddingTop = 10.px,
                                                        paddingBottom = 10.px,
                                                        fontWeight = FontWeight.normal,
                                                        backgroundColor = rgb(244, 244, 244),
                                                    ) {
                                                        if (orderItem.article?.kind == Article.Kind.None) {

                                                            +"${
                                                                (orderItem.dimensions)?.height?.times(
                                                                    1000
                                                                )?.roundToInt()
                                                            } mm x "
                                                            +"${
                                                                (orderItem.dimensions)?.width?.times(
                                                                    1000
                                                                )?.roundToInt()
                                                            } mm"
                                                        } else if (orderItem.article?.key == Key<Article>(
                                                                "ADDITIONALINSTALLATION"
                                                            ) || orderItem.article?.key == Key<Article>(
                                                                "STARTUPCOST"
                                                            )
                                                        ) {
                                                            +"${orderItem.quantity} st"
                                                        } else if (orderItem.article?.key == Key<Article>(
                                                                "INSTALLATION"
                                                            )
                                                        ) {
                                                            +"Du blir uppringd för tidsbokning"
                                                        }
                                                    }
                                                }
                                            }

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }



//PRICE
    emailTable(
        border = 0.px,
        width = 100.percent,
        cellPadding = 0.px,
        cellSpacing = 0.px,
        background = rgb(244,244,244),
        borderSpacing = 0.px,
        borderCollapse = BorderCollapse.collapse,
        fontFamily = "Helvetica".fontFamily,
        fontSize = 12.px,
        fontStyle = FontStyle.normal,
        fontVariantCaps = FontVariantCaps.normal,
        fontWeight = FontWeight.normal,
        letterSpacing = LetterSpacing.normal,
        textAlign = TextAlign.start,
        textIndent = 0.px,
        textTransform = "none".textTransform,
        whiteSpace = WhiteSpace.normal,
        wordSpacing = 0.px,
        backgroundColor = rgb(255,255,255),
        textDecoration = "none".textDecoration
    ) {

        tbody {
            tr {
                emailTd(align = TdAlign.center, valign = TdVAlign.top, borderCollapse = BorderCollapse.collapse) {
                    emailTable(
                        border = 0.px,
                        width = 580.px,
                        cellPadding = 0.px,
                        cellSpacing = 0.px,
                        borderSpacing = 0.px,
                        borderCollapse = BorderCollapse.collapse,
                        maxWidth = 100.maxPercent
                    ) {
                        tbody {
                            tr {
                                emailTd(
                                    align = TdAlign.left,
                                    valign = TdVAlign.top,
                                    borderCollapse = BorderCollapse.collapse,
                                    paddingLeft = 20.px,
                                    paddingRight = 20.px
                                ) {
                                    emailTable(
                                        border = 0.px,
                                        width = 100.percent,
                                        cellPadding = 0.px,
                                        cellSpacing = 0.px,
                                        borderSpacing = 0.px,
                                        borderCollapse = BorderCollapse.collapse,
                                        borderWidth = 0.px
                                    ) {
                                        tbody {
                                            tr {
                                                emailTd(
                                                    align = TdAlign.right,
                                                    dir = "ltr",
                                                    valign = TdVAlign.top,
                                                    borderCollapse = BorderCollapse.collapse,
                                                    fontFamily = "Verdana".fontFamily,
                                                    lineHeight = 17.px,
                                                    fontSize = 42.px,
                                                    height = 50.px,
                                                    paddingTop = 40.px,
                                                    backgroundColor = rgb(244, 244, 244)
                                                ) {
                                                    +"${props.viewModel.emailInformation.totalCost}${props.viewModel.emailText.cost}"
                                                }
                                                emailTd(
                                                    align = TdAlign.left,
                                                    dir = "ltr",
                                                    valign = TdVAlign.top,
                                                    borderCollapse = BorderCollapse.collapse,
                                                    fontFamily = "Verdana".fontFamily,
                                                    lineHeight = 17.px,
                                                    fontSize = 15.px,
                                                    height = 50.px,
                                                    paddingTop = 50.px,
                                                    backgroundColor = rgb(244, 244, 244)
                                                ) {
                                                    +" ${props.viewModel.emailText.vat}"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    //CUPON
    if(props.viewModel.emailInformation.discountName?.isNotBlank() == true && props.viewModel.emailInformation.discountCost != 0) {
        emailTable(
            border = 0.px,
            width = 100.percent,
            cellPadding = 0.px,
            cellSpacing = 0.px,
            background = rgb(244, 244, 244),
            borderSpacing = 0.px,
            borderCollapse = BorderCollapse.collapse,
            fontFamily = "Helvetica".fontFamily,
            fontSize = 12.px,
            fontStyle = FontStyle.normal,
            fontVariantCaps = FontVariantCaps.normal,
            fontWeight = FontWeight.normal,
            letterSpacing = LetterSpacing.normal,
            textAlign = TextAlign.start,
            textIndent = 0.px,
            textTransform = "none".textTransform,
            whiteSpace = WhiteSpace.normal,
            wordSpacing = 0.px,
            backgroundColor = rgb(255, 255, 255),
            textDecoration = "none".textDecoration
        ) {
            tbody {
                tr {
                    emailTd(align = TdAlign.center, valign = TdVAlign.top, borderCollapse = BorderCollapse.collapse) {
                        emailTable(
                            border = 0.px,
                            width = 580.px,
                            cellPadding = 0.px,
                            cellSpacing = 0.px,
                            borderSpacing = 0.px,
                            borderCollapse = BorderCollapse.collapse,
                            maxWidth = 100.maxPercent
                        ) {
                            tbody {
                                tr {
                                    emailTd(
                                        align = TdAlign.left,
                                        valign = TdVAlign.top,
                                        borderCollapse = BorderCollapse.collapse,
                                        paddingLeft = 20.px,
                                        paddingRight = 20.px
                                    ) {
                                        emailTable(
                                            border = 0.px,
                                            width = 100.percent,
                                            cellPadding = 0.px,
                                            cellSpacing = 0.px,
                                            borderSpacing = 0.px,
                                            borderCollapse = BorderCollapse.collapse,
                                            borderWidth = 0.px
                                        ) {
                                            tbody {
                                                tr {
                                                    emailTd(
                                                        align = TdAlign.left,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingLeft = 30.px,
                                                        paddingTop = 5.px,
                                                        backgroundColor = rgb(244, 244, 244),
                                                    ) {
                                                        +"${props.viewModel.emailText.titleDiscount}"
                                                    }
                                                    emailTd(
                                                        align = TdAlign.right,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingRight = 30.px,
                                                        paddingBottom = 5.px,
                                                        backgroundColor = rgb(244, 244, 244),
                                                    ) {
                                                        +"${props.viewModel.emailInformation.discountName}"
                                                    }
                                                }
                                                tr {
                                                    emailTd(
                                                        align = TdAlign.left,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingLeft = 30.px,
                                                        paddingTop = 5.px,
                                                        backgroundColor = rgb(244, 244, 244),
                                                    ) {

                                                        +" ${props.viewModel.emailText.originalCost}"
                                                    }
                                                    emailTd(
                                                        align = TdAlign.right,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingRight = 30.px,
                                                        paddingTop = 5.px,
                                                        backgroundColor = rgb(244, 244, 244),
                                                    ) {
                                                        +" ${props.viewModel.emailInformation.originalCost}:-"
                                                    }
                                                }
                                                tr {
                                                    emailTd(
                                                        align = TdAlign.left,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingLeft = 30.px,
                                                        paddingTop = 5.px,
                                                        paddingBottom = 50.px,
                                                        backgroundColor = rgb(244, 244, 244),
                                                    ) {

                                                        +" ${props.viewModel.emailText.discountCost}"
                                                    }
                                                    emailTd(
                                                        align = TdAlign.right,
                                                        dir = "ltr",
                                                        valign = TdVAlign.top,
                                                        borderCollapse = BorderCollapse.collapse,
                                                        fontFamily = "Verdana".fontFamily,
                                                        lineHeight = 17.px,
                                                        fontSize = 15.px,
                                                        paddingRight = 30.px,
                                                        paddingTop = 5.px,
                                                        paddingBottom = 50.px,
                                                        backgroundColor = rgb(244, 244, 244),
                                                    ) {
                                                        +" ${props.viewModel.emailInformation.discountCost}:-"
                                                    }
                                                }
                                            }

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }




//FOOTER
    emailTable(
        border = 0.px,
        width = 100.percent,
        cellPadding = 0.px,
        cellSpacing = 0.px,
        background = rgb(255,255,255),
        borderSpacing = 0.px,
        borderCollapse = BorderCollapse.collapse,
        fontFamily = "Helvetica".fontFamily,
        fontSize = 12.px,
        fontStyle = FontStyle.normal,
        fontVariantCaps = FontVariantCaps.normal,
        fontWeight = FontWeight.normal,
        letterSpacing = LetterSpacing.normal,
        textAlign = TextAlign.start,
        textIndent = 0.px,
        textTransform = "none".textTransform,
        whiteSpace = WhiteSpace.normal,
        wordSpacing = 0.px,
        backgroundColor = rgb(255,255,255),
        textDecoration = "none".textDecoration
    ) {
        tbody {
            tr {
                emailTd(
                    align = TdAlign.center,
                    valign = TdVAlign.top,
                    borderCollapse = BorderCollapse.collapse,
                    padding = 20.px
                ) {
                    emailTable(
                        border = 0.px,
                        width = 100.percent,
                        cellPadding = 0.px,
                        cellSpacing = 0.px,
                        borderSpacing = 0.px,
                        borderCollapse =  BorderCollapse.collapse//"0"
                    ) {


                        tbody {
                            tr {
                                emailTd(
                                    colSpan = 2,
                                    align = TdAlign.center,
                                    dir = "ltr",
                                    valign = TdVAlign.top,
                                    borderCollapse = BorderCollapse.collapse,
                                    fontFamily = "Verdana".fontFamily,
                                    lineHeight = 22.px,
                                    fontSize = 15.px,
                                    color = rgb(0,0,0),
                                    paddingTop = 0.px,
                                    paddingBottom = 8.px,
                                    paddingLeft = 0.px,
                                    paddingRight = 0.px,
                                ) {
                                    +props.viewModel.emailText.openingHours
                                    ahref(
                                        href = "${props.viewModel.emailText.linkRydsGlas}${
                                            replaceChar(
                                                props.viewModel.emailInformation.selectedCity
                                            )
                                        }",
                                        target = AnchorTarget._blank,
                                        color = rgb(0, 170, 136)
                                    ) {
                                        +" ${props.viewModel.emailText.textRydsGlasSe}"
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }


    emailTable(
        border = 0.px,
        width = 100.percent,
        cellPadding = 0.px,
        cellSpacing = 0.px,
        background = rgb(0,170,136),
        borderSpacing = 0.px,
        borderCollapse = BorderCollapse.collapse,
        fontFamily = "Helvetica".fontFamily,
        fontSize = 12.px,
        fontStyle = FontStyle.normal,
        fontVariantCaps = FontVariantCaps.normal,
        fontWeight = FontWeight.normal,
        letterSpacing = LetterSpacing.normal,
        textAlign = TextAlign.start,
        textIndent = 0.px,
        textTransform = "none".textTransform,
        whiteSpace = WhiteSpace.normal,
        wordSpacing = 0.px,
        textDecoration = "none".textDecoration
    ) {
        tbody {
            tr {
                emailTd(align = TdAlign.center, valign = TdVAlign.top, borderCollapse = BorderCollapse.collapse) {
                    emailTable(
                        border = 0.px,
                        width = 600.px,
                        cellPadding = 0.px,
                        cellSpacing = 0.px,
                        borderSpacing = 0.px,
                        borderCollapse = BorderCollapse.collapse,
                        maxWidth = 100.maxPercent
                    ) {
                        tbody {
                            tr {
                                emailTd(
                                    align = TdAlign.left,
                                    valign = TdVAlign.top,
                                    borderCollapse = BorderCollapse.collapse,
                                    paddingTop = 0.px,
                                    paddingBottom = 0.px,
                                    paddingLeft = 10.px,
                                    paddingRight = 10.px
                                ) {
                                    emailTable(
                                        border = 0.px,
                                        width = 100.percent,
                                        cellPadding = 0.px,
                                        cellSpacing = 0.px,
                                        borderSpacing = 0.px,
                                        borderCollapse = BorderCollapse.collapse
                                    ) {
                                        tbody {
                                            tr {
                                                emailTd(
                                                    align = TdAlign.left,
                                                    valign = TdVAlign.top,
                                                    borderCollapse = BorderCollapse.collapse,
                                                    padding = 20.px
                                                ) {
                                                    emailTable(
                                                        border = 0.px,
                                                        width = 100.percent,
                                                        cellPadding = 0.px,
                                                        cellSpacing = 0.px,
                                                        borderSpacing = 0.px,
                                                        borderCollapse = BorderCollapse.collapse
                                                    ) {
                                                        tbody {
                                                            tr {
                                                                emailTd(
                                                                    align = TdAlign.left,
                                                                    valign = TdVAlign.top,
                                                                    borderCollapse = BorderCollapse.collapse,
                                                                    background = rgb(0,170,136),
                                                                    paddingBottom = 30.px
                                                                ) {
                                                                    emailTable(
                                                                        border = 0.px,
                                                                        width = 100.percent,
                                                                        cellPadding = 0.px,
                                                                        cellSpacing = 0.px,
                                                                        borderSpacing = 0.px,
                                                                        borderCollapse = BorderCollapse.collapse
                                                                    ) {
                                                                        tbody {
                                                                            tr {
                                                                                emailTd(
                                                                                    align = TdAlign.left,
                                                                                    valign = TdVAlign.top,
                                                                                    borderCollapse = BorderCollapse.collapse,
                                                                                    whiteSpace = WhiteSpace.nowrap,
                                                                                    fontFamily = "Verdana".fontFamily,
                                                                                    fontSize = 15.px,
                                                                                    paddingBottom = 10.px,
                                                                                    color = rgb(255, 255, 255)
                                                                                ) {
                                                                                    +props.viewModel.emailText.instagramText
                                                                                }
                                                                                emailTd(
                                                                                    align = TdAlign.right,
                                                                                    valign = TdVAlign.top,
                                                                                    borderCollapse = BorderCollapse.collapse,
                                                                                    width = 100.percent,
                                                                                    fontFamily = "Verdana".fontFamily,
                                                                                    fontSize = 15.px,
                                                                                    paddingBottom = 10.px,
                                                                                    color = rgb(255, 255, 255)
                                                                                ) {
                                                                                    +props.viewModel.emailText.contact
                                                                                }
                                                                            }
                                                                            tr {
                                                                                emailTd(
                                                                                    align = TdAlign.left,
                                                                                    valign = TdVAlign.top,
                                                                                    borderCollapse = BorderCollapse.collapse,
                                                                                    whiteSpace = WhiteSpace.nowrap,
                                                                                    fontFamily = "Verdana".fontFamily,
                                                                                    fontSize = 15.px,
                                                                                    color = rgb(255, 255, 255)
                                                                                ) {
                                                                                    ahref(
                                                                                        href = props.viewModel.emailText.urlInstagram,
                                                                                        target = AnchorTarget._blank,
                                                                                        color = rgb(255, 255, 255)
                                                                                    ) {
                                                                                        img {
                                                                                            src = "https://www.noboto.io/dev/speglar/ico_instagram.png"
                                                                                            alt = "${props.viewModel.emailText.instagramName} Instagram"

                                                                                            style = jso {
                                                                                                height = 30.0.px
                                                                                                border = 0.px
                                                                                            }
                                                                                        }
                                                                                        +" ${props.viewModel.emailText.instagramName}"
                                                                                    }
                                                                                }
                                                                                emailTd(
                                                                                    align = TdAlign.right,
                                                                                    valign = TdVAlign.top,
                                                                                    borderCollapse = BorderCollapse.collapse,
                                                                                    width = 100.percent,
                                                                                    fontFamily = "Verdana".fontFamily,
                                                                                    fontSize = 15.px,
                                                                                    color = rgb(255, 255, 255)
                                                                                ) {
                                                                                    +props.viewModel.emailText.RydsEmail
                                                                                    br {}
                                                                                    +props.viewModel.emailText.RydsPhone
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@ExperimentalTime
fun ChildrenBuilder.email(
    viewModel: PaymentScreen.ViewModel.PaymentSuccessful,
    orderItemPrice: ((OrderItem) -> String),

    ) =
    EMAIL() {
        this.viewModel = viewModel
        this.orderItemPrice = orderItemPrice
    }
