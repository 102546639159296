package support

import techla.base.Either
import techla.base.Outcome
import techla.base.Warning

sealed class Scene {
    data class Input<ViewModel>(val store: Store, val viewModel: ViewModel) : Scene()
    data class Output<out ViewModel>(
        val viewModel: ViewModel, val actions: List<Store.Action>
        = emptyList()
    ) : Scene()

}

fun <ViewModel> sceneOf(store: Store, viewModel: ViewModel) =
    Scene.Input(store, viewModel)

fun <ViewModel> sceneOf(viewModel: ViewModel, actions: List<Store.Action> = emptyList()) =
    Scene.Output(viewModel, actions)

fun <ViewModel> sceneOf(viewModel: ViewModel, action: Store.Action) =
    Scene.Output(viewModel, listOf(action))




