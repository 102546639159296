package screens

import org.w3c.dom.Screen
import support.Scene
import support.sceneOf
import support.Store
import techla.base.Either
import techla.base.Key
import techla.base.Warning
import kotlin.time.ExperimentalTime


@ExperimentalTime
object OwnDesignScreen {
    data class Texts(
        val title: String,
        val subText: String,
        val contactText: String,
        val email: String,
        val buttonGoBack: String,
        val errorMessage: String
    )


    sealed class ViewModel() {
        object None : ViewModel()

        class Started() : ViewModel()

        class OwnDesignReady(
            val texts: Texts,

            ) : ViewModel()


        class Failed(
            val message: String
        ) : ViewModel()

        fun started() =
            Started()

        fun ownDesignReady(
            texts: Texts,

            ) =
            OwnDesignReady(texts = texts)

        fun failed(message: String) =
            Failed(message = message)

        fun failed(result: Either<List<Warning>, Throwable>) =
            failed(
                message = when (result) {
                    is Either.Left -> "(${result.value.joinToString(", ") { it.message }})"
                    is Either.Right -> "(${result.value.message})"
                }
            )


    }


    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf<ViewModel>(viewModel.failed(result))

    suspend fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return sceneOf(viewModel.started())
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val texts = Texts(
            title = store.get(media = Key("screen:ownDesign"), content = Key("title")),
            subText = store.get(media = Key("screen:ownDesign"), content = Key("subText")),
            contactText = store.get(media = Key("screen:ownDesign"), content = Key("contactText")),
            email = store.get(media = Key("screen:ownDesign"), content = Key("email")),
            buttonGoBack = store.get(media = Key("screen:ownDesign"), content = Key("buttonGoBack")),
            errorMessage = store.get(media = Key("screen:ownDesign"), content = Key("errorMessage")),

            )
        return sceneOf(
            viewModel.ownDesignReady(texts = texts)
        )
    }


}
