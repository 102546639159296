package screens

import support.Scene
import support.sceneOf
import techla.base.Either
import techla.base.Key
import techla.base.Warning
import kotlin.time.ExperimentalTime

@ExperimentalTime
object HelperScreen {
    data class Texts(
        val instructions: String,
    )

    sealed class ViewModel() {
        object None : ViewModel()

        class Started() : ViewModel()

        class HelperReady(
            val texts: Texts,
        ) : ViewModel()

        class Failed(
            val message: String,
            val title: String
        ) : ViewModel()

        fun started() =
            Started()

        fun helperReady(texts: Texts) =
            HelperReady(texts = texts)

        fun failed(message: String, title: String) =
            Failed(message = message, title = title)

        fun failed(result: Either<List<Warning>, Throwable>, title: String) =
            failed(
                message = when (result) {
                    is Either.Left -> "(${result.value.joinToString(", ") { it.message }})"
                    is Either.Right -> "(${result.value.message})"
                },
                title = title
            )
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>, title: String) =
        sceneOf<ViewModel>(viewModel.failed(result, title))

    suspend fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return sceneOf(viewModel.started())
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val texts = Texts(
            instructions = store.get(media = Key("screen:helper"), content = Key("instructions")),
        )

        return sceneOf(viewModel.helperReady(texts = texts))
    }
}
