package screens


import support.*
import techla.notification.NotificationAPI
import techla.base.*
import techla.form.Submission
import techla.notification.Notification
import kotlin.time.ExperimentalTime

@ExperimentalTime
object EmailScreen {
    data class Texts(
        val urlSpeglar: String,
        val thankYouMessage: String,
        val openingHours: String,
        val textRydsGlasSe: String,
        val instagramText: String,
        val urlInstagram: String,
        val contact: String,
        val RydsPhone: String,
        val RydsEmail: String,
        val titleOverview: String,
        val linkRydsGlas: String,
        val instagramName: String,
        val emailsubject: String
    )

    sealed class ViewModel(val texts: Texts) {
        object None : ViewModel(
            Texts("", "", "", "", "", "", "", "", "", "", "", "", ""),
        )

        class Ready(
            texts: Texts,
        ) : ViewModel(texts)

        class Sent(
            texts: Texts,
        ) : ViewModel(texts)

        class Failed(
            texts: Texts,
            val message: String,
        ) : ViewModel(texts)

        fun ready(texts: Texts) =
            Ready(texts = texts)

        fun sent() =
            Sent(texts = texts)

        fun failed(message: String) =
            Failed(texts = texts, message = message)

        fun failed(result: Either<List<Warning>, Throwable>) =
            failed(
                message = when (result) {
                    is Either.Left -> "(${result.value.joinToString(", ") { it.message }})"
                    is Either.Right -> "(${result.value.message})"
                }
            )

        fun missing(key: String) =
            failed(message = "Missing key '$key'")
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf<ViewModel>(viewModel.failed(result))

    fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val texts = Texts(
            urlSpeglar = store.get(media = Key("screen:email"), content = Key("urlSpeglar")),
            thankYouMessage = store.get(media = Key("screen:email"), content = Key("thankYouMessage")),
            openingHours = store.get(media = Key("screen:email"), content = Key("openingHours")),
            textRydsGlasSe = store.get(media = Key("screen:email"), content = Key("textRydsGlasSe")),
            instagramText = store.get(media = Key("screen:email"), content = Key("instagramText")),
            urlInstagram = store.get(media = Key("screen:email"), content = Key("urlInstagram")),
            contact = store.get(media = Key("screen:email"), content = Key("contact")),
            RydsPhone = store.get(media = Key("screen:email"), content = Key("RydsPhone")),
            RydsEmail = store.get(media = Key("screen:email"), content = Key("RydsEmail")),
            titleOverview = store.get(media = Key("screen:email"), content = Key("titleOverview")),
            linkRydsGlas = store.get(media = Key("screen:email"), content = Key("linkRydsGlas")),
            instagramName = store.get(media = Key("screen:email"), content = Key("instagramName")),
            emailsubject = store.get(media = Key("screen:email"), content = Key("emailsubject")),

            )

        return sceneOf(
            viewModel.ready(texts = texts)
        )
    }

    suspend fun send(
        scene: Scene.Input<ViewModel>,
        body: String,
        email: String,
    ): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.notificationAPI.createNotification(
            Notification.Create(
                profileIds = emptyList(),
                title = viewModel.texts.emailsubject,
                body = body,
                metadata = listOf(Notification.Metadata.Recipient(email = email)),
                distribution = Notification.Distribution.Email
            )

        )
            .map {
                sceneOf<ViewModel>(
                    viewModel.sent()
                )
            }
            .fold(onSuccess = { it },
                onNotSuccess = { techla_log("Email failed $it");scene.failed(result = it) })
    }
}



