package views

import FrontContext
import StoreContext
import bCol
import bootstrap.*
import components.*
import react.*
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.main
import react.dom.html.ReactHTML.p
import react.router.dom.Link
import screens.FrontScreen
import support.Design
import support.className
import support.sceneOf
import techla.base.techla_log
import kotlin.time.ExperimentalTime

@ExperimentalTime
val HelperView = FC<PropsWithChildren>("HELPER_VIEW") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useContext(FrontContext)


    useEffect(viewModel) {
        when (viewModel) {
            is FrontScreen.ViewModel.None -> {
                techla_log("NONE")
                MainScope().launch {
                    val (new, actions) = FrontScreen.start(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)
                }
            }
            is FrontScreen.ViewModel.Started -> {
                techla_log("STARTED")
                MainScope().launch {
                    val (new, actions) = FrontScreen.load(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)
                }
            }
            is FrontScreen.ViewModel.Ready -> {
                techla_log("READY")
            }
            is FrontScreen.ViewModel.Failed -> {
                techla_log("FAILED")
                techla_log(viewModel.message)
            }
        }
    }



    main {
        if (viewModel is FrontScreen.ViewModel.Ready) {
            //bContainer(fluid = true, className = className( "n-header py-3 px-0") {
            headerComponent()
            //}
            bContainer {
                fluid = true
                className = className("px-0")
                div {
                    className = className("n-bg-container")
                    img {
                        className = className("n-bg full")
                        alt = ""
                        src = Design.image("img/bg_img_v2.jpg")
                    }
                    //img(className =className("n-bg full", alt = "", src = "img/bg_img_v2.jpg") {
                    //}
                }
                div {
                    className = className("w-100 position-relative n-container-scroll")
                    helperComponent()
                    footerComponent()
                }
            }
        }
        if (viewModel is FrontScreen.ViewModel.Failed) {
            div {
                bContainer {
                    className = className("px-3 px-md-0 p-lg-0")
                    Link {
                        to = "/"
                        className = className("d-block greenlink pt-4")
                        img {
                            className = className("pr-2")
                            alt = "Go back"
                            src = "svg/ico_back.svg"
                        }
                        +"Tillbaka"
                    }
                }

                bContainer {
                    className = className("py-5")
                    bRow {
                        className = className("pb-4 justify-content-center")
                        bCol {
                            className = className("p-4 text-center")
                            xs = 12; md = 8
                            img {
                                src = "svg/ico_error.svg"
                                className = className("py-4")
                            }
                            h1 {
                                // +viewModel.title
                            }

                            p {
                                +"Något gick fel"
                            }
                        }
                    }
                }
            }
        }
    }
}