
import csstype.ClassName
import react.ComponentType
import react.PropsWithChildren

external interface ColProps : PropsWithChildren {
    var className: ClassName
    var lg: dynamic /* boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number } */
    var md: dynamic /* boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number } */
    var sm: dynamic /* boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number } */
    var xl: dynamic /* boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number } */
    var xs: dynamic /* boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number } */
    var bsPrefix: String
}

@JsModule("react-bootstrap/Col")
private external val colModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bCol: ComponentType<ColProps> = colModule.default