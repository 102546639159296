package support

import techla.base.techla_log
import kotlin.time.ExperimentalTime


@JsModule("./img/bg_img.jpg")
@JsNonModule
external val bgImage: dynamic

@JsModule("./img/bg_img_v2.jpg")
@JsNonModule
external val bgImageV2: dynamic

@JsModule("./img/img_insta_test.jpg")
@JsNonModule
external val bgInsta: dynamic


@ExperimentalTime
object Design {
    fun image(image: String?): String? {
        return when (image) {
            "img/bg_img.jpg" -> bgImage.default
            "img/bg_img_v2.jpg" -> bgImageV2.default
            "img/img_insta_test.jpg" -> bgInsta.default
            else -> null
        }
    }
}

