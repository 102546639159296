package components

import csstype.ClassName
import react.*
import react.dom.*
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h4
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import screens.OrderScreen
import screens.category
import support.className
import techla.base.Key
import techla.order.Article
import kotlin.time.ExperimentalTime

@ExperimentalTime
external interface OrderItemsComponentProps : PropsWithChildren {
    var item: OrderScreen.Item
    var texts: OrderScreen.Texts
    var duplicate: ((OrderScreen.Item) -> Unit)
    var delete: ((OrderScreen.Item) -> Unit)
    var className: String
    var isEditable: Boolean
}

@ExperimentalTime
val ORDER_ITEMS_COMPONENT = FC<OrderItemsComponentProps>("ORDER_ITEMS_COMPONENT") { props ->


    fun duplicate(item: OrderScreen.Item) {
        props.duplicate(props.item)
    }

    fun delete(item: OrderScreen.Item) {
        props.delete(props.item)
    }


    div {
        className = className(props.className)
        div {
            className = className("pt-2 pr-2")
            if (props.item.imgLink != null) {
                img {
                    alt = props.item.title
                    src = props.item.imgLink
                    className = className("n-cart-img")
                }
            }
        }
        div {
            className = className("pt-2 pr-2 w-100")
            div {
                className = className("d-flex flex-column")
                div {
                    className = className("d-flex justify-content-between")
                    h4 {
                        className = className("pt-1")
                        +props.item.title
                    }
                    if (props.item.article.key != Key<Article>("ADDITIONALINSTALLATION") &&
                        props.item.article.key != Key<Article>("STARTUPCOST") &&
                        props.isEditable
                    ) {
                        img {
                            src = "svg/ico_remove.svg"
                            alt = "remove article"
                            className = className("n-pointer")
                            height = 31.00
                            width = 25.00
                            onClick = {
                                delete(props.item)
                            }
                        }
                    }
                }
                div {
                    className = className("d-flex justify-content-between")
                    if (props.item.width > 0) {

                        p {
                            className = className("m-0 pb-1")
                            +"Bredd: ${props.item.width} ${props.texts.unit}, Höjd: ${props.item.height} ${props.texts.unit}"
                        }
                    }
                    p {
                        className = className("m-0 pb-1 pl-1")
                        +"${props.texts.total} ${props.item.cost}${props.texts.cost}"
                    }
                    if (props.item.article.key == Key<Article>("ADDITIONALINSTALLATION") ||
                        props.item.article.key == Key<Article>("STARTUPCOST")
                    ) {
                        p {
                            className = className("m-0 pb-1")
                            +"${props.item.unit} ${props.texts.amount}"
                        }
                    }
                }
                if (props.item.article.category?.key != Key<Article>("installation") &&
                    props.item.article.key != Key<Article>("STARTUPCOST") &&
                    props.isEditable
                ) {
                    div {
                        className = className("d-flex")
                        a {
                            className = className("d-block greenlink")
                            onClick = {
                                duplicate(props.item)
                            }
                            img {
                                className = className("pr-2")
                                alt = "duplicate article"
                                src = "svg/ico_add.svg"

                            }
                            +props.texts.buttonDuplicate
                        }
                    }
                }
            }
        }
    }
}


@ExperimentalTime
fun ChildrenBuilder.orderItemsComponent(
    item: OrderScreen.Item,
    texts: OrderScreen.Texts,
    duplicate: ((OrderScreen.Item) -> Unit),
    delete: ((OrderScreen.Item) -> Unit),
    className: String,
    isEditable: Boolean,
) =
    ORDER_ITEMS_COMPONENT() {
        this.item = item
        this.texts = texts
        this.duplicate = duplicate
        this.delete = delete
        this.className = className
        this.isEditable = isEditable
    }