package views

import FrontContext
import StoreContext
import bCol
import bootstrap.*
import react.*
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.main
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.strong
import react.router.dom.Link
import screens.FrontScreen
import support.className
import support.sceneOf
import techla.base.techla_log
import kotlin.time.ExperimentalTime

@ExperimentalTime
val TermsView = FC<PropsWithChildren>("TERMSVIEW") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useContext(FrontContext)

    useEffect(viewModel) {
        when (viewModel) {
            is FrontScreen.ViewModel.None -> {
                techla_log("NONE")
                MainScope().launch {
                    val (new, actions) = FrontScreen.start(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)
                }
            }
            is FrontScreen.ViewModel.Started -> {
                techla_log("STARTED")
                MainScope().launch {
                    val (new, actions) = FrontScreen.load(sceneOf(store, viewModel))
                    dispatch(actions)
                    setViewModel(new)
                }
            }
            is FrontScreen.ViewModel.Ready -> {
                techla_log("READY")
            }
            is FrontScreen.ViewModel.Failed -> {
                techla_log("FAILED")
                techla_log(viewModel.message)
            }
        }
    }

    main {
        if (viewModel is FrontScreen.ViewModel.Ready) {
            bContainer {
                fluid = true
                className = className("n-header py-3 px-0 d-flex align-items-center")
                bContainer {
                    className = className("px-3 px-md-0 p-lg-0")
                    Link {
                        to = "/"

                        img {
                            className = className("n-logo-spegla")
                            alt = "Speglar.nu"
                            src = "svg/logo_speglar.svg"
                        }
                    }
                }
            }
            bContainer {
                className = className("px-3 px-md-0 p-lg-0")
                Link {
                    to = "/"
                    className = className("d-block greenlink pt-4")
                    img {
                        className = className("pr-2")
                        alt = "Go back"
                        src = "svg/ico_back.svg"
                    }
                    +viewModel.texts.buttonGoBack
                }
            }
            bContainer {
                className = className("p-4")
                bRow {
                    className = className("pb-4 justify-content-center")
                    bCol {
                        xs = 12; md = 8
                        h1 {
                            className = className("text-center")
                            +viewModel.texts.termsTitle
                        }
                        h3 {
                            +viewModel.texts.generalTitle
                        }
                        p {
                            +viewModel.texts.generalBody
                        }
                        h3 {
                            +viewModel.texts.orderTitle
                        }
                        p {
                            +viewModel.texts.orderBody
                        }
                        h3 {
                            +viewModel.texts.priceTitle
                        }
                        p {
                            +viewModel.texts.priceBody
                        }
                        h3 {
                            +viewModel.texts.paymentTitle1
                        }
                        strong {
                            +viewModel.texts.paymentTitle2
                        }
                        p {
                            +viewModel.texts.paymentBody1
                        }
                        p {
                            a {
                                target = AnchorTarget._blank
                                href = viewModel.texts.paymentLink
                                +viewModel.texts.paymentLink
                            }
                        }
                        p {
                            +viewModel.texts.paymentBody2
                        }
                        h3 {
                            +viewModel.texts.deliveryTimeTitle
                        }
                        p {
                            +viewModel.texts.deliveryTimeBody
                        }
                        h3 {
                            +viewModel.texts.deliveryDelayTitle
                        }
                        p {
                            +viewModel.texts.deliveryDelayBody
                        }
                        h3 {
                            +viewModel.texts.guaranteeTitle
                        }
                        p {
                            +viewModel.texts.guaranteeBody
                        }
                        h3 {
                            +viewModel.texts.withdrawalTitle
                        }
                        p {
                            +viewModel.texts.withdrawalBody
                        }
                        h3 {
                            +viewModel.texts.complaintTitle
                        }
                        p {
                            +viewModel.texts.complaintBody
                        }
                        h3 {
                            +viewModel.texts.uncollectedMirrorsTitle
                        }
                        p {
                            +viewModel.texts.uncollectedMirrorsBody
                        }
                        h3 {
                            +viewModel.texts.personalDataTitle
                        }
                        p {
                            +viewModel.texts.personalDataBody
                        }
                        h3 {
                            +viewModel.texts.cookiesTitle
                        }
                        p {
                            +viewModel.texts.cookiesBody
                        }
                        h3 {
                            +viewModel.texts.reservationTitle
                        }
                        p {
                            +viewModel.texts.reservationBody
                        }
                        h3 {
                            +viewModel.texts.klarnaTitle
                        }
                        p {
                            +viewModel.texts.KlarnaBody1
                            a {
                                target = AnchorTarget._blank
                                href = viewModel.texts.KlarnaLink
                                +viewModel.texts.KlarnaBody2
                            }
                        }

                    }
                }
            }
        }
        if (viewModel is FrontScreen.ViewModel.Failed) {
            div {
                bContainer {
                    className = className("px-3 px-md-0 p-lg-0")
                    Link {
                        to = "/"
                        className = className("d-block greenlink pt-4")

                        img {
                            className = className("pr-2")
                            alt = "Go back"
                            src = "svg/ico_back.svg"
                        }
                        +"Tillbaka"
                    }
                }
            }
            bContainer {
                className = className("py-5")
                bRow {
                    className = className("pb-4 justify-content-center")
                    bCol {
                        className = className("p-4 text-center")
                        xs = 12; md = 8
                        img {
                            src = "svg/ico_error.svg"
                            className = className("py-4")
                        }
                        /*h1 {
                            +viewModel.title
                        }

                         */
                        p {
                            +"Något gick fel"
                        }
                    }
                }
            }

        }
    }
}